import { Injectable } from '@angular/core';
import { AppConstants } from '../../constants/appConstants';
import { HeaderService } from '../header/header.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
// import { AuthService } from '../../auth/auth.service';

var document:any;
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  selectedGoal:any = {};
  selectedProgramId:number = 0;

  constructor(private headerService: HeaderService,
    private $gaService: GoogleAnalyticsService,
  ) { }

  public validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  public validatePhone(phone: string) {
    const re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return re.test(String(phone).toLowerCase());
  }

  public validateAllFields(data: any) {
    let isAllFilled = true;
    Object.keys(data).forEach(key => {
      if (data[key].length === 0) {
        isAllFilled = false;
      }
    });
    return isAllFilled;
  }

  public setHeaderData(routes: any) {

    this.headerService.setHeaderData(routes);
  }

  validateAddZero(val:number) {
    return val < 10 ? `0${val}` : val;
  }

  public getFormattedDate(val: string) {
    const dt = new Date(val);
    return `${this.validateAddZero(dt.getMonth() + 1)}-${this.validateAddZero(dt.getDate())}-${dt.getFullYear()}`;
  }

  public getGeneralFormatDate(dt:any) {
    return `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
  }

  public getTextMonthFormat(data:Date) {
    const monthlist = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(data);
    return `${monthlist[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  }

  formatAMPM(date:Date) {
    let hours = date.getHours();
    let minutes:any = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  public getDateTimeFormat(date: string) {
    const dt = new Date(date);
    const dtStr = this.getTextMonthFormat(dt);
    const dtTime = this.formatAMPM(dt);
    return `${dtStr} ${dtTime}`;
  }

  public getFormattedError(err: any) {
    if (err?.error?.error) {
      return err.error.error;
    }
  }

  public getUserInitials(item:any) {
    if (item.firstname || item.lastname) {
      return `${item.firstname.slice(0,1)}${item.lastname.slice(0,1)}`;
    }
    return ''
  }

  public gaEvent(key: string, section: string, value: string) {
    this.$gaService.event(key, section, value);
  }

  public addDays(days: number, date = new Date()) {
    var date = new Date(date);
    date.setDate(date.getDate() + days);
    return date;
  }

  public weeksBetween(dt:any) {
    const dtNow:any = new Date();
    return Math.round((dtNow - dt) / (7 * 24 * 60 * 60 * 1000));
  }

  public getPercentageValue(actual: number, total: number) {
    if (actual !== 0 && total !==0 ) {
      return (actual / total) * 100;
    }
    return 0;
  }

  public differValues(startValue:any, actualValue:any) {
    const resultVal = actualValue - startValue;
    return resultVal > 0 ? `+${this.roundValues(resultVal)}` : this.roundValues(resultVal);
  }

  public roundValues(val:any) {
    const resVal = isNaN(val) ? 0 : val;
    return Math.round(resVal);
  }

  public detectBrowser() {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return 'Firefox';
    } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) {
      return 'IE';
    } else {
      return 'unknown';
    }
  }

  public getFixedValue(val:number = 0) {
    const str = val ? val.toString() : '0';
    if (str.indexOf('.') !== -1) {
      return parseFloat(val.toFixed(2));
    }
    return val;
  }

  public getValidateNumber = (value:any) => {
    const parsed = parseFloat(value);
    if (isNaN(parsed)) {
        return 0;
    }
    return value;
  };

  // public setSelectedGoal = (data:any) => {
  //   this.authService.setGoalData(data);
  //   this.selectedGoal = data;
  // };

  // public getSelectedGoal = () => {
  //   return this.authService.getGoalData();
  //   //return this.selectedGoal;
  // };

  
  public objectFilter = (array:any, text:any) => {
    const getChildren = (result:any, object:any) => {
      var re = new RegExp(text, 'gi');
      if (object.name.match(re)) {
        result.push(object);
        return result;
      }
      if (Array.isArray(object.children)) {
        const children = object.children.reduce(getChildren, []);
        if (children.length) result.push({ ...object, children });
      }
      return result;
    };
    return array.reduce(getChildren, []);
  }

  convertDate2Age(dateString:Date) {
    var today = new Date();
    var ageArr = [];
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    ageArr.push(age);
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
        ageArr[0] = age;
    }
    if (m > 0){
      ageArr.push(m);
    } else if (m < 0) {
      ageArr.push(12 + m);
    } else if (m === 0 && today.getDate() < birthDate.getDate()){
      ageArr.push(11);
    }
    return ageArr.join('.');
  }

}