import { Component, OnInit, Input, Output,  EventEmitter} from '@angular/core';

@Component({
  selector: 'app-selectradio',
  templateUrl: './selectradio.component.html',
  styleUrls: ['./selectradio.component.sass']
})
export class SelectradioComponent implements OnInit {
  @Input() values:any = [];
  @Output() action = new EventEmitter<any>();
  @Input() itemValue:any = '';
  @Input() indexValue:number = -1;
  @Input() colType:string = '';
  @Input() specified_strings:any = [];

  constructor() { }

  ngOnInit(): void {
  }

  onOptionChange(key:any) {
    const obj = {
      index: this.indexValue,
      info: key
    };
    this.action.emit(obj);
  }
}
