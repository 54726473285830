<div>
    <div class="select-ddl col-33 padding-5">
        <mat-form-field class="width-full">
          <mat-select [(ngModel)]="selected_category" (ngModelChange)="onFilterMealCategory()">
            <mat-option [value]="default_value">View Category layout</mat-option>
            <mat-option *ngFor="let data of recipesCategories"  [value]="data.id">{{data.name}}</mat-option>
          </mat-select>
        </mat-form-field>
    </div>
    <div>
        <table mat-table [dataSource]="datasource" class="mat-elevation-z10" multiTemplateDataRows>
            <ng-container matColumnDef="name">
                <th class="fieldlbls" mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="namefld">
                        {{element.name}}
                    </div> 
                </td>
            </ng-container>
            <ng-container matColumnDef="parentcategory">
                <th class="fieldlbls" mat-header-cell *matHeaderCellDef> Parent Category </th>
                <td mat-cell *matCellDef="let element; let i = dataIndex"> 
                    <div class="select-ddl">
                        <mat-form-field class="width-full">
                            <mat-label>No Parent Category</mat-label>
                          <mat-select [(ngModel)]="element.parent_id" (ngModelChange)="onChangeRecipeCategory(i)">
                            <mat-option>No Parent Category</mat-option>
                            <span *ngFor="let data of recipesCategories">
                                <mat-option *ngIf="data.id !== element.id" [ngClass]="{'display_none': data.id === element.id}" [value]="data.id"  >{{data.name}}</mat-option>
                            </span>
                          </mat-select>
                        </mat-form-field>
                    </div> 
                </td>
            </ng-container>
            <ng-container matColumnDef="recipes">
                <th class="fieldlbls" mat-header-cell *matHeaderCellDef> Recipes </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="recipe-uploader-categories">
                        <div class="label-primary" *ngFor="let obj of element.recipes">
                            {{obj.name}}
                        </div>
                    </div> 
                </td>
            </ng-container>
            <ng-container matColumnDef="delete">
                <th class="fieldlbls" mat-header-cell *matHeaderCellDef> Delete </th>
                <td mat-cell *matCellDef="let element; let i = dataIndex"> 
                    <div class="delete-field">
                        <mat-icon (click)="deleteHandle(element.id, i)">delete_outline</mat-icon>
                    </div> 
                </td>
            </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="example-detail-row"></tr>
    </table>
    </div>
</div>
