<div class="main-content">
    <div>
        <div class="inlineBlock user-search-count">
          <span class="title">Clients</span>
          <span class="user-count">{{pagination.totalCount}}</span>
          <span class="search-user">
              <mat-form-field appearance="outline">
                  <mat-label>Search name or email</mat-label>
                  <input matInput placeholder="Search name or email" (keyup)="onSearchUser($event)" autocomplete="off">
                  <mat-icon matPrefix>search</mat-icon>
                </mat-form-field>
          </span>
        </div>
        <div class="inlineBlock add-user">
          <span>
            <button mat-raised-button color="primary"
            (click)="addUser()"
            >
              <mat-icon>add_circle</mat-icon>
              Add User</button>
          </span>
        </div>
      </div>
      <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" >

            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
          
            <!-- Position Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element" > 
                {{element.firstname}} {{element.lastname}}
              </td>
            </ng-container>
          
            <!-- Name Column -->
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> Email </th>
              <td mat-cell *matCellDef="let element" > {{element.email}} </td>
            </ng-container>
          
            <!-- Weight Column -->
            <ng-container matColumnDef="progress">
              <th mat-header-cell *matHeaderCellDef> Progress </th>
              <td mat-cell *matCellDef="let element">
              {{element.progress}}
              </td>
            </ng-container>
          
            <!-- Symbol Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let element"> {{element.enabled == 1 ? 'Active' : 'InActive'}} </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="specificUser(row)"></tr>
          </table>
          <span *ngIf="!dataSource.length" class="emtmsg">No records found</span>
          <mat-paginator *ngIf="pagination.totalCount>10"
                [length]="pagination.count"
                [pageIndex]="pagination.pageIndex"
                [pageSize]="pagination.perPage"
                [pageSizeOptions]="[10, 25, 50, 100, 200]"
                (page)="getServerData($event)"
          showFirstLastButtons></mat-paginator>
    </div>
</div>