<div class="client-exercise-container">
    <div class="title">
        Exercise
    </div>
    <div class="section">
        <div class="spec-program col-50">
          <mat-form-field>
            <mat-select [(ngModel)]="program_id" (ngModelChange)="onChangeProgram()" placeholder="Select Session">
              <mat-option *ngFor="let data of programsData"  [value]="data.id">
                {{data.name}}<span *ngIf="data.goal.name!=null && data.goal.name!=''"> ({{data.goal.name}})</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
          </div>
          <div class="col-50 col-date-picker">
            <mat-form-field appearance="fill">
                <div (click)="picker.open()" class="today-lbl" [style.display]="isTodayDate ? 'block' : 'none'">Today</div>
                <input [style.display]="!isTodayDate ? 'block' : 'none'" [max]="maxDate"  matInput [matDatepicker]="picker" [readonly]="true" (focus)="picker.open()" (dateChange)="onDateSelect($event)" [formControl]="exerciseDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
    </div>
    <div class="section">
      <div class="col-50 exercise-plan">
      </div>
      <div class="col-50 add-exercise">
        <form class="example-form" *ngIf="isExercisesExists">
          <div class="inp-section">
            <mat-icon class="input-search">search</mat-icon>
            <input type="text"
                  placeholder="Add Exercise"
                  [matAutocomplete]="auto" 
                  (change)="onSearchExercise($event)"
                  (keyup)="onSearchExercise($event)"
                  [formControl]="exerciseCtrl"
                  />
            </div>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let item of exercises" [value]="item.name"
              (click)="onAddExerciseNew(item)"
            >
              {{item.name}}
            </mat-option>
          </mat-autocomplete>
        </form>
      </div>
    </div>
    <div *ngIf="!isExercisesExists">
      <mat-card>{{exerciseErrorInfo}}</mat-card>
    </div>
    <div class="exercise-list" *ngIf="isExercisesExists">
      <div *ngFor="let item of exerciseData;let i = index">
        <div class="exercise_plan_name">{{item.plan_name}}</div>
        <div class="header">
          <div class="col-25 title">{{item.name}}</div>
          <div class="col-25 hdr-info">
            <span *ngIf="item.cat_id === 1">HEART RATE</span>
            <span *ngIf="item.cat_id !== 1">WEIGHT</span>
          </div>
          <div class="col-25 hdr-info">
            <span *ngIf="item.cat_id === 1">TIME</span>
            <span *ngIf="item.cat_id !== 1">REPS</span>
          </div>
          <div class="col-25 remove" (click)="onExerciseDelete(i)" *ngIf="item.clientadded">Delete All</div>
        </div>
        <div class="content" *ngFor="let details of item.setdetails; let j = index">
          <div class="col-25 align-center">
            Set {{j + 1}}:
          </div>
          <div class="col-25">
            <app-selectinput placeholder="Weight" [indexValue]="i" type="log_weight" [setsValue]="j" [itemValue]="details.log_weight" (action)="onAction($event)"></app-selectinput>
          </div>
          <div class="col-25">
            <app-selectinput placeholder="Reps" [indexValue]="i" type="log_reps" [setsValue]="j" [itemValue]="details.log_reps" (action)="onAction($event)"></app-selectinput>
          </div>
          <div class="col-25 horiz">
            <button mat-button [matMenuTriggerFor]="menu" *ngIf="details.clientadded">
              <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="onDeleteSet(i, j)" >Delete</button>
            </mat-menu>
          </div>
        </div>
        <div class="content" *ngIf="item.cat_id === 1">
            <div class="col-25 align-center">
              
            </div>
            <div class="col-25">
              <app-selectinput placeholder="Heart Rate" [indexValue]="i" type="log_heartrate" [setsValue]="defaultSetsValue" [itemValue]="item.log_heartrate" (action)="onAction($event)"></app-selectinput>
            </div>
            <div class="col-25">
              <app-selectinput placeholder="Time" [indexValue]="i" type="log_duration" [setsValue]="defaultSetsValue" [itemValue]="item.log_duration" (action)="onAction($event)"></app-selectinput>
            </div>
            <div class="col-25 horiz">
              <button mat-button [matMenuTriggerFor]="menu">
                <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item>Add Note</button>
                <button mat-menu-item (click)="onDeleteSet(i, 0)" *ngIf="item.clientadded">Delete</button>
              </mat-menu>
            </div>
        </div>
        <div class="content" *ngIf="item.cat_id !== 1">
          <div class="col-25 add-set"
          >
          <button mat-button color="primary"
            (click)="onAddSet(i)"
            [disabled]="!item.logexercisesingle_id"
          ><strong>ADD SET</strong></button>
          </div>
          <div class="col-25">

          </div>
          <div class="col-25">

          </div>
          <div class="col-25 horiz">
            <mat-icon class="exercise_icons" color="primary" (click)="onVideoView(item.video_url, item.name)">videocam</mat-icon>
            <button mat-button color="primary"
            (click)="onAddNote(i)"
            [ngClass]="{'exercise_add_note': item.logexercisesingle_id}"
            [disabled]="!item.logexercisesingle_id"
          ><mat-icon *ngIf="!item.clientnote">note_add</mat-icon>
          <mat-icon *ngIf="item.clientnote">edit</mat-icon>
          </button>
          <mat-icon class="exercise_icons" color="primary" (click)="onExerciseInfo(item.description, item.name, item.exercisetemplateimages)" >info</mat-icon>
          </div>
        </div>
      </div>
    </div>
</div>