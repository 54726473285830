import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { config } from '../config';
import { HttpService } from '../services/http/http.service';
import { LogService } from '../services/log/log.service';
import { CommonService } from '../services/common/common.service';
import { UiService } from '../services/uiloader/uiloader.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuthService } from '../auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { ToasterService } from '../services/toaster/toaster.service';

@Component({
  selector: 'app-newprogram',
  templateUrl: './newprogram.component.html',
  styleUrls: ['./newprogram.component.sass']
})
export class NewprogramComponent implements OnInit {

  goalId:any = '';
  userId:any = '';
  activeProgramId:any = '';
  headerData:any = {};
  noSessionsFound:boolean = false;
  goalsData:any;
  start_date:any;
  end_date:any;
  copySessionObject:any ={
    selectedSession:'',
    clientDetais:{},
    sessions: [],
  };

  constructor(private router: Router, private route: ActivatedRoute,
    private httpService: HttpService, private logService: LogService,
    public commonService: CommonService, private location: Location,
    private ui: UiService, private dialog: MatDialog,
    private authService: AuthService,
    private cookieService: CookieService,
    private toasterService:ToasterService
    ) { 
    this.goalId = this.route.snapshot.params.goalId;
    this.userId = this.route.snapshot.params.userId;
    this.headerData = this.authService.getGoalData();
  }

  ngOnInit(): void {
    this.authService.hideDisplayHeaderValue();
    this.fetchSessions();
    this.getGoalsData();
  }

  fetchSessions() {
    const url = `${config.apiUrl}/getgoalsessions`;
    const reqData = {
      goal_id: this.goalId
    };
    this.ui.spin$.next(true);

    this.httpService.postCall(url, reqData).subscribe(data => {
      console.log(data);
      this.noSessionsFound = false;

      this.activeProgramId = data.user.activeprogram_id;
      this.sessionsTableData = data.sessions;
      this.ui.spin$.next(false);
    },
    err => {
      if (err.error.statusCode === 404) {
        this.noSessionsFound = true;
      }
      this.ui.spin$.next(false);
      this.logService.logError(err);
    });
  }

  sessionsTableData:any = [];
  displayedColumns: string[] = ['name', 'date', 'weight', 'bodyfat', 'status'];

  onNavigateBack() {
    this.location.back();
  }

  onNavigateBackUser() {
    this.router.navigate(['/userdetails/' + this.userId ]);
  }

  addSession(): void {
    let dialogRef = this.dialog.open(NavDialog, { data: "" });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
        this.ui.spin$.next(true);
        this.httpService.postCall(`${config.apiUrl}/createsession`, {'client_id': this.userId, 'goal_id': this.goalId}).subscribe(data => {
          this.authService.setSelectedProgramId(data.success.id);
          this.setSessionName(data.success);
          this.router.navigate(['/sessions/' + this.userId + '/' + data.success.id]);
          this.ui.spin$.next(false);
        },
        err => {
          this.ui.spin$.next(false);
          this.logService.logError(err);
        });        
      }
    });
  }
  
  getGoalsData(): void {
    this.httpService.postCall(`${config.apiUrl}/getclientprogramswithgoals`, {'client_id': this.userId, 'program_id': this.goalId}).subscribe(data => {
      console.log(data);
      this.goalsData = data?.programswithgoals[0];
      this.start_date = this.commonService.getFormattedDate(this.goalsData.start_date);
      this.end_date = this.commonService.getFormattedDate(this.goalsData.estimated_end_date);
    },
    err => {
      this.logService.logError(err);
    });
  }

  copySession(): void {
        this.ui.spin$.next(true);
        this.httpService.postCall(`${config.apiUrl}/copysession`, {'program_id': this.copySessionObject.selectedSession.id, 'goal_id': this.copySessionObject.selectedSession.goal_id}).subscribe(data => {
          const [ record ] = data;
          this.authService.setSelectedProgramId(record.id);
          this.setSessionName(record);
          this.router.navigate(['/sessions/' + this.userId + '/' + record.id]);
          this.ui.spin$.next(false);
        },
        err => {
          this.ui.spin$.next(false);
          this.logService.logError(err);
        });    
  }
  openSessionPopup() {
    let copySessiondialogRef = this.dialog.open(CopySessionDialog, { data: { 'title': "Select Session for client", "copyUser": true, copySessionObject: this.copySessionObject }, width: '600px' });
    copySessiondialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.copySession();
      }
    })
  }

  getPrograms(reqObj: any) {
    this.ui.spin$.next(true);
    const url = `${config.apiUrl}/getclientprograms`;
    this.httpService.postCall(url, reqObj).subscribe(data => {
      this.ui.spin$.next(false);
      this.copySessionObject.clientDetais = data.clientDetais;
      this.copySessionObject.sessions = data.programs;
     this.openSessionPopup();
    }, err => {
      this.ui.spin$.next(false);
      this.dialog.closeAll();
      this.toasterService.show('error', 'Error', 'Error Occurred while fetching progrmas');
    });
  }

  copySessionPopUp(){
    this.copySessionObject = {
      selectedSession: '',
      clientDetais: {},
      sessions: [],
    };
    const reqObj = {
      "client_id": this.userId,
    }
     this.getPrograms(reqObj);
  }

  setSessionName(data:any) {
    const goalData = this.authService.getGoalData();
    goalData.sessionName = data.name;
    this.authService.setGoalData(goalData);
  }

  onProgramSelection(data:any) {
    this.cookieService.set('editsession', 'true');
    this.authService.setSelectedProgramId(data.id);
    this.setSessionName(data);
    this.router.navigate(['/sessions/' + this.userId + '/' + data.id]);
  }
}

@Component({
  selector: 'copy-session-dialog',
  templateUrl:'./copy-session-dialog.html',
  styleUrls: ['./newprogram.component.sass']
})
export class CopySessionDialog {
  constructor(
    public dialogRef: MatDialogRef<CopySessionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  responseHandler(action:any): void {
    this.dialogRef.close(action);
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  template: `
  <h1 mat-dialog-title>Add Session</h1>
  
  <div mat-dialog-content class="dialogContent">
      <p>Are you sure to Create New Session?</p>
    </div>
    <div mat-dialog-actions class="dialogActions">
      <button mat-button class="resultsbtn success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>Submit</button>
      <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
    </div>`,
  styleUrls: ['./newprogram.component.sass']
})
export class NavDialog {
  constructor(
    public dialogRef: MatDialogRef<NavDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  responseHandler(action:any): void {
    this.dialogRef.close(action);
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  template: `
  <h1 mat-dialog-title>Copy Session</h1>
  <div mat-dialog-content class="dialogContent">
      <p>Are you sure to Copy from Active Session?</p>
    </div>
    <div mat-dialog-actions class="dialogActions">
      <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>Submit</button>
      <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
    </div>`,
  styleUrls: ['./newprogram.component.sass']
})
export class NavCopyDialog {
  constructor(
    public dialogRef: MatDialogRef<NavCopyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  responseHandler(action:any): void {
    this.dialogRef.close(action);
  }
}
