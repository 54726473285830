<!-- <mat-toolbar >
    <!- <button *ngIf="isLoggedIn$ | async as isLoggedIn" mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
    (click)="toggleSideNav()"
    >
      <mat-icon>menu</mat-icon>
    </button> --
    <span>Company Administration</span> <br/>
    <span>Manage users</span>
    <span class="example-spacer"></span>
    <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
      <mat-icon>favorite</mat-icon>
    </button>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
      <mat-icon>share</mat-icon>
    </button>
  </mat-toolbar> -->
  <div class="mat-card-header" 
    *ngIf="isDisplayHeader$ | async as isDisplayHeader"
  >
    <mat-card class="header-card"
    >
      <div class="row">
        <div class="col-70">
          <div class="display-inline menu-header-icon">
            <button *ngIf="isLoggedIn$ | async as isLoggedIn" mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
                (click)="toggleSideNav()"
              >
                <mat-icon>menu</mat-icon>
            </button>
          </div>
          <div class="display-inline">
            <mat-card-subtitle class="sub-title" *ngIf="isLoggedIn$ | async as isLoggedIn">
              <span>Total Health & Fitness</span>
              <span *ngFor="let item of headerData$" class="spec-route-item"><span class="spn-dash">/</span> <span (click)="onRoute(item.path)">{{item.name}}</span></span>
            </mat-card-subtitle>
          </div>
        </div>
        <div class="col-30 right-items" *ngIf="isLoggedIn$ | async as isLoggedIn">
          <span class="notification_icon" [ngClass]="{'hideBadge': notificationsNewCount !== -1}">
            <mat-icon aria-hidden="false" [matMenuTriggerFor]="notifications" aria-label="Example home icon" matBadge="{{notificationsNewCount}}" matBadgeColor="warn" >notifications</mat-icon>
          </span>
          <mat-menu #notifications="matMenu" class="nList">
            <div *ngFor="let item of notificationsList" class="notification"
              (click) = "onNotificationClick(item); $event.stopPropagation();"
            >
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header class="exp_panel_hdr">
                  <mat-panel-title>
                    <div>
                      <div class="notification_created_date">{{item.createdDate}}</div>
                      {{item.title}}
                    </div>
                  </mat-panel-title>

                </mat-expansion-panel-header>
                <p>{{item.message}}</p>
              </mat-expansion-panel>
            </mat-accordion>
              <!-- <div class="align-right">{{item.createdDate}}</div>
              <div>{{item.message}}</div> -->
            </div>
          </mat-menu>
          <span><mat-icon aria-hidden="false" aria-label="Example home icon">info</mat-icon></span>
          <span class="profile-pic">
            <img *ngIf="userAvatar.length > 2" mat-card-avatar [matMenuTriggerFor]="menu" [src]="userAvatar" (error)="onImgError()" />
            <span *ngIf="userAvatar.length <= 2" class="avatar-char" [matMenuTriggerFor]="menu">{{userAvatar}}</span>
          </span>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onProfile()">Profile</button>
            <button mat-menu-item (click)="onLogout()">Logout</button>
          </mat-menu>
        </div>
      </div>
    </mat-card>
  </div>
  