<div class="main-content">
  <div>
    <span class="title">Platform Setup</span>
  </div>
  <div class="content-section">
    <div>
      <mat-card class="main-card"
        (click)="onPlatformItem('exercises-uploader')">
        <mat-icon>supervised_user_circle</mat-icon>
        <span class="card-title">Exercise Uploader</span>
      </mat-card>
      <mat-card class="main-card"
      (click)="onPlatformItem('meals-uploader')">
      <mat-icon>supervised_user_circle</mat-icon>
      <span class="card-title">Meals uploader</span>
    </mat-card>
      <mat-card class="main-card"
        (click)="onPlatformItem('roles-editor')">
        <mat-icon>supervised_user_circle</mat-icon>
        <span class="card-title">Roles Editor</span>
      </mat-card>
      <mat-card class="main-card">
        <mat-icon>supervised_user_circle</mat-icon>
        <span class="card-title">Branding</span>
      </mat-card>
      <mat-card class="main-card">
        <mat-icon>supervised_user_circle</mat-icon>
        <span class="card-title">Billing</span>
      </mat-card>
      <mat-card class="main-card">
        <mat-icon>supervised_user_circle</mat-icon>
        <span class="card-title">Program Settings</span>
      </mat-card>
      <mat-card class="main-card">
        <mat-icon>supervised_user_circle</mat-icon>
        <span class="card-title">Reports Editor</span>
      </mat-card>
    </div>
  </div>
</div>
<!-- <div>
    <h1>Platform Setup</h1>
    <mat-tab-group mat-align-tabs="start">
        <mat-tab label="Branding">Content 1</mat-tab>
        <mat-tab label="Configure Features">Content 2</mat-tab>
        <mat-tab label="Alerts / Nudges">Content 3</mat-tab>
        <mat-tab label="Translations">Content 2</mat-tab>
        <mat-tab label="Integrations">Content 3</mat-tab>
      </mat-tab-group>
</div> -->