import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { config } from '../../config';
import { ToasterService } from '../../services/toaster/toaster.service';
import { UiService } from '../../services/uiloader/uiloader.service';

@Component({
  selector: 'app-mealsuploadercategories',
  templateUrl: './mealsuploadercategories.component.html',
  styleUrls: ['./mealsuploadercategories.component.sass']
})
export class MealsuploadercategoriesComponent implements OnInit {
  @Input() mealsCategories:any;

  selected_category = -1;
  default_value = -1;

  displayedColumns = ['name', 'parentcategory','mealtemplates', 'delete'];
  datasource:any = [];

  constructor(private httpService: HttpService,
    private toasterService: ToasterService,
    private ui: UiService) { }

  ngOnInit(): void {
    this.getMealsTempPerCategory();
  }

  onChangeMealCategory(index:number) {
    const record = this.datasource[index];
    const url = `${config.apiUrl}/meals/updatemealtemplatecategory/${record.id}`;
    const reqObj = {
      parent_id: record.parent_id
    };

    this.httpService.putCall(url, reqObj).subscribe(record => {

    }, err => {

    });

  }

  onFilterMealCategory() {
    this.getMealsTempPerCategory(); 
  }

  getMealsTempPerCategory() {
    this.ui.spin$.next(true);
    const categoryId = this.selected_category === -1 ? '' : this.selected_category;
    const url = `${config.apiUrl}/meals/getmealtemplatespercategory/${categoryId}`;
    this.httpService.getCall(url).subscribe(result => {
      this.datasource = result;
      this.ui.spin$.next(false);
    }, err => {
      this.ui.spin$.next(false);
    });
  }

  deleteHandle(id:number, index:number) {
    this.ui.spin$.next(true);
    const url = `${config.apiUrl}/meals/deletemealtemplates`;
    this.httpService.putCall(url, {meal_ids: [id]}).subscribe(result => {
      const arr = this.datasource;
      arr.splice(index, 1);
      this.datasource = arr;
      this.datasource = this.datasource.filter((record:any)=>{
        return id != record.id;
      });
      this.ui.spin$.next(false);
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', err?.error?.errors[0]?.msg);
    });
  }

}
