import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-selectinput',
  templateUrl: './selectinput.component.html',
  styleUrls: ['./selectinput.component.sass']
})
export class SelectinputComponent implements OnInit {
  @Input() placeholder:string = '';
  @Input() itemValue:string = '';
  @Input() isFocus:string = '0';
  @Output() action = new EventEmitter<any>();
  @Input() indexValue:number = -1;
  @Input() setsValue:number = -1;
  @Input() type:string = '';
  @Input() isDisabled:boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  onValueChange() {
    
  }

  onInputChange(event:any) {
    const obj = {
      index: this.indexValue,
      setIndex: this.setsValue,
      info: event.target.value,
      type: this.type
    };
    this.action.emit(obj);
  }

  onInputFocus(event:any) {
    if (this.isFocus == '1') {
      event.target.select()
    }
  }
}
