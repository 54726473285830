import { Component, OnInit } from '@angular/core';
import { config } from '../config';
import { HttpService } from '../services/http/http.service';
import { CommonService } from '../services/common/common.service';

@Component({
  selector: 'app-recipes-uploader',
  templateUrl: './recipes-uploader.component.html',
  styleUrls: ['./recipes-uploader.component.sass']
})
export class RecipesUploaderComponent implements OnInit {

  recipesCategories = [];
  constructor(private httpService: HttpService,
    private commonService: CommonService) { 
    this.getRecipesCategories();
  }

  ngOnInit(): void {
    const routes:any = [{
      name: 'Uploader',
      path: 'uploader'
    },{
      name: 'Recipes Uploader',
      path: 'recipes-uploader'
    }];

    this.commonService.setHeaderData(routes);
  }

  getRecipesCategories() {
    const url = `${config.apiUrl}/recipe/getrecipecategory`;
    this.httpService.getCall(url).subscribe((result:any) => {
      this.recipesCategories = result;
    }, (err:any) => {
      
    });
  }

}
