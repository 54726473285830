<div class="row record-item">
    <div class="col-60 spec-label">{{name}}</div>
    <div class="col-40">
        <mat-form-field class="example-full-width" *ngIf="type == 'general'">
            <mat-label>{{name}}</mat-label>
            <input matInput [placeholder]="name" [(ngModel)]="itemValue" [type]="inputType" >
        </mat-form-field>
        <mat-form-field *ngIf="type === 'date'">
            <mat-label>{{name}}</mat-label>
            <input matInput [matDatepicker]="picker" [disabled]="disabled" [formControl]="dateValue" [min]="minStartDate" [max]="maxBirthDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker [disabled]="!disabled"></mat-datepicker>
        </mat-form-field>
        <div *ngIf="type === 'gender'">
            <label id="example-radio-group-label">Select Gender</label>
            <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="itemValue">
                <mat-radio-button class="example-radio-button" *ngFor="let option of radioOptions" [value]="option.value">
                    {{option.text}}
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <div *ngIf="type === 'multiinputs'">
            <div *ngFor="let item of userData">
                <mat-form-field class="example-full-width" *ngIf="item.elementType !== 'select'">
                    <mat-label>{{item.placeholder}}</mat-label>
                    <input matInput [placeholder]="item.placeholder" [(ngModel)]="item.value" >
                </mat-form-field>
                <mat-form-field *ngIf="item.elementType === 'select'">
                    <mat-label>{{item.placeholder}}</mat-label>
                    <mat-select [(ngModel)]="item.value" name="state">
                    <mat-option value="">None</mat-option>
                      <mat-option *ngFor="let data of selectData" [value]="data.stateCode">
                        {{data.stateName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
        </div>
        <div *ngIf="type === 'select'">
            <mat-form-field>
                <mat-label>{{name}}</mat-label>
                <mat-select [(ngModel)]="itemValue" name="dropdown">
                <mat-option value="">None</mat-option>
                  <mat-option *ngFor="let data of selectData" [value]="data.key">
                    {{data.value}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        
        <div class="action">
            <button mat-button (click)="onSaveData(false)">Cancel</button>
            <button mat-flat-button color="primary" (click)="onSaveData(true)">Save</button>
        </div>
    </div>
</div>