import { Component, OnInit, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpService } from '../../services/http/http.service';
import { config } from '../../config';
import { ToasterService } from '../../services/toaster/toaster.service';
import { LogService } from '../../services/log/log.service';
import { UiService } from '../../services/uiloader/uiloader.service';

let exerciseTimer:any = '';

@Component({
  selector: 'app-exercise-plans-uploader-edit',
  templateUrl: './exercise-plans-uploader-edit.component.html',
  styleUrls: ['./exercise-plans-uploader-edit.component.sass'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ExercisePlansUploaderEditComponent implements OnInit {
  @Input() exerciseCategories:any;
  searchText = '';
  deletehighlight = false;

  isAllSelected: any = false;

  datasource: any = [];
  columnsList: string[] = ['name', 'category', 'description', 'instructions'];
  innerDisplayedColumns: string[] = ['name', 'sunday','monday','tuesday','wednesday','thursday','friday','saturday'];
  exercisePlansColumns:string[] = ['name', 'category', 'sets', 'reps', 'rest', 'speed', 'mode', 'intensity', 'heartrate', 'duration', 'note'];

  pagination = {
    count: 0,
    perPage: 25,
    pageIndex: 0,
    searchtext: '',
    totalCount: 0
  };

  constructor(private httpService: HttpService,
    private toasterService: ToasterService,
    private logService: LogService,
    private ui: UiService) { 
      this.getExercisePrograms();
    }

  ngOnInit(): void {
  }

  deleteExercises() {
    this.ui.spin$.next(true);
    const payload = this.datasource.filter((obj: any) => obj.checked).map((obj: any) => obj.id);
    this.httpService.putCall(`${config.apiUrl}/exerciseplans/deleteexerciseplantemplates`, { template_ids: payload }).subscribe((result: any) => {
      this.datasource = this.datasource.filter((val: any) => !val.checked);
      this.masterSelection();
      this.toasterService.show('success', 'Success', 'Nutrition template deleted successfully');
      this.ui.spin$.next(false);
      this.getExercisePrograms();
    }, (err: any) => {
      this.logService.logError(err);
      this.toasterService.show('error', 'Error', err?.error?.errors[0]?.msg);
      this.ui.spin$.next(false);
    });
  }

  onExpandExerciseItem(index:number) {
    const { id } = this.datasource[index];
    const url = `${config.apiUrl}/exerciseplans/getexerciseplantemplates/${id}`;
    this.httpService.getCall(url).subscribe(result => {
      this.datasource[index].exerciseplantemplateplans = result.exerciseplantemplateplans;
    }, err => {
      this.logService.logError(err);
      this.toasterService.show('error', 'Error', err?.error?.errors[0]?.msg);
      this.ui.spin$.next(false);
    });
  }

  masterSelection() {
    let highlgt = this.datasource.find((o: any) => o.checked == true);
    highlgt ? this.deletehighlight = true : this.deletehighlight = false;
  }

  setSelection(ev: any, row: any = '') {
    let overall = true;
    this.datasource = this.datasource.map((ob: any) => {
      if (row && ob.id === row.id) ob.checked = ev.checked;
      if (!row) ob.checked = ev.checked;
      if (!ob.checked) overall = false;
      return ob;
    });
    this.masterSelection();
    this.isAllSelected = overall;
  }
  
  saveNewValue(row: any, key: string, i: number) {
    const url = `${config.apiUrl}/exerciseplans/updateexerciseplantemplate/${row.id}`;
    let req: any = {
    }
    if (key === 'category') {
      req.exercisecategory = row.exerciseplantemplatecategory_id;
    } else {
      req[key] = row[`${key}`];
    }
    if(req[key]=="" || req.exerciseplantemplatecategory_id=="" || req.exerciseplantemplatecategory_id<=0){
      this.toasterService.show('error', "Invalid "+key+" value", key+" value is required to update");
      return;
    }
    
    this.httpService.putCall(url, req).subscribe(result => {
      if (key === 'category') {
        const records = this.exerciseCategories.filter((item: any) => item.id === row.nutritionprogramcategory_id);
        this.datasource[i].exerciseplantemplatecategory.name = records[0].name;
      }
      this.cancelEdit(row, key, i);
      this.toasterService.show('success', 'Success', 'Updated successfully');
    }, err => {
      this.toasterService.show('error', 'Failed', 'Failed to update');
    });
  }

  enableEdit(row: any, field: any, i: number) {
    this.datasource[i][`${field}Edit`] = true;
  }

  cancelEdit(row: any, field: string, i: number = 0) {
    this.datasource[i][`${field}Edit`] = false;
  }

  getExercisePrograms() {
    const url = `${config.apiUrl}/exerciseplans/getexerciseplantemplates`;

    const reqObj = {
      "orderByOn": "name",
      "orderBy": "ASC",
      "limit": this.pagination.perPage,
      "fromIndex": this.pagination.pageIndex,
      "searchtext": this.searchText
    };
    this.httpService.postCall(url, reqObj).subscribe((result:any) => {
      this.datasource = result.rows.map((item: any) => ({
        ...item,
        isExpanded: false
      }
      ));
      this.pagination.count = result.count;
      this.ui.spin$.next(false);
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error Occurred', 'Error occurred, Please try again');
    });
  }

  searchRecords(text: string) {
    this.searchText = text;
    this.pagination.pageIndex = 0;
    this.getExercisePrograms();
  }

  getServerData(e: any) {
    this.pagination.pageIndex = e.pageIndex;
    this.pagination.perPage = e.pageSize;
    this.getExercisePrograms();
  }

  searchResult(ev: any) {
    clearTimeout(exerciseTimer);
    exerciseTimer = setTimeout(() => {
      this.searchRecords(ev.target.value);
    }, 1000);
  }

  masterInnerSelection(index:number) {
    let highlgt = this.datasource[index].exerciseplantemplateplans.find((o: any) => o.checked == true);
    highlgt ? this.datasource[index].deletehighlight = true : this.datasource[index].deletehighlight = false;
  }

  setInnerSelection(ev:any, index:number, row:any = '') {
    let overall = true;
    this.datasource[index].exerciseplantemplateplans = this.datasource[index].exerciseplantemplateplans.map((ob: any) => {
      if (row && ob.id === row.id) ob.checked = ev.checked;
      if (!row) ob.checked = ev.checked;
      if (!ob.checked) overall = false;
      return ob;
    });
    this.masterInnerSelection(index);
    this.datasource[index].isAllSelected = overall;
  }

  masterInnerExerciseSelection(index:number, innerIndex:number) {
    let highlgt = this.datasource[index].exerciseplantemplateplans[innerIndex].exerciseplantemplateplanexercises.find((o: any) => o.checked == true);
    highlgt ? this.datasource[index].exerciseplantemplateplans[innerIndex].deletehighlight = true : this.datasource[index].exerciseplantemplateplans[innerIndex].deletehighlight = false;
  }

  setInneExerciseSelection(ev:any, index:number, innerIndex:number, row:any = '') {
    let overall = true;
    this.datasource[index].exerciseplantemplateplans[innerIndex].exerciseplantemplateplanexercises = this.datasource[index].exerciseplantemplateplans[innerIndex].exerciseplantemplateplanexercises.map((ob: any) => {
      if (row && ob.id === row.id) ob.checked = ev.checked;
      if (!row) ob.checked = ev.checked;
      if (!ob.checked) overall = false;
      return ob;
    });
    this.masterInnerExerciseSelection(index, innerIndex);
    this.datasource[index].exerciseplantemplateplans[innerIndex].isAllSelected = overall;
  }

  enableInnerEdit(row:any, field: string, i: number, innerI: number) {
    this.datasource[i].exerciseplantemplateplans[innerI][`${field}Edit`] = true;
    if (field !== 'name') {
      this.datasource[i].exerciseplantemplateplans[innerI][`${field}Modal`] = this.datasource[i].exerciseplantemplateplans[innerI][`${field}`];
    }
  }

  saveInnerNewValue(row:any, field: string, i: number, innerI: number) {
    const { id } = this.datasource[i];
    const url = `${config.apiUrl}/exerciseplans/updateexerciseplantemplateplan/${id}/${row.id}`;
    const reqObj:any = {
    };
    if (field === 'name') {
      reqObj[field] = row[`${field}Modal`];
    } else {
      reqObj[field] = row[`${field}Modal`] ? 1 : 0;
    }
    this.httpService.putCall(url,reqObj).subscribe(result => {
      this.datasource[i].exerciseplantemplateplans[innerI][field] = reqObj[field];
      this.cancelInnerEdit(row, field, i, innerI);
    }, err => {

    });
  }

  cancelInnerEdit(row:any, field: string, i: number, innerI: number) {
    this.datasource[i].exerciseplantemplateplans[innerI][`${field}Edit`] = false;
  }

  enableInnerExerciseEdit(row:any, field: string, i: number, innerI: number, specificExerciseI: number) {
    const record = this.datasource[i].exerciseplantemplateplans[innerI].exerciseplantemplateplanexercises[specificExerciseI];
    record[`${field}Edit`] = true;
    record[`${field}Modal`] = record[`${field}`];
    //JSON.parse(JSON.stringify(record[`${field}`]));
  }
  
  saveExerciseNewValue(row:any, field: string, i: number, innerI: number, specificExerciseI: number) {
    const templatePlanId = this.datasource[i].exerciseplantemplateplans[innerI].id;
    const exerciseId = this.datasource[i].exerciseplantemplateplans[innerI].exerciseplantemplateplanexercises[specificExerciseI].id;
    const url = `${config.apiUrl}/exerciseplans/updateexerciseplanexercise/${templatePlanId}/${exerciseId}`;
    let reqObj:any = {};
    reqObj[field] = row[`${field}Modal`];

    this.httpService.putCall(url, reqObj).subscribe(result => {
      this.datasource[i].exerciseplantemplateplans[innerI].exerciseplantemplateplanexercises[specificExerciseI][field] = reqObj[field];
      this.cancelExerciseEdit(row, field, i, innerI, specificExerciseI);
    }, err => {

    });

  }

  cancelExerciseEdit(row:any, field: string, i: number, innerI: number, specificExerciseI: number) {
    this.datasource[i].exerciseplantemplateplans[innerI].exerciseplantemplateplanexercises[specificExerciseI][`${field}Edit`] = false;
  }

  deleteInnerNutritions(index:number) {
    this.ui.spin$.next(true);
    const { id } = this.datasource[index];
    const payload = this.datasource[index].exerciseplantemplateplans.filter((obj: any) => obj.checked).map((obj: any) => obj.id);
    this.httpService.putCall(`${config.apiUrl}/exerciseplans/deleteexerciseplantemplateplans/${id}`, { templateplan_ids: payload }).subscribe((result: any) => {
      this.datasource[index].exerciseplantemplateplans = this.datasource[index].exerciseplantemplateplans.filter((val: any) => !val.checked);
      this.masterInnerSelection(index);
      this.datasource[index].isAllSelected = false;
      this.toasterService.show('success', 'Success', 'Exercise template deleted successfully');
      this.ui.spin$.next(false);
      // this.getNutritionPrograms();
    }, (err: any) => {
      this.logService.logError(err);
      this.toasterService.show('error', 'Error', err?.error?.errors[0]?.msg);
      this.ui.spin$.next(false);
    });
  }

  deleteSpecificExercise(index:number, innerIndex:number) {
    const {id} = this.datasource[index].exerciseplantemplateplans[innerIndex];
    const payload = this.datasource[index].exerciseplantemplateplans[innerIndex].exerciseplantemplateplanexercises.filter((obj: any) => obj.checked).map((obj: any) => obj.id);
  
    this.httpService.putCall(`${config.apiUrl}/exerciseplans/deleteexerciseplantemplateplanexercises/${id}`, { exercise_ids: payload }).subscribe((result: any) => {
      this.datasource[index].exerciseplantemplateplans[innerIndex].exerciseplantemplateplanexercises = this.datasource[index].exerciseplantemplateplans[innerIndex].exerciseplantemplateplanexercises.filter((val: any) => !val.checked);
      this.masterInnerExerciseSelection(index, innerIndex);
      this.datasource[index].exerciseplantemplateplans[innerIndex].isAllSelected = false;
      this.toasterService.show('success', 'Success', 'Exercise template deleted successfully');
      this.ui.spin$.next(false);
      // this.getNutritionPrograms();
    }, (err: any) => {
      this.logService.logError(err);
      this.toasterService.show('error', 'Error', err?.error?.errors[0]?.msg);
      this.ui.spin$.next(false);
    });
  }

}
