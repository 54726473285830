<div class="main-container">
    <div class="header">
        Foods Uploader
    </div>
    <div>
        <mat-tab-group mat-align-tabs="start" >
            <mat-tab label="Create">
                <app-foodsuploadercreate [foodsCategories]="foodsCategories"></app-foodsuploadercreate>
            </mat-tab>
            <mat-tab label="Edit">
                <app-fooduploaderedit [foodsCategories]="foodsCategories"></app-fooduploaderedit>
            </mat-tab>
            <mat-tab label="Settings">
                <app-fooduploadsettings></app-fooduploadsettings>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>