import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { config } from '../../config';
import { LogService } from '../../services/log/log.service';
import { UiService } from '../../services/uiloader/uiloader.service';
import { ToasterService } from '../../services/toaster/toaster.service';

@Component({
  selector: 'app-fooduploadsettings',
  templateUrl: './fooduploadsettings.component.html',
  styleUrls: ['./fooduploadsettings.component.sass']
})
export class FooduploadsettingsComponent implements OnInit {

  constructor(private httpService: HttpService, private logService: LogService, private ui: UiService, private toasterService: ToasterService) { }

  ngOnInit(): void {
    this.getfoodtemplatecategories();
  }

  foodCategories: any = [];
  displayedColumns = ['name', 'parentcategory', 'foodtemplates'];
  datasource:any = [];
  searchtxt = 'all';

  getfoodtemplatecategories() {
    this.httpService.getCall(`${config.apiUrl}/foods/getfoodtemplatespercategory`).subscribe((result:any) => {
      this.foodCategories = result;
      this.formatTableData(result);
      this.ui.spin$.next(false);
    }, (err:any) => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });
  }

  formatTableData(result:any) {
    this.datasource = result.map((data:any) => {
      return {
        id: data.id,
        name: data.name,
        foodtemplates: data.foodtemplates,
        parentcategory: {
          list: this.foodCategories.filter((val:any) => val.name !== data.name),
          category: data.parent_id ? data.parent_id : 'noparent'
        }
      }
    });
  }

  onChangeCategory(value:any, catid:any) {
    let payload = value === "noparent" ? null : value;
    this.httpService.putCall(`${config.apiUrl}/foods/updatefoodtempcategory/${catid}`, {parent_id: payload}).subscribe((result:any) => {
      this.ui.spin$.next(false);
    }, (err:any) => {
      this.logService.logError(err);
      this.toasterService.show('error', 'Error', "Update Failed");
      this.ui.spin$.next(false);
    });
  }

  filterTable(value:any) {
    let data;
    if(value === 'all') {
      data = this.foodCategories;
    } else {
      data = this.foodCategories.filter((obj:any) => obj.id === value);
    }
    this.formatTableData(data);
  }

  deleteHandle(id:any) {
    this.ui.spin$.next(true);
    this.httpService.deleteCall(`${config.apiUrl}/foods/deletefoodtempcategory/${id}`, {}).subscribe((result:any) => {
      this.datasource = this.datasource.filter((val:any) => val.id !== id);
      this.ui.spin$.next(false);
    }, (err:any) => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });
  }

  createNewCategory() {
    this.ui.spin$.next(true);
    this.httpService.postCall(`${config.apiUrl}/foods/createfoodtempcategory`, {}).subscribe((result:any) => {
      const data = result.success;
      this.datasource.push({
        id:data.id,
        name: data.name,
        foodtemplates: [],
        parentcategory: {list:this.foodCategories, category: 'noparent'}
      })
      this.ui.spin$.next(false);
    }, (err:any) => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });
  }

}
