import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {PortalModule} from '@angular/cdk/portal';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';

import { CustomMaterialModule } from './core/material.module';

import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { SidenavService } from './services/sidenav/sidenav.service';
import { ConfigService } from './services/config/config.service';
import { HttpService } from './services/http/http.service';
import { ProgressbarService } from './services/progressbar/progressbar.service';
import { ToasterService } from './services/toaster/toaster.service';
import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { EmbedVideo } from 'ngx-embed-video';
import { ToasterComponent } from './components/toaster/toaster.component';
import { ToasterContainerComponent } from './components/toaster/toaster.container.component';
import { HeaderService } from './services/header/header.service';

import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { ConsultantsComponent } from './consultants/consultants.component';
import { UsersComponent } from './users/users.component';
import { ReportsComponent } from './reports/reports.component';
import { PlatformComponent } from './platform/platform.component';
import { UploaderComponent } from './uploader/uploader.component';
import { UserdetailsComponent } from './userdetails/userdetails.component';
import { UseritemComponent } from './useritem/useritem.component';

import { CookieService } from 'ngx-cookie-service';
import { TokenInterceptor } from './auth/token.interceptor';

import { OverlayModule } from '@angular/cdk/overlay';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LoginflowComponent } from './loginflow/loginflow.component';
import { SurveyComponent } from './survey/survey.component';
import { SelectradioComponent } from './selectradio/selectradio.component';
import { SelectcheckboxComponent } from './selectcheckbox/selectcheckbox.component';
import { SelectinputComponent } from './selectinput/selectinput.component';
import { CreateuserComponent } from './createuser/createuser.component';
import { SurveyfinishComponent } from './surveyfinish/surveyfinish.component';
// import { AppleSigninModule } from 'ngx-apple-signin';

import { AppleLoginProvider } from './providers/apple-login.provider';
import { AppleComponent } from './apple/apple.component';
import { SurveyinitComponent } from './surveyinit/surveyinit.component';
import { AdduserComponent } from './adduser/adduser.component';

import { ConsultantsubsComponent } from './consultants/consultantsubs/consultantsubs.component';
import { ProspectsComponent } from './prospects/prospects.component';
import { ProspectprofileComponent } from './prospectprofile/prospectprofile.component';
import { PlatformsetupComponent } from './platformsetup/platformsetup.component';
import { RoleseditorComponent } from './roleseditor/roleseditor.component';
import { ResourcesComponent } from './resources/resources.component';

import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { LogService } from './services/log/log.service';
import { HighchartsChartModule } from 'highcharts-angular';
import { CompositionComponent } from './composition/composition.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NutritionTableComponent } from './nutrition/nutrition-table/nutrition-table.component';
import { ClientdashboardComponent } from './clientdashboard/clientdashboard.component';
import { ClientexerciseComponent, ClientExerciseInfo } from './clientexercise/clientexercise.component';
import { ClientresourcesComponent } from './clientresources/clientresources.component';
import { UserprofileComponent } from './userprofile/userprofile.component';
import { ClientnutritionComponent } from './clientnutrition/clientnutrition.component';
import { SurveysummaryComponent } from './surveysummary/surveysummary.component';
import { CopySessionDialog, NewprogramComponent } from './newprogram/newprogram.component';
import { CopyDialogComponent, SessionComponent } from './session/session.component';
import { CompositionsessionComponent, PrintDialogComponent } from './session/compositionsession/compositionsession.component';
import { CopyExcerciseDialogComponent, ExercisesessionComponent, ExerciseInfo } from './session/exercisesession/exercisesession.component';
import { ProgramcreationComponent } from './programcreation/programcreation.component';
import { FoodsuploaderComponent } from './foodsuploader/foodsuploader.component';
import { FoodsuploadercreateComponent } from './foodsuploader/foodsuploadercreate/foodsuploadercreate.component';
import { FooduploadereditComponent } from './foodsuploader/fooduploaderedit/fooduploaderedit.component';

import { NotificationsComponent } from './notifications/notifications.component';
import { InbodyComponent } from './inbody/inbody.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ExercisesuploaderComponent } from './exercisesuploader/exercisesuploader.component';
import { ExercisesuploadercreateComponent } from './exercisesuploader/exercisesuploadercreate/exercisesuploadercreate.component';
import { ExercisesuploadereditComponent } from './exercisesuploader/exercisesuploaderedit/exercisesuploaderedit.component';
import { ExercisesuploadercategoriesComponent } from './exercisesuploader/exercisesuploadercategories/exercisesuploadercategories.component';
import { FooduploadsettingsComponent } from './foodsuploader/fooduploadsettings/fooduploadsettings.component';
import { MealsuploaderComponent } from './mealsuploader/mealsuploader.component';
import { MealsuploadercreateComponent } from './mealsuploader/mealsuploadercreate/mealsuploadercreate.component';
import { MealsuploadereditComponent } from './mealsuploader/mealsuploaderedit/mealsuploaderedit.component';
import { MealsuploadercategoriesComponent } from './mealsuploader/mealsuploadercategories/mealsuploadercategories.component';
import { NutritionixFoodComponent } from './nutritionix-food/nutritionix-food.component';
import { RecipesUploaderComponent } from './recipes-uploader/recipes-uploader.component';
import { RecipesuploadercategoriesComponent } from './recipes-uploader/recipesuploadercategories/recipesuploadercategories.component';
import { RecipesuploadercreateComponent, CopyRecipeDialog } from './recipes-uploader/recipesuploadercreate/recipesuploadercreate.component';
import { RecipesuploadereditComponent } from './recipes-uploader/recipesuploaderedit/recipesuploaderedit.component';
import { NutritionProgramUploaderComponent } from './nutrition-program-uploader/nutrition-program-uploader.component';
import { ExercisePlansUploaderComponent } from './exercise-plans-uploader/exercise-plans-uploader.component';
import { NutritionProgramUploaderCreateComponent } from './nutrition-program-uploader/nutrition-program-uploader-create/nutrition-program-uploader-create.component';
import { NutritionProgramUploaderEditComponent } from './nutrition-program-uploader/nutrition-program-uploader-edit/nutrition-program-uploader-edit.component';
import { NutritionProgramUploaderCategoriesComponent } from './nutrition-program-uploader/nutrition-program-uploader-categories/nutrition-program-uploader-categories.component';
import { ExercisePlansUploaderEditComponent } from './exercise-plans-uploader/exercise-plans-uploader-edit/exercise-plans-uploader-edit.component';
import { ExercisePlansUploaderCategoriesComponent } from './exercise-plans-uploader/exercise-plans-uploader-categories/exercise-plans-uploader-categories.component';
import { ExercisePlansUploaderCreateComponent } from './exercise-plans-uploader/exercise-plans-uploader-create/exercise-plans-uploader-create.component';
import { ProgramArchiveComponent } from './program-archive/program-archive.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    ToasterComponent,
    ToasterContainerComponent,
    ConsultantsComponent,
    UsersComponent,
    ReportsComponent,
    PlatformComponent,
    UploaderComponent,
    UserdetailsComponent,
    UseritemComponent,
    LoginflowComponent,
    SurveyComponent,
    SelectradioComponent,
    SelectcheckboxComponent,
    SelectinputComponent,
    CreateuserComponent,
    SurveyfinishComponent,
    AppleComponent,
    SurveyinitComponent,
    AdduserComponent,
    ConsultantsubsComponent,
    ProspectsComponent,
    ProspectprofileComponent,
    PlatformsetupComponent,
    RoleseditorComponent,
    ResourcesComponent,
    CompositionComponent,
    NutritionTableComponent,
    ClientdashboardComponent,
    ClientexerciseComponent,
    ClientExerciseInfo,
    ClientresourcesComponent,
    UserprofileComponent,
    ClientnutritionComponent,
    SurveysummaryComponent,
    NewprogramComponent,
    SessionComponent,
    CompositionsessionComponent,
    PrintDialogComponent,
    ExercisesessionComponent,
    ExerciseInfo,
    ProgramcreationComponent,
    FoodsuploaderComponent,
    FoodsuploadercreateComponent,
    FooduploadereditComponent,
    NotificationsComponent,
    InbodyComponent,
    ExercisesuploaderComponent,
    ExercisesuploadercreateComponent,
    ExercisesuploadereditComponent,
    ExercisesuploadercategoriesComponent,
    FooduploadsettingsComponent,
    MealsuploaderComponent,
    MealsuploadercreateComponent,
    MealsuploadereditComponent,
    MealsuploadercategoriesComponent,
    NutritionixFoodComponent,
    RecipesUploaderComponent,
    RecipesuploadercategoriesComponent,
    RecipesuploadercreateComponent,
    CopyRecipeDialog,
    RecipesuploadereditComponent,
    NutritionProgramUploaderComponent,
    ExercisePlansUploaderComponent,
    NutritionProgramUploaderCreateComponent,
    NutritionProgramUploaderEditComponent,
    NutritionProgramUploaderCategoriesComponent,
    ExercisePlansUploaderEditComponent,
    ExercisePlansUploaderCategoriesComponent,
    ExercisePlansUploaderCreateComponent,
    CopyDialogComponent,
    CopyExcerciseDialogComponent,
    ProgramArchiveComponent,
    CopySessionDialog, 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CustomMaterialModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    HttpClientModule,
    EmbedVideo.forRoot(),
    SocialLoginModule,
    PortalModule,
    OverlayModule,
    HighchartsChartModule,
    NgxGoogleAnalyticsRouterModule,
    NgxGoogleAnalyticsModule.forRoot('UA-187531303-1'),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NgCircleProgressModule.forRoot({
      "radius": 60,
      "space": -10,
      "outerStrokeGradient": true,
      "outerStrokeWidth": 10,
      "outerStrokeColor": "#0080F5",
      "outerStrokeGradientStopColor": "#0080F5",
      "innerStrokeColor": "#e7e8ea",
      "innerStrokeWidth": 10,
      "animateTitle": false,
      "animationDuration": 1000,
      "showUnits": false,
      "showBackground": false,
      "clockwise": true,
      "startFromZero": true,
      "lazy": true})
  ],
  providers: [AuthGuard, AuthService, SidenavService, LogService, ConfigService, HttpService, ProgressbarService, ToasterService,
    HeaderService, CookieService,
    AppleLoginProvider,
    [
      {
        provide: MAT_DATE_FORMATS,
        useValue: {
          parse: {
            dateInput: "MM-DD-YYYY",
          },
          display: {
            dateInput: 'MM-DD-YYYY',
            monthYearLabel: 'MMM YYYY',
            dateA11yLabel: 'LL',
            monthYearA11yLabel: 'MMMM YYYY',
          },
        },
      },
    ],
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '1086453462813-q3c3dkinn2g79g15clqkvivn27srke92.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              '4687773004584681'
            )
          }
        ]
      } as SocialAuthServiceConfig,
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
