import { Component, OnInit, Input, Inject } from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { FormControl } from '@angular/forms';
import {CdkDropList, moveItemInArray, copyArrayItem, CdkDragExit, CdkDragDrop} from '@angular/cdk/drag-drop';
import { AppConstants } from '../../constants/appConstants';
import { config } from '../../config';
import { HttpService } from '../../services/http/http.service';
import { LogService } from '../../services/log/log.service';
import { UiService } from '../../services/uiloader/uiloader.service';
import { CommonService } from '../../services/common/common.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToasterService } from '../../services/toaster/toaster.service';


let sessionTimeout:any;

@Component({
  selector: 'app-nutrition-program-uploader-create',
  templateUrl: './nutrition-program-uploader-create.component.html',
  styleUrls: ['./nutrition-program-uploader-create.component.sass']
})
export class NutritionProgramUploaderCreateComponent implements OnInit {
  @Input() nutritionCategories:any;
  description = '';
  selected_sub_nutrition_category = -1;
  selected_nutrition_category = -1;
  newNutritionCategory = '';
  nutritionName = '';

  nutritionCtrl:any;

  itemDisabled:boolean = true;

  nutrition:any = [];

  specificSessionPlan = ['BreakFast', 'Snack', 'Lunch', 'Dinner'];

  weeks:any = AppConstants.weekConstants;

  isAllWeeksSelected = false;

  selectedMealIndex  = -1;

  mealPlans:any = [];

  transferringItem: string | undefined = undefined;

  fixedDiv = false;

  sideTotal = {
    calories: 0,
    protein: 0,
    carbs: 0,
    fat: 0,
    protein_pct: 0,
    carbs_pct: 0,
    fat_pct: 0
  }

  hasChild = (_: number, node: any) => node.expandable;

  drop(event: any,  index:number) {
    if (event.previousContainer.id !== event.container.id) {
      copyArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      //console.log(event.item.data);
      if (index !== -1) {
        //event.item.data.mealplan_id = this.meals[index].mealplan_id;
        event.item.data.draggedIndex = index;
      }
      this.saveItem(event.item.data, index !== -1 ? this.mealPlans[this.selectedMealIndex].meals[index].id : -1);
    } else {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      //this.reorderMealFoods(this.meals[index]);
    }

    this.transferringItem = undefined;
  }

  reorderMealFoods(data:any) {

  }

  saveItem(data:any, mealId:number) {
    let url = '';
    let isFood = false;
    if (data.nutritionprogram) {
      url = `${config.apiUrl}/recipe/getrecipecaldetails/${data.id}`;
    } else if (data.mealtemplate) {
      url = `${config.apiUrl}/meals/getmealtemplatefoods/${data.id}`;
    } else if (data.recipe) {
      url = `${config.apiUrl}/recipe/getrecipecaldetails/${data.id}`;
    } else {
      isFood = true;
      this.mealPlans[this.selectedMealIndex].meals[data.draggedIndex].mealfoods.push(data);
    }
    if (!isFood) {
      this.httpService.getCall(url).subscribe(result => {
        console.log(result);
        if (data.recipe) {
          const record = {...result, ...result.recipefoods[0]};
          delete record.recipefoods;
          this.mealPlans[this.selectedMealIndex].meals[data.draggedIndex].mealfoods.push(record);
        } else if (data.mealtemplate) {
          const records = this.mealPlans[this.selectedMealIndex].meals[data.draggedIndex].mealfoods;
          this.mealPlans[this.selectedMealIndex].meals[data.draggedIndex].mealfoods = [...records, ...result.mealtemplatefoods];
          
        }
      }, err => {
        console.log(err);
      });
    }
  }

  onOrderChangeNutrition(event:any, mealIndex:number, foodIndex:number) {
    console.log(event);
  }

  entered(e:any) {
    this.transferringItem = undefined;
  }

  exited(e: any) {
    this.transferringItem = e.item.data;
  }

  private _transformer = (node: any, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      id: node.id,
      nutritionprogram: node.nutritionprogram,
      mealtemplate: node.mealtemplate,
      recipe: node.recipe,
      foodData: (!node.nutritionprogram && !node.mealtemplate && !node.recipe) ? JSON.stringify(node) : null
    };
  };

  treeControl = new FlatTreeControl<any>(
    (node:any) => node.level,
    (node:any) => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  newPlanName = '';

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  obtainedDataSource = [];

  constructor(private httpService: HttpService,
    private ui: UiService,
    public commonService: CommonService,
    private toasterService: ToasterService,
    private dialog: MatDialog,
    private logService: LogService) { 
    this.getProgramNutritionTabTree();
    this.nutritionCtrl = new FormControl();
  }

  ngOnInit(): void {
  }

  onChangeNutritionCategory() {
    this.selected_sub_nutrition_category = -1;
    this.nutritionName = '';
  }

  onChangeNutritionSubCategory() {
    this.selected_nutrition_category = -1;
  }

  onAction(event:any) {
    if (event.type === 'nutritionName') {
      this.nutritionName = event.info;
    } else if (event.type === 'newNutritionCategory') {
      this.newNutritionCategory = event.info;
    } else if (event.type === 'newPlanName') {
      this.newPlanName = event.info;
    }
  }

  onResetNutrition() {
    this.description = '';
    this.selected_sub_nutrition_category = -1;
    this.selected_nutrition_category = -1;
    this.newNutritionCategory = '';
    this.nutritionName = '';
    this.mealPlans = [];
  }

  onSubmitNutrition() {
    const url = `${config.apiUrl}/nutritionprograms/createnutritionprogram`;
    const reqObj = {
      name: this.newPlanName,
      description: this.description,
      category: this.newNutritionCategory || this.selected_nutrition_category,
      subcat: this.selected_sub_nutrition_category,
      mealplans: this.mealPlans
    };
    this.httpService.postCall(url, reqObj).subscribe(result => {
      this.toasterService.show('success', 'Success', 'Plan created successfully');
      this.onResetNutrition();
    }, err => {
      this.toasterService.show('error', 'Error', 'Error while creating plan');
    });
  }

  onSideScroll(ev:any) {
    if (ev.currentTarget.scrollY > 310) {
      this.fixedDiv = true;
    } else {
      this.fixedDiv = false;
    }
  }

  filterChanged(filterText: any) {
    let filteredTreeData = [];
    if(filterText.target.value)
    {
      function filter(array:any, text:any) {
        const getChildren = (result:any, object:any) => {
          var re = new RegExp(text, 'gi');
          if (object.name.match(re)) {
            result.push(object);
            return result;
          }
          if (Array.isArray(object.children)) {
            const children = object.children.reduce(getChildren, []);
            if (children.length) result.push({ ...object, children });
          }
          return result;
        };

        return array.reduce(getChildren, []);
      }

      filteredTreeData = filter(this.obtainedDataSource, filterText.target.value);
      this.dataSource.data = filteredTreeData;

      //this.treeControl.expandAll();
    } else {
      this.dataSource.data = this.obtainedDataSource;
      this.treeControl.collapseAll();
    }
  }

  getProgramNutritionTabTree() {
    const url = `${config.apiUrl}/getprogramnutritiontabtree`;
    this.httpService.getCall(url).subscribe(result => {
      const data = result[0].programnutritiontabtreeindex;
      const parsed = JSON.parse(data);
      // parsed.forEach((item:any) => {
      //   item.isDragDisabled = true;
      // });
      this.dataSource.data = parsed;
      this.obtainedDataSource = parsed;
    }, err => {

    });
  }

  onAddPlan() {
    const obj = {
      name: this.newPlanName,
      weeks: JSON.parse(JSON.stringify(this.weeks)),
      meals: [{
        name: 'Breakfast',
        mealtime: '09:00',
        mealfoods: []
      },
      {
        name: 'Snack',
        mealtime: '12:00',
        mealfoods: []
      },
      {
        name: 'Lunch',
        mealtime: '14:00',
        mealfoods: []
      },
      {
        name: 'Snack',
        mealtime: '17:00',
        mealfoods: []
      },
      {
        name: 'Dinner',
        mealtime: '20:00',
        mealfoods: []
      }]
    };
    this.mealPlans.push(obj);
    this.newPlanName = '';
    this.selectedMealIndex = this.mealPlans.length - 1;
  }

  onMealSelection(index:number) {
    this.selectedMealIndex = index;
  }

  onChangeMealType(i:number, name:string = '') {

  }

  onSelectMealPlan(i:number, event:any) {
    this.onChangeMealType(i, event.target.value);
  }

  onWeekSelection() {
    const mealPlansData = this.mealPlans[this.selectedMealIndex];
    const checkedDays:any = [];
    mealPlansData.weeks.forEach((item:any) => {
      if (item.checked) {
        checkedDays.push(item.value);
      }
    });
    if (checkedDays.length === 7) {
      mealPlansData.isAllWeeksSelected = true;
    } else {
      mealPlansData.isAllWeeksSelected = false;
    }
    mealPlansData.days = checkedDays.join(',').toLowerCase();
  }

  onSelectAllDays() {
    const isAllWeeksSelected = !this.mealPlans[this.selectedMealIndex].isAllWeeksSelected;
    this.mealPlans[this.selectedMealIndex].weeks.forEach((item:any) => {
      item.checked = isAllWeeksSelected;
    });
    this.mealPlans[this.selectedMealIndex].isAllWeeksSelected = isAllWeeksSelected;
  }

  deletePlan() {
    this.mealPlans.splice(this.selectedMealIndex, 1);
  }

  copyFoodItem(data:any, index:number) {
    this.mealPlans[this.selectedMealIndex].meals[index].mealfoods.push(data);
  }

  fetchSearchedNutrition(value:string) {
    this.httpService.getCall(`${config.apiUrl}/nutrition/search/${value}`).subscribe(data => {
      this.nutrition = data.results;
    },
    err => {
      this.logService.logError(err);
    });
  }

  onSearchNutrition(ev:any) {
    const { value = '' } = ev.target;
    clearTimeout(sessionTimeout);
    sessionTimeout = setTimeout(() => {
      this.fetchSearchedNutrition(value);
    }, 600);
  }

  addFoodItem(i:number) {
    const obj = JSON.parse(JSON.stringify({displayName: '', enabled: true}));
    this.mealPlans[this.selectedMealIndex].meals[i].mealfoods.push(obj);
  }

  deleteMealFood(foods:any, i:number, j: number) {
    this.mealPlans[this.selectedMealIndex].meals[i].mealfoods.splice(j, 1);
  }

  addNoteFoodItem(i:number, j:number, item:any) {
    let dialogRef = this.dialog.open(NoteDialog, { data: {'note': item.note ? item.note : ''}, minWidth: 350, minHeight: 300 });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result.status) {
        this.mealPlans[this.selectedMealIndex].meals[i].mealfoods[j].note = result.note;
      }
    });
  }

  getTotalCount(data:any) {
    if (!data ||data.length === 0) {
      return [];
    }
    const total = {
      calories: 0,
      protein: 0,
      carbs: 0,
      fat: 0,
      protein_pct: 0,
      carbs_pct: 0,
      fat_pct: 0
    };

    data.forEach((item:any, i:number) => {
      const obj:any = {
        totalCals: 0,
        totalProtein: 0,
        totalCarbs: 0,
        totalFat:0
      };
      item.mealfoods.forEach((record:any) => {
        obj.totalCals += record.calories;
        obj.totalProtein += record.protein;
        obj.totalCarbs += record.carbs;
        obj.totalFat += record.fat;
        record.log_qty = record.qty;
        if (record.is_added_manual === 1) {
          record.enabled = true;
        }
      });

      obj.totalCals = this.commonService.roundValues(obj.totalCals);
      obj.totalProtein = this.commonService.roundValues(obj.totalProtein);
      obj.totalFat = this.commonService.roundValues(obj.totalFat);
      obj.totalCarbs = this.commonService.roundValues(obj.totalCarbs);


      total.calories += obj.totalCals;
      total.protein += obj.totalProtein;
      total.fat += obj.totalFat;
      total.carbs += obj.totalCarbs;

      const totalmacros = obj.totalProtein * 4 + obj.totalCarbs * 4 + obj.totalFat * 9;

      obj.protein_pct = totalmacros > 0 ? Math.round(obj.totalProtein * 4 / totalmacros * 100) : 0
      obj.carbs_pct = totalmacros > 0 ? Math.round(obj.totalCarbs * 4 / totalmacros * 100) : 0;
      obj.fat_pct = totalmacros > 0 ? Math.round(obj.totalFat * 9 / totalmacros * 100) : 0;

      data[i] = {
        ...data[i],
        ...obj
      };
    });
    const totalCompleteMacros = total.protein * 4 + total.carbs * 4 + total.fat * 9;
    total.protein_pct = totalCompleteMacros > 0 ? Math.round(total.protein * 4 / totalCompleteMacros * 100) : 0;
    total.carbs_pct = totalCompleteMacros > 0 ? Math.round(total.carbs * 4 / totalCompleteMacros * 100) : 0;
    total.fat_pct = totalCompleteMacros > 0 ? Math.round(total.fat * 9 / totalCompleteMacros * 100) : 0;
    this.sideTotal = total;
    return data;
  }

  setWeeksMeals() {
    this.mealPlans[this.selectedMealIndex].meals = this.getTotalCount(this.mealPlans[this.selectedMealIndex] ? this.mealPlans[this.selectedMealIndex].meals : []);
  }

  onSelectNutrition(item:any, mealIndex:number, foodIndex:number) {
    this.nutritionCtrl.setValue('');
    this.ui.spin$.next(true);

    this.httpService.postCall(`${config.apiUrl}/nutrition/nutrients`, {item: item.item_name}).subscribe(data => {
      const [ result ] = data.foods;
      this.mealPlans[this.selectedMealIndex].meals[mealIndex].mealfoods[foodIndex].name = result.food_name;
      this.mealPlans[this.selectedMealIndex].meals[mealIndex].mealfoods[foodIndex].log_qty = result.qty;
      this.mealPlans[this.selectedMealIndex].meals[mealIndex].mealfoods[foodIndex].qty = result.qty;
      this.mealPlans[this.selectedMealIndex].meals[mealIndex].mealfoods[foodIndex].calories = result.calories;
      this.mealPlans[this.selectedMealIndex].meals[mealIndex].mealfoods[foodIndex].carbs = result.carbs;
      this.mealPlans[this.selectedMealIndex].meals[mealIndex].mealfoods[foodIndex].protein = result.protein;
      this.mealPlans[this.selectedMealIndex].meals[mealIndex].mealfoods[foodIndex].fat = result.fat;
      this.mealPlans[this.selectedMealIndex].meals[mealIndex].mealfoods[foodIndex].unit = result.unit;
      this.setWeeksMeals();
      this.ui.spin$.next(false);

    },
    err => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });
  }

  deleteMealPlan(item:any, i:number) {
    this.mealPlans[this.selectedMealIndex].meals.splice(i, 1);
  }

  copyMealSection(i:any) {
    const copy = this.mealPlans[this.selectedMealIndex].meals[i];
    this.mealPlans[this.selectedMealIndex].meals.push(JSON.parse(JSON.stringify(copy)));
  }

  timeChange(ev:any) {

  }

}

@Component({
  selector: 'dialog-overview-add-note-dialog',
  template: `
  <h1 mat-dialog-title>Note</h1>
    <div mat-dialog-content class="dialogContent">
      <textarea (change)="onNoteChange($event)" [value]="data.note" class="add-note-textarea"> </textarea>
    </div>
    <div mat-dialog-actions class="dialogActions">
      <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>Submit</button>
      <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
    </div>
    `,
  styleUrls: ['../nutrition-program-uploader.component.sass']
})
export class NoteDialog {
  constructor(
    public dialogRef: MatDialogRef<NoteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

    onNoteChange(ev:any) {
      this.data.note = ev.target.value ? ev.target.value : '';
    }

  responseHandler(action:any): void {
    let obj = {
      status: false,
      note: ''
    };
    if (action) {
      obj = {
        status: true,
        note: this.data.note
      };
    }
    this.dialogRef.close(obj);
  }
}
