<div>
    <div>Add Nutrionix Food</div>
    <div>
        <div class="col-33 paddingleft-right">
            <div>
                <app-selectinput placeholder="Food Search" type="foodSearch" [itemValue]="foodSearch" (action)="onAction($event)"></app-selectinput>
            </div>
        </div>
        <div class="nutrition-table">
            <table mat-table [dataSource]="foodSearchTableData" class="mat-elevation-z10" multiTemplateDataRows>
                <ng-container matColumnDef="name">
                    <th class="fieldlbls" mat-header-cell *matHeaderCellDef> NAME </th>
                    <td mat-cell *matCellDef="let element"> 
                        <div class="client-name">{{element.item_name}}</div> 
                    </td>
                </ng-container>
                <ng-container matColumnDef="unit">
                    <th class="fieldlbls" mat-header-cell *matHeaderCellDef> UNIT </th>
                    <td mat-cell *matCellDef="let element"> 
                        <div class="client-name">{{element.serving_uom}}</div> 
                    </td>
                </ng-container>
                <ng-container matColumnDef="servingqty">
                    <th class="fieldlbls" mat-header-cell *matHeaderCellDef> SERVING QTY </th>
                    <td mat-cell *matCellDef="let element"> 
                        <div class="client-name">{{element.serving_qty}}</div> 
                    </td>
                </ng-container>
                <ng-container matColumnDef="cals">
                    <th class="fieldlbls" mat-header-cell *matHeaderCellDef> CALS </th>
                    <td mat-cell *matCellDef="let element"> 
                        <div class="client-name">{{element.calories}}</div> 
                    </td>
                </ng-container>
                <ng-container matColumnDef="photo">
                    <th class="fieldlbls" mat-header-cell *matHeaderCellDef> PHOTO </th>
                    <td mat-cell *matCellDef="let element"> 
                        <div class="client-name">
                            <img class="img_food_resource" [src]="element.thumbnail"  alt="Nutrition resource image" />
                        </div> 
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="expandedDetail">
                    <td *matCellDef="let element" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
                        colSpan="5"
                    >
                        <div *ngIf="element.foods">
                            <div *ngFor="let record of element.foods.alt_measures;let i = index" class="spec_row">
                                <mat-icon class="delete_icon" *ngIf="record.selected" (click)="onSpecificFoodItem(record, element, i)" class="success_icon">check_circle</mat-icon> 
                                <span (click)="onSpecificFoodItem(record, element, i)">
                                    <mat-icon *ngIf="!record.selected" class="success_icon">add_box</mat-icon> 
                                    <span class="align_item">{{element.item_name}} - {{record.name}}</span>
                                    <div>
                                        <span class="spn-margin">Calories: {{record.calories}}</span>
                                        <span class="spn-margin">Protein: {{record.protein}}</span>
                                        <span class="spn-margin">Carbs: {{record.carbs}}</span>
                                        <span class="spn-margin">Fat: {{record.fat}}</span>
                                        <span *ngFor="let strength of micronutrients" class="spn-margin">{{strength.label}}: {{record[strength.key]}}</span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row class="cursorPointer main-content-row example-element-row" *matRowDef="let element; columns: displayedColumns; let i = dataIndex;" (click)="onFoodSearchSelect(element, i);expandedElement = expandedElement === element ? null : element;"
                [class.example-expanded-row]="expandedElement === element"
                ></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            </table>
            <mat-paginator
                *ngIf="foodSearchTableData.length > 0"
                [length]="pagination.count"
                [pageIndex]="pagination.pageIndex"
                [pageSize]="pagination.perPage"
                [pageSizeOptions]="[10, 20, 30, 40]"
                (page)="getServerData($event)"
                showFirstLastButtons></mat-paginator>
    </div>
</div>