import { Component, OnInit } from '@angular/core';
import { config } from '../config';
import { HttpService } from '../services/http/http.service';
import { UiService } from '../services/uiloader/uiloader.service';
import { LogService } from '../services/log/log.service';
import { ToasterService } from '../services/toaster/toaster.service';
import { CommonService } from '../services/common/common.service';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.sass']
})
export class UserprofileComponent implements OnInit {
  userData:any = {};
  userNamesData:any = {};
  userAddressData:any = {};
  selectedItem:string = '';
  imgDomainUrl:string = '';
  selectData:any = [];

  genderOptions:any = [
    {
      value: 'm',
      text: 'Male'
    },
    {
      value: 'f',
      text: 'Female'
    }
  ];

  constructor(private httpService: HttpService,
    private ui: UiService,
    private logService: LogService,
    private toasterService: ToasterService,
    private authService: AuthService,
    private commonService: CommonService) { 
    const clientPath = config.ADMIN_USER_ROLE === this.authService.getUserRole() ? 'company-clients' : 'consultant-clients'
      this.commonService.setHeaderData([
        { name: 'Clients', path: clientPath},
        { name: 'User Profile', path: 'userprofile'}
      ]);
    }

  ngOnInit(): void {

    this.imgDomainUrl = config.imageUrl;
    this.getUserProfile();
    this.getStates();
  }

  getStates() {
    this.httpService.getCall(`${config.apiUrl}/getstates`).subscribe(data => {
      this.selectData = data;
    },
    err => {
        this.logService.logError(err);
        this.toasterService.show('error', 'Alert', err?.error?.error);
    });
  }
  
  setUserAddress(data:any) {
    if (!data) {
      return false;
    }
    this.userAddressData = [
      {
        key: 'address',
        value: data.address,
        placeholder: 'Address'
      },
      {
        key: 'aptsuite',
        value: data.aptsuite,
        placeholder: 'Apt Suite'
      },
      {
        key: 'city',
        value: data.city,
        placeholder: 'City'
      },
      {
        key: 'state',
        value: data.state,
        placeholder: 'State',
        elementType: 'select'
      },
      {
        key: 'postalcode',
        value: data.postalcode,
        placeholder: 'Postal Code'
      },
    ];
    return true;
  }

  getUserGender(gender: string) {
    if (gender === 'm') {
      return 'Male';
    } else if ( gender === 'f') {
      return 'Female';
    }
    return '';
  }

  setUserNamesData(data:any) {
    this.userNamesData = [
      {
        key: 'firstname',
        value: data["user.firstname"],
        placeholder: 'First Name'
      },
      {
        key: 'lastname',
        value: data["user.lastname"],
        placeholder: 'Last Name'
      }
    ];
  }

  getUserFormattedData(result:any) {
    if (result.length > 0) {
      const [data] = result;
      this.setUserNamesData(data);
      this.setUserAddress(data);
      return {
        user_id: data.id,
        firstname: data["user.firstname"],
        lastname: data["user.lastname"],
        email: data["user.email"],
        enabled: data.enabled,
        gender: this.getUserGender(data.gender),
        genderShort: data.gender,
        mobilephone: data.mobilephone,
        address: data.address,
        aptsuite: data.aptsuite,
        city: data.city,
        state: data.state,
        postalcode: data.postalcode,
        birthdate: data.birthdate,
        avatar: data?.avatar ? `${this.imgDomainUrl}${data.avatar}` : null,
      }
    } 
    return {};
  }

  onItemSelect(val:string) {
    this.selectedItem = val;
  }

  getUserProfile() {
    this.ui.spin$.next(true);
    const url = `${config.apiUrl}/getuser`;
    this.httpService.getCall(url).subscribe(result=>{
      this.userData = this.getUserFormattedData(result);
      this.ui.spin$.next(false);
    }, err =>{

      this.ui.spin$.next(false);
    });
  }

  onAction(item:any) {
    console.log(item);
    if (item.isSave) {
      this.ui.spin$.next(true);
      
      let obj:any = {};
      if (!item.type) {
        obj = {
          user_id: this.userData.user_id,
          [this.selectedItem]: item.value
        };
      } else {
        obj = item.data;
        obj.user_id = this.userData.user_id;
      }
      this.httpService.putCall(`${config.apiUrl}/updateuser`, obj).subscribe((data:any) => {
        if (item.type) {
          this.userData = {...this.userData, ...item.data};
          if (item.type === 'image') {
            this.userData.avatar = `${config.imageUrl}${item.data.avatar}`;
          }
          // this.setUserNamesData(this.userData);  
        }
        else if (this.selectedItem === 'gender') {
          this.userData[this.selectedItem] = this.getUserGender(item.value);
        } else {
          this.userData[this.selectedItem] = item.value;
        }
        this.selectedItem = '';
        this.ui.spin$.next(false);
      },
      err => {
        this.logService.logError(err);
        this.selectedItem = '';
        this.ui.spin$.next(false);
      });
    } else {
      this.selectedItem = '';
      this.setUserNamesData(this.userData);
      this.setUserAddress(this.userData);
    }
    return true;
  }

}
