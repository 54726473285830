<div>
    <div class="sub-header">
        <span>
            <button mat-icon-button aria-label="Go Back" (click)="onGoBack()">
                <mat-icon class="back-icon" aria-hidden="false" aria-label="Go Back">chevron_left</mat-icon>
            </button>
        </span>
        <span class="user-name">
            Add New User
        </span>
    </div>
    <div class="sub-content spec-section">
        <div [ngClass]="{'highlight': selectedItem === 'name'}">
            <div class="highlight-inner" tabindex="0" (keyup)="onItemSelect($event, 'name')">
                <div class="item" (click)="selectedItem = 'name'">
                    <span>Name</span>
                    <span *ngIf="addUser.firstname || addUser.lastname">{{addUser.firstname}} {{addUser.lastname}}</span>
                    <span *ngIf="!addUser.firstname && !addUser.lastname" class="no-value">Full Name</span>
                </div>
                <app-useritem *ngIf="selectedItem === 'name'"  name="Name" type="multiinputs" [userData]="userNamesData" (action)="onAction($event)"></app-useritem>
            </div>
        </div>
        <div [ngClass]="{'highlight': selectedItem === 'email'}">
            <div class="highlight-inner" tabindex="0" (keyup)="onItemSelect($event, 'email')">
                <div class="item" (click)="selectedItem = 'email'">
                    <span>Email Address</span>
                    <span *ngIf="addUser.email">{{addUser.email}}</span>
                    <span *ngIf="!addUser.email" class="no-value">Email Address</span>
                </div>
                <app-useritem *ngIf="selectedItem === 'email'"  name="Email Address" [itemValue]="addUser.email" (action)="onAction($event)"></app-useritem>
            </div>
        </div>
        <div [ngClass]="{'highlight': selectedItem === 'gender'}">
            <div class="highlight-inner" tabindex="0" (keyup)="onItemSelect($event, 'gender')">
                <div class="item" (click)="selectedItem = 'gender'">
                    <span>Gender</span>
                    <span *ngIf="addUser.gender">{{addUser.gender}}</span>
                    <span *ngIf="!addUser.gender" class="no-value">Gender</span>
                </div>
                <app-useritem *ngIf="selectedItem === 'gender'"  name="Gender" [itemValue]="addUser.genderShort" type="gender" [radioOptions]="genderOptions" (action)="onAction($event)"></app-useritem>
            </div>
        </div>
        <div [ngClass]="{'highlight': selectedItem === 'birthdate'}">
            <div class="highlight-inner" tabindex="0" (keyup)="onItemSelect($event, 'birthdate')">
                <div class="item" (click)="selectedItem = 'birthdate'">
                    <span>Birth Date</span>
                    <span *ngIf="addUser.birthdate">{{addUser.birthdate}}</span>
                    <span *ngIf="!addUser.birthdate" class="no-value">--/--/----</span>

                </div>
                <app-useritem *ngIf="selectedItem === 'birthdate'" [disabled]="isDisabled"  name="Birth Date" type="date" [dateValue]="addUser.birthdate" (action)="onAction($event)"></app-useritem>
            </div>
        </div>
        <div [ngClass]="{'highlight': selectedItem === 'mobilephone'}">
            <div class="highlight-inner" tabindex="0" (keyup)="onItemSelect($event, 'mobilephone')">
                <div class="item" (click)="selectedItem = 'mobilephone'">
                    <span>Mobile Phone</span>
                    <span *ngIf="addUser.mobilephone">{{addUser.mobilephone}}</span>
                    <span *ngIf="!addUser.mobilephone" class="no-value">Mobile Phone</span>
                </div>
                <app-useritem *ngIf="selectedItem === 'mobilephone'"  name="Mobile Phone" (action)="onAction($event)"></app-useritem>
            </div>
        </div>
        <div [ngClass]="{'highlight': selectedItem === 'address'}">
            <div class="highlight-inner" tabindex="0" (keyup)="onItemSelect($event, 'address')">
                <div class="item" (click)="selectedItem = 'address'">
                    <span>Address</span>
                    <span *ngIf="!addUser.aptsuite && !addUser.address && !addUser.city && !addUser.state && !addUser.postalcode" class="no-value">Address</span>
                    <span>{{addUser.aptsuite}}{{addUser.aptsuite ? ',' : ''}} {{addUser.address}}{{addUser.address? ',': ''}} {{addUser.city}}{{addUser.city? ',': ''}} {{addUser.state}}{{addUser.state? ',': ''}} {{addUser.postalcode}}</span>
                </div>
                <app-useritem *ngIf="selectedItem === 'address'"  name="Address" type="multiinputs" [userData]="userAddressData" [selectData]="selectData" (action)="onAction($event)"></app-useritem>
            </div>
        </div>
        <div class="error-required" *ngIf="isDisplayFieldsRequired">
            All Fields are required. Please fill all fields.
        </div>
        <div class="user-footer">
            <button mat-button (click)="onGoBack()">Cancel</button>
            <button mat-flat-button color="primary" (click)="onSubmit()">Submit</button>
        </div>
    </div>
</div>