import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Toast } from './toast.interface';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.sass']
})
export class ToasterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() toast: any =   {
  };
  @Input() i: any;

  @Output() remove = new EventEmitter<number>();

}
