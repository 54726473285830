import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { LogService } from '../services/log/log.service';
import { AppConstants } from '../constants/appConstants';
import { HttpService } from '../services/http/http.service';
import { config } from '../config';
import { UiService } from '../services/uiloader/uiloader.service';
import { ToasterService } from '../services/toaster/toaster.service';
import { CommonService } from '../services/common/common.service';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.sass']
})
export class AdduserComponent implements OnInit {
  selectedItem:string = '';
  isDisplayFieldsRequired:boolean = false;
  genderOptions:any = AppConstants.genderOptions;
  isDisabled = true;
  
  userAddressData:any = [];
  selectData:any = [];

  userNamesData:any = [
    {
      key: 'firstname',
      value: '',
      placeholder: 'First Name'
    },
    {
      key: 'lastname',
      value: '',
      placeholder: 'Last Name'
    }
  ];

  addUser:any = {
    name: '',
    dateValue: '',
    email: '',
    gender: '',
    mobilephone: ''
  };

  constructor(private location: Location,
    private httpService: HttpService,
    private loader: UiService,
    private logService: LogService,
    private toasterService: ToasterService,
    private common: CommonService) { }

  ngOnInit(): void {
    this.getStates();
    this.setUserAddress({});
  }

  setUserAddress(data:any) {
    this.userAddressData = [
      {
        key: 'address',
        value: data.address,
        placeholder: 'Address'
      },
      {
        key: 'aptsuite',
        value: data.aptsuite,
        placeholder: 'Apt Suite'
      },
      {
        key: 'city',
        value: data.city,
        placeholder: 'City'
      },
      {
        key: 'state',
        value: data.state,
        placeholder: 'State',
        elementType: 'select'
      },
      {
        key: 'postalcode',
        value: data.postalcode,
        placeholder: 'Postal Code'
      },
    ];
    return true;
  }

  getStates() {
    this.httpService.getCall(`${config.apiUrl}/getstates`).subscribe(data => {
      this.selectData = data;
    },
    err => {
        this.logService.logError(err);
    });
  }

  getUserGender(gender: string) {
    if (gender === 'm') {
      return 'Male';
    } else if ( gender === 'f') {
      return 'Female';
    }
    return '';
  }

  onItemSelect(eve:any, val:string) {
    if (eve.keyCode === 32 || eve.keyCode === 13) {
      this.selectedItem = val;
    }
  }

  onAction(item:any) {
    if (item.isSave) {
    if (this.selectedItem === 'gender') {
      this.addUser[this.selectedItem] = this.getUserGender(item.value);
      this.addUser.genderShort = item.value;
    }
    else if (!item.type) {
        this.addUser[this.selectedItem] = item.value;
    } else {
      const obj = item.data;
      this.addUser = {...this.addUser, ...obj};
    }
  }
    this.selectedItem = '';
  }

  saveUserDetails(userId:number) {
    const obj:any = {
      user_id: userId,
      firstname: this.userNamesData[0].value,
      lastname: this.userNamesData[1].value,
      gender: this.addUser.genderShort,
      birthdate: this.addUser.birthdate,
      mobilephone: this.addUser.mobilephone
    };

    this.userAddressData.forEach((item:any) => {
      obj[item.key] = item.value;
    });

    const url = `${config.apiUrl}/updateclient`;
    this.httpService.putCall(url, obj).subscribe(data => {
      this.toasterService.show('success', 'Success', 'User Created succesfully');
      this.onGoBack();
      this.loader.stopSpinner();
    }, err => {
      this.loader.stopSpinner();
    })
  }

  validateAddUser() {
    const properties = ['email', 'genderShort', 'birthdate'];
    let isValid = true;
    properties.forEach(key => {
      if (!this.addUser[key] || this.addUser[key].length === 0) {
        isValid = false;
      }
    });
    if (this.userNamesData[0].value.length === 0 &&  this.userNamesData[1].value.length === 0) {
      isValid = false;
    }
    return isValid;
  }

  onSubmit() {
    this.isDisplayFieldsRequired = false;
    const isValidateForm = this.validateAddUser();
    if (!isValidateForm) {
      this.isDisplayFieldsRequired = true;
      return false;
    }
    this.loader.showSpinner();
    const req = {
      email: this.addUser.email
    };
    const url = `${config.apiUrl}/createclient`;
    this.httpService.postCall(url, req).subscribe(data=>{
      const { success } = data;
      const split = success.split(':');
      let user_id = 0;
      if (split.length === 2) {
        user_id = parseInt(split[1]);
      }
      this.saveUserDetails(user_id);
    }, err => {
      const msg = this.common.getFormattedError(err);
      this.toasterService.show('error', 'Error Occurred', msg);
      this.loader.stopSpinner();
    });
    return true;
  }

  onGoBack() {
    this.location.back();
  }
}
