<div>
    <div class="sub-container-header">
        <div>
            <span class="start-info cursorPointer" (click)="onNavigateBackUser()">{{headerData.userName}}</span> <span class="slash-info">/</span> <span class="start-info cursorPointer" (click)="onNavigateBack()">{{headerData.sessionName}}</span> <span *ngIf="!hideTitle" class="slash-info">/</span> <span *ngIf="!hideTitle" class="end-info">Add Session</span>
            
        </div>
        <div class="white program-info">
            {{hideTitle ? headerData.sessionName : 'New Session'}}
            <span class="close_icon" (click)="onNavigateBackUser()">
                <mat-icon>close</mat-icon>
            </span>
        </div>
        <!-- <div class="sub-title">{{headerData.duration}}</div> -->
    </div>
<div class="sessiontabs">
    <mat-tab-group mat-align-tabs="start" >
        <mat-tab label="Measurements">
            <div class="tab-content">
                <app-compositionsession sessionId={{sessionId}} userId={{userId}}></app-compositionsession>
            </div>                
        </mat-tab>
        <mat-tab label="Nutrition">
            <div class="tab-content"
                cdkDropListGroup
            >
               
            <div class="title-container">
                <div class="ml_5">
                <span class="nutrition-header">Nutrition</span>
                <div class="add-plan-input">
                    <app-selectinput placeholder="New plan name" type="text" [itemValue]="newPlanName" type="new_plan" (action)="onAction($event)"></app-selectinput>
                </div>
                <div class="btn-add">
                    <button color="primary" (click)="onAddPlan()" class="spec-btn" [ngClass]="{'btn-active': newPlanName.length > 0}" [disabled]="newPlanName.length === 0">Add Plan</button>
                </div>
            </div>
                <div class="print">
                    <button mat-raised-button class="margin_10" color="secondary" (click)="downloadAsPDF()">
                        <mat-icon>print</mat-icon>
                        Meals
                    </button>
                    <button mat-raised-button class="margin_10" color="secondary" (click)="onSelectShoppingList()">
                        <mat-icon>print</mat-icon>
                        Shopping List
                    </button>
                    <button mat-raised-button color="secondary" (click)="onSelectRecipes()">
                        <mat-icon>print</mat-icon>
                        Recipes
                    </button>
                </div>
              
            </div>
            <div class="guide">
                <button mat-raised-button class="margin_10" color="secondary" (click)="showGuide('substitution_guide')">
                    <mat-icon>print</mat-icon>
                    Substitution Guide
                </button>
                <button mat-raised-button class="margin_10" color="secondary" (click)="showGuide('diningout_guide')">
                    <mat-icon>print</mat-icon>
                    Dining Out Guide
                </button>
            </div>
                <div>
                   
                    <div class="meal-sections">
                        <button class="meal-btn" (click)="onMealSelection(i)" [ngClass]="i === selectedMealIndex ? 'active_meal' : ''" [title]="item.name" *ngFor="let item of mealPlans; let i = index">{{item.name}}</button>
                    </div>
                    
                    <div>
                        <section class="nutrition-data-container"
                        >
                            <div
                                class="nutrition-sections rec col-70"
                            >
                            <section class="week-section"
                                *ngIf="!errorMsg && mealPlans.length > 0"
                            >
                                <div>
                                    <div></div>
                                    <div class="cursorPointer" (click)="onSelectAllDays()">
                                        <mat-icon *ngIf="!isAllWeeksSelected">done</mat-icon>
                                        <mat-icon color="primary" *ngIf="isAllWeeksSelected">check_circle</mat-icon>
                                    </div>
                                </div>
                                <div *ngFor="let item of weeks">
                                    <div>{{item.value}}</div>
                                    <mat-checkbox color="primary" (change)="onUpdateWeeks(item)" class="example-margin" [(ngModel)]="item.checked" ></mat-checkbox>
                                </div>
                                
                                <div class="deletePlan cursorPointer"
                                    title="Delete Plan"
                                    (click)="deletePlan()"
                                >
                                    Delete Plan
                                </div>
                                <div class="deletePlan copyPlan cursorPointer" title="Copy Plan" (click)="copyPlan()">
                                    Copy Plan
                                </div>
                            </section>
                            <!-- <mat-card *ngIf="errorMsg"
                                class="error_msg_nutrition"
                            >
                                {{errorMsg}}
                            </mat-card> -->
                            <div *ngIf="meals.length === 0"
                            cdkDropList [cdkDropListData]="meals" (cdkDropListDropped)="drop($event, -1)"
                            class="empty_drag_container"
                            >
                                <div>Drag and Drop directly from templates</div>
                            </div>
                                <div *ngFor="let item of meals; let i = index;" 
                                    class="matCard"
                                >
                                    <div class="spec-card">
                                        <div class="align_right top_move_icon">
                                            <span (click)="onSortMealPlanLower(i, false)"><mat-icon>expand_less</mat-icon></span>
                                        </div>
                                        <div>
                                            <div class="spec-program">
                                                <form class="example-form">
                                                    <div class="inp-section">
                                                        <input type="text"
                                                            [placeholder]="item.name"
                                                            [matAutocomplete]="auto"
                                                            class="meal_plan_input"
                                                            (blur)="onSelectMealPlan(i, $event)"
                                                        />
                                                    </div>
                                                    <mat-autocomplete #auto="matAutocomplete">
                                                      <mat-option *ngFor="let rec of specificSessionPlan" [value]="rec"
                                                        (click)="onChangeMealType(i, rec)"
                                                      >
                                                        {{rec}}
                                                      </mat-option>
                                                    </mat-autocomplete>
                                                  </form>
                                            </div>
                                            <div class="time-container">
                                                <input type="time" placeholder="Add Food" (change)="onChangeMealType(i)" [(ngModel)]="item.time" class="time-input" autocomplete="off" (change)="timeChange($event)"/>
                                            </div>
                                            <div class="inline floatRight">
                                                <div class="inline cursorPointer"
                                                    (click)="copyMealSection(item)"
                                                    title="Copy"
                                                >
                                                    <img alt="Copy" src="../../assets/icons/paperplus.svg" />
                                                </div>
                                                <div class="inline cursorPointer color_red"
                                                    (click)="deleteMealPlan(item, i)"
                                                    title="Delete"
                                                >
                                                    <mat-icon>delete</mat-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            [id]="item.id"
                                            cdkDropList [cdkDropListData]="item.mealfoods" (cdkDropListDropped)="drop($event, i)"
                                        >
                                            <div class="nutri-header">
                                                <div class="col-5"></div>
                                                <div class="col-30">Name</div>
                                                <div class="col-10">Qty</div>
                                                <div class="col-10">Unit</div>
                                                <div class="col-7">Cals</div>
                                                <div class="col-7">Protn</div>
                                                <div class="col-7">Carbs</div>
                                                <div class="col-7">Fat</div>
                                            </div>
                                            <div class="nutri-items"
                                                *ngFor="let foods of item.mealfoods; let j = index;"
                                                cdkDrag
                                            >
                                                <div class="col-5" cdkDragHandle><mat-icon class="menu_item_icon">menu</mat-icon></div>
                                                <div class="col-30 add-nutrition">
                                                    <!-- <app-selectinput placeholder="Drag food or type" [indexValue]="i" [itemValue]="foods.name" (action)="onAction($event)"></app-selectinput> -->
                                                    <form class="example-form">
                                                        <div class="inp-section">
                                                            <input type="text"
                                                                [placeholder]="foods.name ? foods.name : 'Drag food or type'"
                                                                [matAutocomplete]="auto" 
                                                                (change)="onSearchNutrition($event)"
                                                                (keyup)="onSearchNutrition($event)"
                                                                [formControl]="nutritionCtrl"
                                                                [(ngModel)]="foods.displayName"
                                                            />
                                                        </div>
                                                        <mat-autocomplete #auto="matAutocomplete" [panelWidth]="450" class="auto_cmpt_ddl_nutrition">
                                                          <mat-option *ngFor="let item of nutrition" [value]="item.item_name"
                                                            class="nutrition_mat_option"
                                                            (click)="onSelectNutrition(item, i, j)"
                                                          >
                                                            <span class="nutrition_item_image">
                                                                <img alt="Nutrition Image" [src]="item.thumbnail" class="nutrition_search_image" />
                                                            </span>
                                                            <span class="nutrition_item_name" *ngIf="!item.nix_item_id">
                                                                <span class="spn_font_weight">{{item.item_name}}</span>
                                                                {{item.serving_qty}} {{item.serving_uom}}
                                                            </span>
                                                            <span class="nutrition_item_name" *ngIf="item.nix_item_id">
                                                                <span class="spn_font_weight">{{item.item_name}}</span>
                                                                Brand: {{item.brand_name}}, {{item.calories}} calories, {{item.serving_qty}} {{item.serving_uom}}
                                                            </span>
                                                          </mat-option>
                                                        </mat-autocomplete>
                                                      </form>
                                                </div>
                                                <div class="col-10"><app-selectinput placeholder="Quantity" [indexValue]="i" [setsValue]="j" [itemValue]="foods.qty" type="qty" isFocus="1" (action)="onAction($event)" ></app-selectinput></div>
                                                <div class="col-10"><app-selectinput placeholder="Unit" [indexValue]="i" [setsValue]="j" [itemValue]="foods.unit" type="unit" isFocus="1" (action)="onAction($event)" [isDisabled]="foods.enabled ? !foods.enabled : itemDisabled"></app-selectinput></div>
                                                <div class="col-7"><app-selectinput placeholder="Cals" [indexValue]="i" [setsValue]="j" [itemValue]="foods.calories" type="calories" isFocus="1" (action)="onAction($event)" [isDisabled]="foods.enabled ? !foods.enabled : itemDisabled"></app-selectinput></div>
                                                <div class="col-7"><app-selectinput placeholder="Protn" [indexValue]="i" [setsValue]="j" [itemValue]="foods.protein" type="protein" isFocus="1" (action)="onAction($event)" [isDisabled]="foods.enabled ? !foods.enabled : itemDisabled"></app-selectinput></div>
                                                <div class="col-7"><app-selectinput placeholder="Carbs" [indexValue]="i" [setsValue]="j" [itemValue]="foods.carbs" type="carbs" isFocus="1" (action)="onAction($event)" [isDisabled]="foods.enabled ? !foods.enabled : itemDisabled"></app-selectinput></div>
                                                <div class="col-7"><app-selectinput placeholder="Fat" [indexValue]="i" [setsValue]="j" [itemValue]="foods.fat" type="fat" isFocus="1" (action)="onAction($event)" [isDisabled]="foods.enabled ? !foods.enabled : itemDisabled"></app-selectinput></div>
                                                <div class="col-15 action_icons">
                                                    <div class="cursorPointer icon_position" (click)="copyFoodItem(foods, i)"
                                                    >
                                                        <img alt="Copy" title="Copy" src="../../assets/icons/paperplus.svg" />
                                                    </div>
                                                    <div class="cursorPointer icon_position" (click)="addNoteFoodItem(i, j, foods)"
                                                    title="Note"
                                                    >
                                                        <mat-icon>note</mat-icon>
                                                    </div>
                                                    <div class="cursorPointer color_red icon_position" title="Delete" (click)="deleteMealFood(foods, i, j)">
                                                        <mat-icon>close</mat-icon>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                            class="cursorPointer"
                                                (click)="addFoodItem($event, i)"
                                            >
                                                <mat-icon>add_box</mat-icon>
                                            </div
                                            >
                                            <div>
                                                <div class="col-30">

                                                </div>
                                                <div class="col-20">
                                                    Meal Totals:
                                                </div>
                                                <div class="col-10">
                                                    {{item.totalCals}}
                                                </div>
                                                <div class="col-10">
                                                    {{item.totalProtein}}
                                                </div>
                                                <div class="col-10">
                                                    {{item.totalCarbs}}
                                                </div>
                                                <div class="col-10">
                                                    {{item.totalFat}}
                                                </div>
                                            </div>
                                            <div>
                                                <div class="col-30"></div>
                                                <div class="col-20"></div>
                                                <div class="col-10"></div>
                                                <div class="col-10">{{item.protein_pct}}%</div>
                                                <div class="col-10">{{item.carbs_pct}}%</div>
                                                <div class="col-10">{{item.fat_pct}}%</div>

                                            </div>
                                        </div>
                                        <div class="align_right bottom_move_icon">
                                            <span (click)="onSortMealPlanLower(i, true)"><mat-icon>expand_more</mat-icon></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="add_meal_nutrition" *ngIf="!errorMsg && mealPlans.length > 0">
                                    <span (click)="addMeal()" class="cursorPointer"><mat-icon>add</mat-icon> Add Meal</span>
                                </div>
                            </div>
                            <div class="col-30 side-nutri-options"
                            >
                                <div
                                    (window:scroll)="onSideScroll($event)"
                                    [ngClass]="{'fixed-div': fixedDiv}"
                                >
                                    <div class="search-container">
                                        <div class="input-container">
                                            <mat-icon matPrefix>search</mat-icon>
                                            <input type="text" placeholder="Meal / Food filter" class="search-input" autocomplete="off" (input)="filterChanged($event)" />
                                        </div>
                                    </div>

                                    <div>
                                        <div>
                                            <div
                                                cdkDropList
                                                [cdkDropListData]="dataSource"
                                                class="matCard"
                                            >
                                                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                                                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="0"
                                                    cdkDrag [cdkDragData]="node"
                                                    class="cursorPointer"
                                                    >
                                                      <button mat-icon-button disabled></button>
                                                      {{node.name}}
                                                    </mat-tree-node>
                                                    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="10" 
                                                    cdkDrag [cdkDragData]="node"
                                                    cdkDragDisabled="true"
                                                    >
                                                      <button mat-icon-button matTreeNodeToggle
                                                              [attr.aria-label]="'Toggle ' + node.name">
                                                        <mat-icon class="mat-icon-rtl-mirror">
                                                          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                                        </mat-icon>
                                                        {{node.name}}
                                                      </button>
                                                    </mat-tree-node>
                                                  </mat-tree>
                                            </div
                                            >
                                        </div>
                                    </div>
                                    <div class="matCard matcard_plan_total"
                                        *ngIf="mealPlans.length > 0"
                                        [ngClass]="{'fixed-bottom-div': fixedDiv}"
                                    >
                                            <div>
                                                <div class="plan_totals_hdr">Plan Totals</div>
                                                <div>
                                                    <div class="plan_totals">
                                                        <div>
                                                            Calories:
                                                        </div>
                                                        <div>
                                                            {{commonService.roundValues(sideTotal.calories)}}
                                                        </div>
                                                    </div>
                                                    <div class="plan_totals">
                                                        <div>
                                                            Protein:
                                                        </div>
                                                        <div>
                                                            {{commonService.roundValues(sideTotal.protein)}} ({{sideTotal.protein_pct}}%)
                                                        </div>
                                                    </div>
                                                    <div class="plan_totals">
                                                        <div>
                                                            Carbs:
                                                        </div>
                                                        <div>
                                                            {{commonService.roundValues(sideTotal.carbs)}} ({{sideTotal.carbs_pct}}%)
                                                        </div>
                                                    </div>
                                                    <div class="plan_totals">
                                                        <div>
                                                            Fat:
                                                        </div>
                                                        <div>
                                                            {{commonService.roundValues(sideTotal.fat)}} ({{sideTotal.fat_pct}}%)
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div
                                >
                            </div>
                        </section>
                    </div>
                </div>
            </div>                
        </mat-tab>
        <mat-tab label="Exercise">
            <div class="tab-content">
                <app-exercisesession sessionId={{sessionId}} userId={{userId}}></app-exercisesession>
            </div>                
        </mat-tab>
    </mat-tab-group>
</div>
</div>