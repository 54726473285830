import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidenavService } from './services/sidenav/sidenav.service';
import { ProgressbarService } from './services/progressbar/progressbar.service';
import { AuthService } from './auth/auth.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  title = 'my-app';
  isSideNavToggle$: any = false;
  isLoggedIn$: any = false;
  isDisplaySpinner$: any = false;
  sideNavData$: any = [];
  selectedItem:string = '';
  sub: any = {};
  constructor(private sidenavService: SidenavService,
    private progressbarService: ProgressbarService,
    private authService: AuthService,
    private router: Router,
    private location: Location) { }
  ngOnInit() {
    
    this.sidenavService.isSideNavToggle.subscribe(x =>{
      console.log(x);
      this.isSideNavToggle$ = x;
    });
    this.authService.sideMenuSelected.subscribe(x => {
      this.selectedItem = x;
    });
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.isDisplaySpinner$ = this.progressbarService.isDisplaySpinner;
    this.sideNavData$ = this.sidenavService.sideNavData;
    this.setSelectedSideMenu();
  }

  setSelectedSideMenu() {
    const path = this.location.path();
    if (path !== '/login') {
      let specificPath = path.replace('/', '');
      if (specificPath === 'dashboard') {
        specificPath = '';
      }
      this.selectedItem = specificPath;
    }
  }

  redirect(path:string, id:number,displayName:string) {
    if(displayName.toLowerCase().trim() === 'program binder'){
      window.open("/assets/NewClientBinder_2018_Education.pdf", "_blank");
      return;
    }
    this.selectedItem = path;
    if (id !== 9999) {
      this.router.navigate([`/${path}`]);
    } else {
      this.authService.switchBackToAdmin();
    }
  }

}
