import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-nutrition-table',
  templateUrl: './nutrition-table.component.html',
  styleUrls: ['./nutrition-table.component.sass']
})
export class NutritionTableComponent implements OnInit {

  @Input() datasource:any;
  @Input() type:any;
  @Input() overAllTotal:any;
  @Input() overAllTableUpdate:any;
  localData:any;
  @Output() rowChange = new EventEmitter<string>();
  @Output() delete = new EventEmitter();

  constructor(public commonService: CommonService) { }
  
  columnsList:string[] = ['data', 'calories', 'protein', 'carbs', 'fat', 'servingsize', 'delete'];
  selectAll:boolean = false;
  selectedrow: any;
  totalClr: boolean = false;
  
  ngOnInit(): void {

  }

  ngOnChanges() {
    this.localData = this.datasource.map((f:any) => f);
    let selectAll = true;
    if(this.datasource.length) {
      this.datasource.forEach((element:any) => {
        if(element.data.checked === false) selectAll = false;
      });
      this.selectAll = selectAll;
      const checkedlist = this.datasource.filter((ob:any) => {
        return ob.data.checked === true
      });
      checkedlist.length ? this.totalClr = true : this.totalClr = false;
    }
  }

  deleteClick(key:any) {
    console.log(key);
    this.delete.emit({val:key});
  }

  addNote(key:any) {
    console.log(key)
  }

  sizeChange(row: any) {
    console.log(row, this.datasource);
  }

  rowselection(ev:any, id: any, type:any) {
    const value:any = { ev: ev, id: id, type: type, obj: this.datasource};
    this.rowChange.emit(value);
  }

  changeHandler(ev:any, row:any, type:any) {
    const times = ev.target.value;
    if(times > 0 && /^\d*$/.test(times) && times !== '') {
      let isChecked: any;
      this.datasource.filter((obj:any) => {
        if(obj.id === row.id && times !== '') {
          isChecked = obj.data.checked;
        }
      });
      let cons:any = {ev: {checked: isChecked}, id: row.id, type: type, count: times, obj: this.datasource};
      this.rowChange.emit(cons);
    }
  }

  calculateAll() {
    this.overAllTableUpdate(this.type, this.selectAll);
  }

  ngOnDestroy() {
    
  }
}
