<div class="client-dashboard-container">
    <div class="title" *ngIf="!selectedUserRole && isDisplayClientDashboard">
        Dashboard
    </div>
    <div>
        <div *ngIf="isDisplayClientDashboard">
            <div class="progress">
                <div class="col-50">
                    <span class="goal-progress">Goal Progress</span> <span class="sessions" *ngIf="isDisplayClientDashboard && isDashboardLoaded && errorMsg.length === 0">{{dashboardData.weeksNow}} /
                        {{dashboardData.goal.weekstogoal}} Sessions</span>
                </div>
                <div class="col-50 col-date-picker">
                    <mat-form-field appearance="fill">
                        <div (click)="picker.open()" class="today-lbl" [style.display]="isTodayDate ? 'block' : 'none'">
                            Today</div>
                        <input [style.display]="!isTodayDate ? 'block' : 'none'" matInput [matDatepicker]="picker"
                            (focus)="picker.open()" [readonly]="true" [max]="maxDate" (dateChange)="onDateSelect($event)"
                            [formControl]="goalsDate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div *ngIf="!isDisplayClientDashboard">
            <mat-card>
                <section class="dashboardSection">
                  <img src="/assets/THFHome.png" alt="Fitness Image">  
                </section>
            </mat-card>
        </div>
        <div *ngIf="errorMsg.length > 0" class="err-info">
            <mat-card>{{errorMsg}}</mat-card>
        </div>
        <div *ngIf="isDisplayClientDashboard && isDashboardLoaded && errorMsg.length === 0">
            <div class="composition">
                <mat-card>
                    <div>
                        <div class="lbl-composition">
                            Composition
                        </div>
                        <div class="col">
                            <div class="col-50">
                                <div class="card-title">Weight</div>
                                <div class="card-value">{{dashboardData.weights[0].weight}}</div>
                                <div>
                                    <mat-icon class="success-info success-icon" *ngIf="dashboardData.weightsDiffer <= 0">
                                        arrow_downward</mat-icon>
                                    <mat-icon class="error-info success-icon" *ngIf="dashboardData.weightsDiffer > 0">
                                        arrow_upward</mat-icon> <span class="success-info info-label"
                                        [ngClass]="{'error-info': dashboardData.weightsDiffer > 0}">{{dashboardData.weightsDiffer}}
                                        lbs</span> <span class="since-start">Since Start</span>
                                </div>
                            </div>
                            <div class="col-50">
                                <highcharts-chart [Highcharts]="highcharts" [options]="dashboardWeightChartOptions"
                                    [(update)]="updateNutritionFlag" style="width: 100%; height: 100px; display: block;">
                                </highcharts-chart>
                            </div>
                        </div>
                        <div class="col">
                            <div class="col-50">
                                <div class="card-title">Body Fat</div>
                                <div class="card-value">{{dashboardData.weights[0].bodyfat}}%</div>
                                <div>
                                    <mat-icon class="success-info success-icon" *ngIf="dashboardData.bodyfatDiffer <= 0">
                                        arrow_downward</mat-icon>
                                    <mat-icon class="error-info success-icon" *ngIf="dashboardData.bodyfatDiffer > 0">
                                        arrow_upward</mat-icon> <span class="success-info info-label"
                                        [ngClass]="{'error-info': dashboardData.bodyfatDiffer > 0}">{{dashboardData.bodyfatDiffer}}
                                        %</span> <span class="since-start">Since Start</span>
                                </div>
                            </div>
                            <div class="col-50">
                                <highcharts-chart [Highcharts]="highcharts" [options]="dashboardBodyFatChartOptions"
                                    [(update)]="updateNutritionFlag" style="width: 100%; height: 100px; display: block;">
                                </highcharts-chart>
                            </div>
                        </div>
                        <div class="col">
                            <div class="col-50">
                                <div class="card-title">Muscle Mass</div>
                                <div class="card-value">{{commonService.getFixedValue(dashboardData.weights[0].leanmuscle)}}</div>
                                <div>
                                    <mat-icon class="error-info success-icon" *ngIf="dashboardData.musclemassDiffer <= 0">
                                        arrow_downward</mat-icon>
                                    <mat-icon class="success-info success-icon" *ngIf="dashboardData.musclemassDiffer > 0">
                                        arrow_upward</mat-icon> <span class="success-info info-label"
                                        [ngClass]="{'error-info': dashboardData.musclemassDiffer <= 0}">{{dashboardData.musclemassDiffer}}
                                        lbs</span> <span class="since-start">Since Start</span>
                                </div>
                            </div>
                            <div class="col-50">
                                <highcharts-chart [Highcharts]="highcharts" [options]="dashboardLeanMuscleChartOptions"
                                    [(update)]="updateNutritionFlag" style="width: 100%; height: 100px; display: block;">
                                </highcharts-chart>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
            <div class="composition-info">
                <div class="col">

                    <mat-card class="info-card">
                        <div class="card-title">Nutrition</div>
                        <div>
                            <highcharts-chart [Highcharts]="highcharts" [options]="nutritionChartOptions"
                                [(update)]="updateNutritionFlag" style="width: 100%; height: 300px; display: block;">
                            </highcharts-chart>
                        </div>
                    </mat-card>
                </div>
                <div class="col">
                    <mat-card class="info-card calories-info">
                        <div class="card-title">Calories</div>
                        <div class="cal-center">
                            <circle-progress
                                [percent]="commonService.getPercentageValue(dashboardData.nutrition.calories.consumed, dashboardData.nutrition.calories.total)"
                                [title]="commonService.roundValues(dashboardData.nutrition.calories.consumed).toString()"
                                titleFontSize="40" titleFontWeight="700" titleColor="#11101D" [showSubtitle]="false"
                                [radius]="100" [animation]="true" [animationDuration]="300">
                            </circle-progress>
                        </div>
                        <div class="goal-info">
                            Goal: {{commonService.roundValues(dashboardData.nutrition.calories.total)}}
                        </div>
                    </mat-card>
                </div>
                <div class="col">
                    <mat-card class="info-card">
                        <div class="card-title">Exercise</div>
                        <div *ngFor="let item of exerciseData" class="percent-exercise">
                            <div class="exercise-info">
                                <span class="exe-title">{{item.title}}</span> <span
                                    class="actual_total">{{item.actual}}/{{item.total}}</span>
                            </div>
                            <mat-progress-bar mode="determinate" [value]="item.percent"></mat-progress-bar>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>