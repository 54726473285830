import { Component, OnInit, Input, Inject } from '@angular/core';
import { EmbedVideoService } from 'ngx-embed-video';
import { CommonService } from '../../services/common/common.service';
import { config } from '../../config';
import { HttpService } from '../../services/http/http.service';
import { AppConstants } from '../../constants/appConstants';
import { FormControl } from '@angular/forms';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { LogService } from '../../services/log/log.service';
import { UiService } from '../../services/uiloader/uiloader.service';
import { ToasterService } from '../../services/toaster/toaster.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-exercisesession',
  templateUrl: './exercisesession.component.html',
  styleUrls: ['./exercisesession.component.sass']
})
export class ExercisesessionComponent implements OnInit {

  @Input() sessionId:any;
  @Input() userId:any;

  fixedDiv = false;

  exerciseCtrl:any;
  searchTree:any;
  weeks:any = AppConstants.weekConstants;
  weeksObj:any = this.weeks.map((o:any) => {
    o.checked = false;
    return o;
  })

  main = {
    oRest: '',
    oSpeed: '',
    oReps: '',
    oSet: ''
  };

  templateData: any = [];
  catlist: any = [];
  obtainedDataSource:any = [];
  excersieCopyObject:any={
    users:[],
    selectedUser:"",
    client:"sameClinet",
    sessions:[],
    selectedSession:'',
    clientDetais:{},
  }

  constructor(private commonService: CommonService, private httpService: HttpService, private ui: UiService,
    private logService: LogService,
    private dialog: MatDialog,
    private toasterService: ToasterService) { 
      this.exerciseCtrl = new FormControl();
    }

  private _transformer = (node: any, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      isDragDisabled: node.isDragDisabled,
      exeData: node
    };
  };

  treeControl = new FlatTreeControl<any>(
    (node:any) => node.level,
    (node:any) => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: any) => node.expandable;

  ngOnInit(): void {
    this.formatExerciseData();
    this.formatTreedata();
    this.getExerciseCategory();
  }

  getDaysData(data:any) {
    return this.weeks.map((o:any) => {
      o.checked = data[o.key];
      return o;
    })
  }

  getExerciseCategory() {
    this.httpService.getCall(`${config.apiUrl}/exercise/getexercisetemplatecategories`).subscribe(result => {
      this.catlist = result;
    }, err => {
      this.logService.logError(err);
    });
  }

  formatExerciseData() {
    this.httpService.postCall(`${config.apiUrl}/exercise/getsessionexerciseplans`, {session_id: this.sessionId, client_id: this.userId}).subscribe(result => {
      let exercisePlan = result[0].exerciseplans;
      this.templateData = this.execiseDataOrder(exercisePlan);
    }, err => {
      this.logService.logError(err);
    });
  }

  formatTreedata() {
    this.httpService.getCall(`${config.apiUrl}/exercise/getexercisecategorytabtree`).subscribe(result => {
      const data = result.exerciseplancategoryindex;
      const parsed = JSON.parse(data);
      this.dataSource.data = parsed;
      this.obtainedDataSource = parsed;
    }, err => {
      this.logService.logError(err);
    });
  }
  allWeeksSelected(data:any) {
    let allWeeksSelected =true;
    this.weeks.forEach((o:any) => {
      if(data[o.key] !== 1) {
        allWeeksSelected = false;
      }
    })
    return allWeeksSelected;
  }

  execiseDataOrder(data:any) {
    return data.map((o: any) => {
      return {
        id: o.id,
        planName: o.name,
        oSet: '',
        oReps: '',
        oRest: '',
        oSpeed: '',
        days: AppConstants.weeksKeys.map((day,i)=> { return {key: day, value: o[this.weeks[i].key] == 1}}),
        isAllWeeksSelected: this.allWeeksSelected(o),
        exercises: o.exerciseplanexercises ? o.exerciseplanexercises.map((ob:any) => {ob["searchOpt"] = [{name: ob.name}]; return ob;}) : []
      }
    });
  }

  deletePlan(id:any) {
    let dialogRef = this.dialog.open(NavDialog, { data: "" });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
    this.ui.spin$.next(true);
    this.httpService.deleteCall(`${config.apiUrl}/exercise/deleteexerciseplan/${this.sessionId}/${id}`, {}).subscribe(result => {
      this.templateData = this.templateData.filter((o: any) => o.id !== id);
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'Exercise Plan deleted successfully');
    }, err => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error Occured while deleting plan');
    });
  }
});
  }

  deleteExercise(id:any, planId:any) {
    this.ui.spin$.next(true);
    if(JSON.stringify(id).indexOf('tmp') !== -1) {
      this.templateData = this.templateData.map((o: any) => {
        if(o.id === planId) {
          o.exercises = o.exercises.filter((exe:any) => exe.id !== id);
        }
        return o;
      });
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'Exercise deleted successfully');
    } else {
      this.httpService.deleteCall(`${config.apiUrl}/exercise/deleteexerciseplanexercise/${planId}/${id}`, {}).subscribe(result => {
        this.templateData = this.templateData.map((o: any) => {
          if(o.id === planId) {
            o.exercises = o.exercises.filter((exe:any) => exe.id !== id);
          }
          return o;
        });
        this.ui.spin$.next(false);
        this.toasterService.show('success', 'Success', 'Exercise deleted successfully');
      }, err => {
        this.logService.logError(err);
        this.ui.spin$.next(false);
        this.toasterService.show('error', 'Error', 'Error Occured while deleting exercise');
      });
    }
  }
  onSelectAllDays(plan:any,index:any) {
    const isAllWeeksSelected = !this.templateData[index].isAllWeeksSelected;
    const checkedDays:any = [];

    this.templateData[index].days.forEach((item:any) => {
      item.value = isAllWeeksSelected;
      if (item.value) {
        checkedDays.push(item.key);
      }
    });
    this.templateData[index].isAllWeeksSelected = isAllWeeksSelected;
    this.templateData[index].weeks = checkedDays.join(',').toLowerCase();
    this.updatePlan(plan);
}

  updatePlan(plan:any) {
    this.ui.spin$.next(true);
    const payload:any = {
      session_id: this.sessionId,
      name: plan.planName
    }
    plan.days.map((o:any,i:any) => { payload[this.weeks[i].key] = o.value?1:0; return o; });
    this.httpService.putCall(`${config.apiUrl}/exercise/updateexerciseplan/${plan.id}`, payload).subscribe(result => {
      this.templateData = this.templateData.filter((o: any) => o.id !== "id");
      this.ui.spin$.next(false);
    }, err => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });
  }

  updateExercise(exe:any, planid:any) {
    if((exe.customname || exe.name) && exe.cat_id) {
      let payload = {
        exerciseplan_id: planid,
        name: exe.name || exe.customname,
        sets: exe.sets,
        reps: exe.reps,
        rest: exe.rest,
        speed: exe.speed,
        mode: exe.mode,
        intensity: exe.intensity,
        heartrate: exe.heartrate,
        duration: exe.duration,
        cat_id: exe.cat_id,
        session_id: this.sessionId
       }
      this.httpService.putCall(`${config.apiUrl}/exercise/updateexerciseplanexercise/${exe.id}`, payload).subscribe(result => {
        this.ui.spin$.next(false);
      }, err => {
        this.logService.logError(err);
        this.ui.spin$.next(false);
      });
    }
  }

  drop(ev:any, index:number = 0) {
    console.log(index);
    let data = ev?.item?.data?.exeData;
    let payload:any;
    if (data) {
      this.ui.spin$.next(true);
      if (data.exerciseplantemplate) {
        payload = {
          id: data.id,
          session_id: this.sessionId,
          client_id: this.userId,
          catid: data.catid
        }
        this.httpService.postCall(`${config.apiUrl}/exercise/addtemplateexercisestosession`, payload).subscribe(result => {
          let exercisePlan = result[0].exerciseplans;
          this.templateData = this.execiseDataOrder(exercisePlan);
          this.ui.spin$.next(false);
          this.toasterService.show('success', 'Success', 'New Exercises successfully added');
        }, err => {
          this.logService.logError(err);
          this.ui.spin$.next(false);
          this.toasterService.show('error', 'Error', 'Error Occured while adding');
        });
      } else {
        if (this.templateData.length === 0) {
          this.ui.spin$.next(false);
          this.toasterService.show('error', 'Info', 'You can not add exercise template items with out exercise plan');
          return false;
        }
        payload = {
          session_id: this.sessionId,
          client_id: this.userId,
          exerciseplan_id: ev.container.id,
          catid: data.catid,
          name: data.name,
          isaddedmanual: true,
          display_order: ev.currentIndex
        }
        this.httpService.postCall(`${config.apiUrl}/exercise/addexercisetoexerciseplan`, payload).subscribe(result => {
          this.templateData = this.templateData.map((o:any)=>{
            if(o.id === result.exerciseplan_id) {
              result.searchOpt = [{name: result.name}];
              o.exercises.push(result);
            }
            return o;
          });
          this.ui.spin$.next(false);
          this.toasterService.show('success', 'Success', 'New Exercise successfully added');
        }, err => {
          this.logService.logError(err);
          this.ui.spin$.next(false);
          this.toasterService.show('error', 'Error', 'Error Occured while adding');
        });
      }
    } else {
      let planid = ev?.item?.data?.exerciseplan_id;
      if(ev.previousContainer.id === ev.container.id) {
        if (planid) {
          let payload = {
            exercises: [{display_order: ev.currentIndex + 1, id: ev.item.data.id}]
          }
          this.templateData.map((o:any)=>{
            if(o.id === planid) {
              moveItemInArray(o.exercises, ev.previousIndex, ev.currentIndex);
            }
            return o;
          });
          this.httpService.putCall(`${config.apiUrl}/exercise/reorderexercises/${planid}`, payload).subscribe(result => {
            
          }, err => {
            this.logService.logError(err);
            this.ui.spin$.next(false);
          });
        }
      } else {
        this.ui.spin$.next(false);
        const reqObj = {
          deletefrom: {
            id: ev.item.data.id,
            exerciseplan_id: ev.previousContainer.id
          },
          addinto: {
            exerciseplan_id: ev.container.id,
            display_order: ev.item.data.currentIndex || 0 + 1
          }
         }
         transferArrayItem(ev.previousContainer.data,
          ev.container.data,
          ev.previousIndex,
          ev.currentIndex);
         this.httpService.putCall(`${config.apiUrl}/exercise/reorderexercisesbwplans`, reqObj).subscribe((result) => {
          this.templateData[index].exercises = result.exercises.map((item:any) => ({
            ...item,
            searchOpt: [{name: item.name}]
          }));
          this.ui.spin$.next(false);
         }, err => {
          this.ui.spin$.next(false);
         });
      }
      //  else {
      //   this.toasterService.show('error', 'Error', `Action can't be performed`);
      // }
    }
    return true;
  }

  addCategory(ex:any, plan:any) {
    if(ex.cat_id && ex.name && ex.name !== '' && ex.searchOpt && !ex.searchOpt.length) {
      this.subNameSelect(ex, ex.id, ex.cat_id, plan);
    }
  }

  searchExercise(ev:any) {
    let filteredTreeData = [];
    if(ev.target.value){
      filteredTreeData = this.commonService.objectFilter(this.obtainedDataSource, ev.target.value);
      this.dataSource.data = filteredTreeData;
    } else {
      this.dataSource.data = this.obtainedDataSource;
      this.treeControl.collapseAll();
    }
  }

 

  addExercise(ev:any, plan:any) {
    let newplan = {
      cat_id: '',
      cat_name: "",
      clientnote: null,
      reps: null,
      rest: null,
      sets: null,
      exercisetemplate: {
        is_added_manual: 1,
        name: ''
      },
      name: '',
      id: 'tmp-' + (new Date()).getTime()
    }
    this.templateData.map((o:any) => {
      if(o.id === plan.id) {
        o.exercises.push(JSON.parse(JSON.stringify(newplan)));
        return o;
      }
      return o;
    });
  }

  copyExercise(exercise:any, planid:any) {
    if(JSON.stringify(exercise.id).indexOf('tmp') !== -1) {
      this.toasterService.show('error', 'Error', 'Body part and Name is mandatory to copy exercise');
    } else {
      this.ui.spin$.next(true);
      this.httpService.postCall(`${config.apiUrl}/exercise/copyandcreateexerciseplanexercise`, {"exerciseplan_id": planid, "exerciseplanexercise_id": exercise.id}).subscribe(result => {
        this.templateData = this.templateData.map((o:any) => {
          if(o.id === planid) {
              let pos:any = 0;
              o.exercises.map((ob:any, index:any) => {
                if(ob.id === exercise.id) pos = index
              });
              result.searchOpt = [{name: result.name}];
              o.exercises = [...o.exercises.slice(0,pos), result, ...o.exercises.slice(pos)];
            return o;
          }
          return o;
        });
        this.ui.spin$.next(false);
      }, err => {
        this.logService.logError(err);
        this.ui.spin$.next(false);
      });
    }
  }

  newExercisePlan() {
    this.ui.spin$.next(true);
    this.httpService.postCall(`${config.apiUrl}/exercise/createexerciseplan`, {"session_id": this.sessionId, "name": "New Plan"}).subscribe(result => {
      const newData = {
          id: result.id,
          planName: result.name,
          oSet: '',
          oReps: '',
          oRest: '',
          days: AppConstants.weeksKeys.map((day)=> { return {value: result[day], key: day}}),
          exercises: []
        }
      this.templateData.push(newData);
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'New plan successfully added');
    }, err => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error Occured while creating');
    });
  }

  onChangeProgram(ev:any, item:any, exId:any) {
    this.templateData = this.templateData.map((obj:any) => {
      if(obj.id === item) {
        obj.exercises.map((o:any) => {
          if(o.id === exId) {
            o.name = null;
            o.sets = null;
            o.reps = null;
            o.rest = null;
          }
        })
        return obj;
      }
      return obj;
    });
    this.searchResults(ev.value, item, exId); 
  }

  searchResults(catid:any, plan:any, ex:any, txt = "") {
    this.httpService.postCall(`${config.apiUrl}/exercise/searchexercisetemplates`, {"category_id": catid, "searchtext": txt}).subscribe(result => {
      this.templateData = this.templateData.map((obj:any) => {
        if(obj.id === plan.id) {
          obj.exercises.map((o:any) => {
            if(o.id === ex) {
              o["searchOpt"] = result;
            }
            return o;
          });
          return obj;
        }
        return obj;
      });
    }, err => {
      this.logService.logError(err);
      this.templateData = this.templateData.map((obj:any) => {
        if(obj.id === plan.id) {
          obj.exercises.map((o:any) => {
            if(o.id === ex) {
              o["searchOpt"] = [];
            }
            return o;
          });
          return obj;
        }
        return obj;
      });
    });
  }

  subNameCustom(event:any, exId:any, catid:any, plan:any, i: number, j: number) {
    this.templateData[i].exercises[j].customname = event.target.value;
  }

  subNameBlur(event:any, exId:any, catid:any, plan:any, i: number, j: number) {
    const { customname } = this.templateData[i].exercises[j]; 
    if (customname) {
      this.subNameSelect({name: customname}, exId, catid, plan);
    }
  }

  subNameSelect(val:any, exId:any, catid:any, plan:any) {
    let add = exId.toString().indexOf('tmp') !== -1;
    this.templateData = this.templateData.map((obj:any) => {
      if(obj.id === plan.id) {
        obj.exercises.map((o:any) => {
          if(o.id === exId) {
            o.name = val.value;
          }
          return o;
        });
        return obj;
      }
      return obj;
    });
    if(add) {
      let payload = {
        "session_id": this.sessionId,
        "client_id": this.userId,
        "exerciseplan_id": plan.id,
        "catid": catid,
        "name": val.name,
        "isaddedmanual": true 
      }
      this.httpService.postCall(`${config.apiUrl}/exercise/addexercisetoexerciseplan`, payload).subscribe(result => {
        this.templateData = this.templateData.map((obj:any) => {
          if(obj.id === plan.id) {
            obj.exercises.map((o:any) => {
              if(o.id === exId) {
                o.id = result.id;
              }
              return o;
            });
            return obj;
          }
          return obj;
        });
      }, err => {
        this.logService.logError(err);
      });
    } else {
      let exercise_plan = this.templateData.filter((obj:any) => obj.id === plan.id)[0];
      exercise_plan = exercise_plan?.exercises.filter((obj:any) => obj.id === exId)[0];
      this.updateExercise(exercise_plan, plan.id);
    }
  }

  debounceSearch = () => {
    let timer:any;
    let context:any = this;
    return function(ev:any, item:any, exId:any, catid:any) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        context.searchResults(catid, item, exId, ev.target.value);
      }, 300);
    }
  }

  categoryNameSelection = this.debounceSearch();

  updateOverallSRRPlans() {
    this.ui.spin$.next(true);

    const payload = {
      session_id: this.sessionId,
      client_id: this.userId,
      sets: this.main.oSet,
      reps: this.main.oReps,
      rest: this.main.oRest,
      speed: this.main.oSpeed
    };
    this.httpService.postCall(`${config.apiUrl}/exercise/copyvaluestoexerciseplan`, payload).subscribe(result => {
      let exercisePlan = result[0].exerciseplans;
      this.templateData = this.execiseDataOrder(exercisePlan);
      this.main = {
        oReps: '',
        oSet: '',
        oRest: '',
        oSpeed: ''
      };
      this.ui.spin$.next(false);
    }, err => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });
  }

  updateOverallSRR(plan:any) {
    this.ui.spin$.next(true);
    const payload = {
      session_id: this.sessionId,
      client_id: this.userId,
      exerciseplan_id: plan.id,
      sets: plan.oSet,
      reps:plan.oReps, 
      rest: plan.oRest,
      speed: plan.oSpeed
    }
    this.httpService.postCall(`${config.apiUrl}/exercise/copyvaluestoexerciseplan`, payload).subscribe(result => {
      let exercisePlan = result[0].exerciseplans;
      this.templateData = this.execiseDataOrder(exercisePlan);
      this.ui.spin$.next(false);
    }, err => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });
  }

  onVideoView(videoUrl:string, name:string) {
    let dialogRef = this.dialog.open(VideoDialog, { data: {videoUrl: videoUrl, name: name} });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
      }
    });
  }

  onExerciseInfo(description: string, name: string, exercisetemplateimages: any) {
    let dialogRef = this.dialog.open(ExerciseInfo, { data: {description: description, name: name, images: exercisetemplateimages}, maxWidth: 500 });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
      }
    });
  }
  copyExePlan(plan?:any) {
    this.ui.spin$.next(true);
    this.httpService.postCall(`${config.apiUrl}/exercise/copyandcreateexerciseplan`, {"exerciseplan_id": plan?plan.id:"", "session_id": this.sessionId, "to_session_id": this.excersieCopyObject.selectedSession}).subscribe(result => {
     if(this.excersieCopyObject.client === "sameClinet"){
      let exercisePlan = result[0].exerciseplans;
      this.templateData = this.execiseDataOrder(exercisePlan);  
     }
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'Excercise Copied successfully');
    }, err => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error Occurred while Coping Excercise');
    });
  }
  openSessionPopup(session: Boolean,plan?:any) {
    let copySessiondialogRef = this.dialog.open(CopyExcerciseDialogComponent, { data: { 'title': "Select Session for client", "copyUser": true, isSeessionCopy: session, excersieCopyObject: this.excersieCopyObject }, width: '600px' });
    copySessiondialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.copyExePlan(plan);
      }
    })
  }
  getPrograms(reqObj: any, session: Boolean,plan?:any) {
    this.ui.spin$.next(true);
    const url = `${config.apiUrl}/getclientprograms`;
    this.httpService.postCall(url, reqObj).subscribe(data => {
      this.ui.spin$.next(false);
      this.excersieCopyObject.clientDetais = data.clientDetais;
      this.excersieCopyObject.sessions = data.programs;
      this.openSessionPopup(session,plan);
    }, err => {
      this.ui.spin$.next(false);
      this.dialog.closeAll();
      this.toasterService.show('error', 'Error', 'Error Occurred while fetching progrmas');
    });
  }

  fetchloggedinconsultantclients(session: Boolean,plan?:any) {
    this.ui.spin$.next(true);
    const url = `${config.apiUrl}/fetchloggedinconsultantclients`;
    this.httpService.postCall(url, { "client_id": this.userId }).subscribe(data => {
      this.excersieCopyObject.users = data;
      this.ui.spin$.next(false);
      let CopyExcerciseDialogRef = this.dialog.open(CopyExcerciseDialogComponent, { data: { title: "Select Clients", isSeessionCopy: session, excersieCopyObject: this.excersieCopyObject }, width: '600px' });
      CopyExcerciseDialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.getPrograms({ "client_id": this.excersieCopyObject.selectedUser }, session,plan);
        }
      })
    }, err => {
      this.dialog.closeAll();
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error Occurred while fetching clients');
    });
  }

  copyExcercise(session: Boolean = false) {
    this.excersieCopyObject = {
      users: [],
      selectedUser: "",
      client: "sameClinet",
      sessions: [],
      selectedSession: '',
      clientDetais: {},
    }
    this.fetchloggedinconsultantclients(session);
  }
  copyExerciseSessionPlan(plan: any) {
    this.excersieCopyObject = {
      users: [],
      selectedUser: "",
      client: "sameClinet",
      sessions: [],
      selectedSession: '',
      clientDetais: {},
    }
    let copySessionExcersiePlanRef = this.dialog.open(CopyExcerciseDialogComponent, { data: { title: "Do you want to copy for", isInitial: true, isSeessionCopy: true, excersieCopyObject: this.excersieCopyObject }, width: '600px' });
    copySessionExcersiePlanRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (this.excersieCopyObject.client === 'sameClinet') {
          this.getPrograms({ "client_id": this.userId }, true,plan);
        }
        else {
          this.fetchloggedinconsultantclients(true,plan);
        }
      }
    });
  }

  async downloadAsPDF() {
    if (this.templateData.length === 0) {
      return false;
    }
    const url = `${config.apiUrl}/getexercisespdf/${this.userId}/${this.sessionId}`;
    this.ui.spin$.next(true);
    this.httpService.getCallFile(url).subscribe(result => {
      var file = new Blob([result], {type: 'application/pdf'});
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'PDF file created successfully');
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error while created successfully');
      console.log(err);
    });
    return true;
  }

  onSortMealPlanLower(index:number, isLower:boolean) {
    const record = this.templateData.splice(index, 1);
    const [ specific ] = record;
    const sortedIndex = isLower ? index + 1 : index - 1;
    this.templateData.splice(sortedIndex, 0, specific);
    const actualRecord = this.templateData[index];
    const sortedRecord = this.templateData[sortedIndex];
    this.saveUpdatedOrder(sortedRecord, sortedIndex, actualRecord, index);
  }

  saveUpdatedOrder(sortingRecord:any, sortingIndex:number, actualRecord:any, actualIndex:number) {
    const url = `${config.apiUrl}/exerciseplans/reorder/${this.sessionId}`;
    const reqObj = {
      exerciseplans: [{
        id: sortingRecord.id,
        display_order: sortingIndex + 1
       },
       {
        id: actualRecord.id,
        display_order: actualIndex + 1
       }
      ]
    };

    this.httpService.putCall(url, reqObj).subscribe(result => {

    }, err => {
      this.toasterService.show('error', 'Error', err?.errors);
      this.logService.logError(err);      
    });
  }

  onInputFocus(event:any) {
    event.target.select();
  }

  onSideScroll(ev:any) {
    if (ev.currentTarget.scrollY > 310) {
      this.fixedDiv = true;
    } else {
      this.fixedDiv = false;
    }
  }

}

@Component({
  selector: 'dialog-overview-example-dialog',
  template: `
  <h1 mat-dialog-title>Confirmation</h1>
  <div mat-dialog-content class="dialogContent">
      <p>Are you sure, You want to delete plan?</p>
    </div>
    <div mat-dialog-actions class="dialogActions">
      <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>Submit</button>
      <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
    </div>`,
  styleUrls: ['../session.component.sass']
})
export class NavDialog {
  constructor(
    public dialogRef: MatDialogRef<NavDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  responseHandler(action:any): void {
    this.dialogRef.close(action);
  }
}

@Component({
  selector:'copy-excercise-dialog',
  templateUrl:'./copy-excercise-dialog.html',
  styleUrls: ['../session.component.sass']
})
export class CopyExcerciseDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CopyExcerciseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  responseHandler(action:any): void {
    this.dialogRef.close(action);
  }
}

@Component({
  selector: 'video-view-dialog',
  template: `
    <h1 mat-dialog-title>{{data.name}}</h1>
    <div mat-dialog-content class="dialogContent">
    <div [innerHTML]="iframe_html"></div>
    </div>
    <div mat-dialog-actions class="video-dialog-action">
      <button mat-raised-button color="primary" class="resultsbtn video_success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>OK</button>
    </div>`,
  styleUrls: ['../session.component.sass']
})
export class VideoDialog {
  vimeoUrl: string = '';
  iframe_html: any;
  constructor(
    private embedService: EmbedVideoService,
    public dialogRef: MatDialogRef<VideoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.vimeoUrl = data.videoUrl;
      this.iframe_html = this.embedService.embed(this.vimeoUrl,
        {
          query: { portrait: 0, color: '333' },
          attr: { width: 450, height: 250 }
        });
    }

  responseHandler(action:any): void {
    this.dialogRef.close(action);
  }
}

@Component({
  selector: 'exercise-info-dialog',
  template: `<h1 mat-dialog-title>{{data.name}}</h1>
  <div mat-dialog-content class="dialogContent">
    <div>
      {{data.description}}
    </div>
    <div>
      <span
        *ngFor="let item of data.images"
      >
        <img [src]="exerciseTemplateImageBaseUrl + item.image" />
      </span>
    </div>
  </div>
  <div mat-dialog-actions class="video-dialog-action">
    <button mat-raised-button color="primary" class="resultsbtn video_success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>OK</button>
  </div>`,
  styleUrls: ['../session.component.sass']
})
export class ExerciseInfo {
  exerciseTemplateImageBaseUrl = '';
  constructor(
    public dialogRef: MatDialogRef<ExerciseInfo>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.exerciseTemplateImageBaseUrl = config.exerciseTemplateImageUrl;
    }

  responseHandler(action:any): void {
    this.dialogRef.close(action);
  }
}
