
<div>
    <div class="top-section">
        <div class="search-container">
          <div class="input-container">
              <mat-icon matPrefix>search</mat-icon>
              <input type="text" placeholder="Search" [(ngModel)]="searchText" class="search-input" autocomplete="off" (keyup)="searchResult($event)" />
          </div>
          <button class="deletebtn" [ngClass]="{'highlight': deletehighlight}" [disabled]="!deletehighlight" (click)="deleteMealTemplates()">Delete Selected Meal(s)</button>
        </div>
    </div>
    <div class="table-container">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z10" >
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef  > 
                    <mat-checkbox class="checkbx" (change)="setSelection($event)" [checked]="isAllSelected"></mat-checkbox>
                    <span class="disp-name">Name</span>
                </th>
                <td mat-cell *matCellDef="let element; let i = dataIndex" width="20%">
                  <div class="name-field">
                    <mat-checkbox class="checkbx" (click)="$event.stopPropagation()" (change)="setSelection($event, element)" [checked]="element.checked"></mat-checkbox>
                    <div class="expand-icon">
                        <span class="icon" (click)="specificRecord(element, i);element.isExpanded = !element.isExpanded">
                          <mat-icon *ngIf="!element.isExpanded">keyboard_arrow_right</mat-icon>
                          <mat-icon *ngIf="element.isExpanded">keyboard_arrow_down</mat-icon>
                        </span>
                        <span class="food-name">
                          <span class="disp-name" (click)="enableEdit(element, 'name', i)" *ngIf="!element.nameEdit">{{element.name}} </span>
                          <span *ngIf="element.nameEdit" class="edit-column" >
                            <input [(ngModel)]="element.nameModel" [placeholder]="element.name" class="edit-field" />
                            <button class="savebtn" (click)="saveNewValue(element, 'name', i)">Save</button>
                            <button class="cancelbtn" (click)="cancelEdit(element, 'name', i)">Cancel</button>
                          </span>
                        </span>
                      </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef  > <span class="disp-name">Category</span> </th>
                <td mat-cell *matCellDef="let element;let i = dataIndex"> 
                  <span *ngFor="let reccat of element.recipecategories">
                    {{reccat.name}}
                  </span>
                    <!-- <span class="disp-name" (click)="enableEdit(element, 'category', i)" *ngIf="!element.categoryEdit">{{element.recipecategories.name}} </span>
                    <span *ngIf="element.categoryEdit" class="edit-column" >
                      <div class="select-ddl">
                      <mat-form-field class="width-full">
                        <mat-label>Select Category</mat-label>
                      <mat-select [(ngModel)]="element.mealtemplatecategory_id">
                        <mat-option *ngFor="let data of recipesCategories"  [value]="data.id">{{data.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    </div>
                      <button class="savebtn" (click)="saveNewValue(element, 'category', i)">Save</button>
                      <button class="cancelbtn" (click)="cancelEdit(element, 'category', i)">Cancel</button>
                    </span> -->
                  </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef  > <span class="disp-name">Description</span> </th>
                <td mat-cell *matCellDef="let element; let i = dataIndex"> 
                    <span class="disp-name" (click)="enableEdit(element, 'description', i)" *ngIf="!element.descriptionEdit">{{element.description}}</span>
                    <span *ngIf="element.descriptionEdit" class="edit-column" >
                      <input [(ngModel)]="element.descriptionModel" [placeholder]="element.description" class="edit-field" />
                      <button class="savebtn" (click)="saveNewValue(element, 'description', i)">Save</button>
                      <button class="cancelbtn" (click)="cancelEdit(element, 'description', i)">Cancel</button>
                    </span>
                  </td>
            </ng-container>

            <ng-container matColumnDef="instructions">
              <th mat-header-cell *matHeaderCellDef  > <span class="disp-name">Instructions</span> </th>
              <td mat-cell *matCellDef="let element; let i = dataIndex"> 
                  <span class="disp-name" (click)="enableEdit(element, 'instructions', i)" *ngIf="!element.instructionsEdit">{{element.instructions}}</span>
                  <span *ngIf="element.instructionsEdit" class="edit-column" >
                    <input [(ngModel)]="element.instructionsModel" [placeholder]="element.instructions" class="edit-field" />
                    <button class="savebtn" (click)="saveNewValue(element, 'instructions', i)">Save</button>
                    <button class="cancelbtn" (click)="cancelEdit(element, 'instructions', i)">Cancel</button>
                  </span>
                </td>
          </ng-container>
            
            <ng-container matColumnDef="note">
                <th mat-header-cell *matHeaderCellDef  > <span class="disp-name">Note</span> </th>
                <td mat-cell *matCellDef="let element; let i = dataIndex"> 
                    <span class="disp-name" (click)="enableEdit(element, 'note', i)" *ngIf="!element.noteEdit">{{element.note}} </span>
                    <span *ngIf="element.noteEdit" class="edit-column" >
                      <input [(ngModel)]="element.noteModel" class="edit-field" />
                      <button class="savebtn" (click)="saveNewValue(element, 'note', i)">Save</button>
                      <button class="cancelbtn" (click)="cancelEdit(element, 'note', i)">Cancel</button>
                    </span>
                  </td>
            </ng-container>

            <ng-container matColumnDef="video_url">
                <th mat-header-cell *matHeaderCellDef  > <span class="disp-name">Video URL</span> </th>
                <td mat-cell *matCellDef="let element; let i = dataIndex"> 
                    <span class="disp-name" (click)="enableEdit(element, 'video_url', i)" *ngIf="!element.video_urlEdit">{{element.video_url}} </span>
                    <span *ngIf="element.video_urlEdit" class="edit-column" >
                      <input [(ngModel)]="element.video_urlModel" class="edit-field" />
                      <button class="savebtn" (click)="saveNewValue(element, 'video_url', i)">Save</button>
                      <button class="cancelbtn" (click)="cancelEdit(element, 'video_url', i)">Cancel</button>
                    </span>
                  </td>
            </ng-container>

            <ng-container matColumnDef="unit">
              <th mat-header-cell *matHeaderCellDef  > <span class="disp-name">Recipe (Unit)</span> </th>
              <td mat-cell *matCellDef="let element; let i = dataIndex"> 
                  <span class="disp-name" (click)="enableEdit(element, 'unit', i)" *ngIf="!element.unitEdit">{{element.unit}}</span>
                  <span *ngIf="element.unitEdit" class="edit-column" >
                    <input [(ngModel)]="element.unitModel" [placeholder]="element.unit" class="edit-field" />
                    <button class="savebtn" (click)="saveNewValue(element, 'unit', i)">Save</button>
                    <button class="cancelbtn" (click)="cancelEdit(element, 'unit', i)">Cancel</button>
                  </span>
                </td>
          </ng-container>

          <ng-container matColumnDef="qty">
            <th mat-header-cell *matHeaderCellDef  > <span class="disp-name">Qty</span> </th>
            <td mat-cell *matCellDef="let element; let i = dataIndex"> 
                <span class="disp-name" (click)="enableEdit(element, 'qty', i)" *ngIf="!element.qtyEdit">{{element.qty}}</span>
                <span *ngIf="element.qtyEdit" class="edit-column" >
                  <input [(ngModel)]="element.qtyModel" [placeholder]="element.qty" class="edit-field" />
                  <button class="savebtn" (click)="saveNewValue(element, 'qty', i)">Save</button>
                  <button class="cancelbtn" (click)="cancelEdit(element, 'qty', i)">Cancel</button>
                </span>
              </td>
        </ng-container>
        
            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element; let i = dataIndex" colSpan="7">
                  <div class="expanded-detail" [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'">
                    <div>
                      <div>
                        <div class="details">
                          <input #imageInput hidden="true" type="file" name="photo" onclick="this.value=null" (change)="onUploadImageSequence($event, i)" accept="image/*"/>
                          <span class="link_upload" (click)="imageInput.click()">Upload Image Sequence</span>
                          <div *ngIf="element && element.recipeimages">
                            <div *ngFor="let specimage of element.recipeimages"
                                class="exerciseImageBlock"
                            >
                                <img [src]="imageCustomUrl+specimage.image" class="exercise-image" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <button class="deletebtn" [ngClass]="{'highlight': element.deletehighlight}" [disabled]="!element.deletehighlight" (click)="deleteFoodItems(i)">Delete Selected Food(s)</button>
                      </div>
                    <table mat-table [dataSource]="element.inner" multiTemplateDataRows class="mat-elevation-z10" >
                      <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let innerElement; let innerI = dataIndex"> 
                          <mat-checkbox class="checkbx" [(ngModel)]="innerElement.checked" [checked]="innerElement.checked" (change)="onFoodSetSelection($event, i)"></mat-checkbox>

                          <div class="expand-icon">
                            <span class="icon" (click)="onMealFoodInnerSelect(innerElement, innerI, i); innerElement.isExpanded = !innerElement.isExpanded">
                              <mat-icon *ngIf="!innerElement.isExpanded">keyboard_arrow_right</mat-icon>
                              <mat-icon *ngIf="innerElement.isExpanded">keyboard_arrow_down</mat-icon>
                            </span>
                          <span>{{innerElement.name}}</span>
                          </div>
                          
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="cat_name">
                        <th mat-header-cell *matHeaderCellDef> Category </th>
                        <td mat-cell *matCellDef="let innerElement"> {{innerElement.cat_name}} </td>
                      </ng-container>

                      <ng-container matColumnDef="calories">
                        <th mat-header-cell *matHeaderCellDef> Calories </th>
                        <td mat-cell *matCellDef="let innerElement"> {{innerElement.calories}} </td>
                      </ng-container>

                      <ng-container matColumnDef="protein">
                        <th mat-header-cell *matHeaderCellDef> Protein </th>
                        <td mat-cell *matCellDef="let innerElement"> {{innerElement.protein}} </td>
                      </ng-container>

                      <ng-container matColumnDef="carbs">
                        <th mat-header-cell *matHeaderCellDef> Carbs </th>
                        <td mat-cell *matCellDef="let innerElement"> {{innerElement.carbs}} </td>
                      </ng-container>

                      <ng-container matColumnDef="fat">
                        <th mat-header-cell *matHeaderCellDef> Fat </th>
                        <td mat-cell *matCellDef="let innerElement"> {{innerElement.fat}} </td>
                      </ng-container>

                      <ng-container matColumnDef="unit">
                        <th mat-header-cell *matHeaderCellDef> Unit </th>
                        <td mat-cell *matCellDef="let innerElement"> {{innerElement.unit}} </td>
                      </ng-container>

                      <ng-container matColumnDef="qty">
                        <th mat-header-cell *matHeaderCellDef> Qty </th>
                        <td mat-cell *matCellDef="let innerElement"> {{innerElement.qty}} </td>
                      </ng-container>

                      <ng-container matColumnDef="subExpandedDetail">
                        <td mat-cell *matCellDef="let innerElement" colSpan="5">
                          <div class="expanded-detail" [@detailExpand]="innerElement.isExpanded ? 'expanded' : 'collapsed'">
                            <div>
                              <div *ngFor="let item of innerElement.foods | keyvalue">
                                <span>{{item.key}}</span>: <span>{{item.value}}</span>
                              </div>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns;sticky: true"></tr>
                      <tr mat-row *matRowDef="let innerElement; columns: innerDisplayedColumns;" class="element-row" [class.expanded-row]="innerElement.isExpanded"></tr>
                      <tr mat-row *matRowDef="let row; columns: ['subExpandedDetail']" class="detail-row"></tr>
                    </table>
                  </div>
                    <div>
                      <app-nutritionix-food [selectedIndex]="i" (action)="onFoodSelection($event)"></app-nutritionix-food>
                    </div>
                  </div>
                </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row class="cursorPointer main-content-row example-element-row" *matRowDef="let element; columns: columnsToDisplay; let i = dataIndex;" 
                [class.expanded-row]="element.isExpanded"
                ></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
        </table>
        <mat-paginator
        [length]="pagination.count"
        [pageIndex]="pagination.pageIndex"
        [pageSize]="pagination.perPage"
        [pageSizeOptions]="[10, 25, 50, 100, 200]"
        (page)="getServerData($event)"
  showFirstLastButtons></mat-paginator>
    </div>
</div>