<!-- <mat-toolbar color="primary">
  <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
    (click)="drawer.toggle()"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <span>Total Health and Fitness</span>
  <span class="example-spacer"></span>
  <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
    <mat-icon>favorite</mat-icon>
  </button>
  <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
    <mat-icon>share</mat-icon>
  </button>
</mat-toolbar> -->

<mat-drawer-container class="example-container" hasBackdrop="false">
  <mat-drawer #drawer mode="side" [opened]="isSideNavToggle$" class="side-menu-container" *ngIf="isLoggedIn$ | async as isLoggedIn">
    <div class="side-content">
      <div>
        <img src="https://www.totalhealthandfitness.com/wp-content/themes/totalhealthfitness/build/img/Logo.svg" />
      </div>
      <hr class="sidenav-hr" />
      <mat-list class="side-list">
        <mat-list-item *ngFor="let item of sideNavData$ | async" (click)="redirect(item.name, item.id, item.display_name)" class="list-item" [ngClass]="{'selected-side-nav': selectedItem === item.name}"
          [matTooltip]="item.display_name"
          matTooltipPosition="right"
        > 
          <mat-icon aria-hidden="false" [attr.aria-label]="item.icon + 'Icon'" *ngIf="item.icon && item.icon.indexOf('.svg') === -1">{{item.icon}}</mat-icon> 
          <img *ngIf="item.icon && item.icon.indexOf('.svg') !== -1" [alt]="item.icon" [src]="'/assets/icons/'+item.icon" />
          <!-- <span class="side-nav-title"> {{item.display_name}} </span> -->
        </mat-list-item>
       </mat-list>
    </div>
    <!-- <div class="side-nav-footer">
      <p>Powered by</p>
      <p>Total Health and Fitness</p>
    </div> -->
  </mat-drawer>
  
  <mat-drawer-content>
    <div class="default-container">
    <app-header></app-header>
<div class="main-container">
  <!-- <div class="main-loader">
    <mat-progress-bar mode="query" *ngIf="isDisplaySpinner$ | async as isDisplaySpinner"></mat-progress-bar>
  </div> -->
  <app-toaster-container></app-toaster-container>
<router-outlet></router-outlet>
</div>
  <!-- footer -->
  <app-footer></app-footer>
</div>
</mat-drawer-content>
</mat-drawer-container>