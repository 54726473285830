import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { config } from '../../config';
import { ToasterService } from '../../services/toaster/toaster.service';
import { UiService } from '../../services/uiloader/uiloader.service';

@Component({
  selector: 'app-nutrition-program-uploader-categories',
  templateUrl: './nutrition-program-uploader-categories.component.html',
  styleUrls: ['./nutrition-program-uploader-categories.component.sass']
})
export class NutritionProgramUploaderCategoriesComponent implements OnInit {
  @Input() nutritionCategories:any;
  selected_category:any = -1;
  default_value = -1;

  displayedColumns = ['name', 'parentcategory','nutritionprograms', 'delete'];
  datasource:any = [];
  
  constructor(private httpService: HttpService,
    private toasterService: ToasterService,
    private ui: UiService) { }

  ngOnInit(): void {
    this.getNutritionProgramsPerCategory();
  }

  onChangeExerciseCategory(index:number) {
    const record = this.datasource[index];
    const url = `${config.apiUrl}/nutritionprograms/updatecategory/${record.id}`;
    const reqObj = {
      parent_id: record.parent_id
    };

    this.httpService.putCall(url, reqObj).subscribe(record => {

    }, err => {

    });

  }

  onFilterNutritionCategory() {
    this.getNutritionProgramsPerCategory(); 
  }

  getNutritionProgramsPerCategory() {
    this.ui.spin$.next(true);
    const categoryId = this.selected_category === -1 ? '' : this.selected_category;
    const url = `${config.apiUrl}/nutritionprograms/getnutritiontemplatespercategory/${categoryId}`;
    this.httpService.getCall(url).subscribe(result => {
      this.datasource = result;
      this.ui.spin$.next(false);
    }, err => {
      this.ui.spin$.next(false);
    });
  }

  deleteHandle(id:number, index:number) {
    this.ui.spin$.next(true);
    const url = `${config.apiUrl}/nutritionprograms/deletenutritionprogramcategory/${id}`;
    this.httpService.deleteCall(url, {}).subscribe(result => {
      const arr = this.datasource;
      arr.splice(index, 1);
      this.datasource = arr;
      this.datasource = this.datasource.filter((record:any)=>{
        return id != record.id;
      });
      this.ui.spin$.next(false);
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', err?.error?.errors[0]?.msg);
    });
  }

}
