<div class="client-resources-container">
    <div class="title">
        Resources
    </div>
    <div class="content-section">
        <div>
          <mat-card class="main-card">
            <mat-icon>supervised_user_circle</mat-icon>
            <span class="card-title">Food Guides</span>
          </mat-card>
          <mat-card class="main-card">
            <mat-icon>supervised_user_circle</mat-icon>
            <span class="card-title">Program Binder</span>
          </mat-card>
          <mat-card class="main-card">
            <mat-icon>supervised_user_circle</mat-icon>
            <span class="card-title">Calculator</span>
          </mat-card>
        </div>
      </div>
</div>