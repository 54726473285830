import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../services/http/http.service';
import { config } from '../config';
import { LogService } from '../services/log/log.service';

@Component({
  selector: 'app-inbody',
  templateUrl: './inbody.component.html',
  styleUrls: ['./inbody.component.sass']
})
export class InbodyComponent implements OnInit {
  @Input() specificProspectId:string = '';
  inbodyDataComp:any = {};

  constructor(private httpService: HttpService,
    private logService: LogService) { }

  ngOnInit(): void {
    
  }

  ngOnChanges() {
    this.getClientMeasurements();
  }

  getClientMeasurements() {
    if(this.specificProspectId) {
      this.httpService.postCall(`${config.apiUrl}/inbody/getclientmeasurements`, {"usertoken": this.specificProspectId}).subscribe(data => {
        this.inbodyDataComp = data;
      },
      err => {
        this.logService.logError(err);
      });
    }
  }

}
