import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SocialAuthService, GoogleLoginProvider, SocialUser, FacebookLoginProvider } from 'angularx-social-login';
import { AuthService } from '../auth/auth.service';
import { AppleLoginProvider } from '../providers/apple-login.provider'
import { config } from '../config';
import { HttpService } from '../services/http/http.service';
import { CommonService } from '../services/common/common.service';
import { ToasterService } from '../services/toaster/toaster.service';
import { LogService } from '../services/log/log.service';
import { UiService } from '../services/uiloader/uiloader.service';

@Component({
  selector: 'app-loginflow',
  templateUrl: './loginflow.component.html',
  styleUrls: ['./loginflow.component.sass']
})
export class LoginflowComponent implements OnInit {
  socialUser: SocialUser = {response: '', provider: '', email: '', id: '', name: '', photoUrl: '', firstName: '', lastName: '', authToken: '', idToken: '', authorizationCode: ''};
  loginflow: boolean = true;
  loginError: string = '';
  constructor(private router: Router,
    private socialAuthService: SocialAuthService,
    private authService: AuthService,
    private appleLoginProvider: AppleLoginProvider,
    private fb: FormBuilder,
    private httpService: HttpService,
    private commonService: CommonService,
    private toasterService: ToasterService,
    private logService: LogService,
    private route: ActivatedRoute,
    private ui: UiService) { }

    form: any = {};
    private formSubmitAttempt: boolean = false;
    forgetPWDform:any;
    private forgetfmSubmitAttempt: boolean = false;
  
    invalidLogins = false;

  ngOnInit(): void {
    this.commonService.gaEvent('login_flow_initial', 'login_flow', 'Initial Login Flow Screen');
    this.form = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.forgetPWDform = this.fb.group({
      email: ['', Validators.required]
    });

    this.route.queryParams.subscribe((params:any) => {
      if (params.invalid) {
        this.loginError = 'User not exists with provided email';
      }
    });

    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
      console.log(user);
      if (user) {
      const obj = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email
      };
      this.authService.setUserDetails(obj);
      if (user.provider === config.PROVIDER_GOOGLE) {
        this.onGoogleValidate(user);
      } else if (user.provider === config.PROVIDER_FACEBOOK) {
        this.onFacebookValidate(user);
      }
    } else {
      this.loginError = 'Error occurred, Please try again';
    }
      // console.log(JSON.stringify(user));
      // //this.authService.onLoginSuccess();
      // console.log('User logged in');
    });
  }

  onGoogleValidate(user:any) {
    this.ui.spin$.next(true);
    const url = `${config.apiUrl}/auth/gmail`;
    const reqData = {
      token: user.idToken
    };
    this.httpService.postCall(url, reqData).subscribe(result => {
      this.authService.storeJwtToken(result);
      this.onUserSuccess();
      this.ui.spin$.next(false);
    }, err => {
      this.ui.spin$.next(false);
      this.loginError = err.error.errors[0];
      //this.toasterService.show('error', 'error', err?.errors ? err.errors : 'Error while validating google');
      this.logService.logError(err, 'Error while validating google', 500);
    });
  }

  onFacebookValidate(user:any) {
    this.ui.spin$.next(true);

    const url = `${config.apiUrl}/auth/facebook`;
    const reqData = {
      token: user.authToken
    };
    this.httpService.postCall(url, reqData).subscribe(result => {
      this.authService.storeJwtToken(result);
      this.onUserSuccess();
      this.ui.spin$.next(false);
    }, err => {
      this.ui.spin$.next(false);
      this.loginError = err.error.errors[0];
      //this.toasterService.show('error', 'error', err?.errors ? err.errors : 'Error while validating facebook');
      this.logService.logError(err, 'Error while validating facebook', 500);
    });
  }

  isFieldInvalid(field: string) {
    if(!this.loginflow) {
      return (
        (!this.forgetPWDform.get(field).valid && this.forgetPWDform.get(field).touched) ||
        (this.forgetPWDform.get(field).untouched && this.forgetfmSubmitAttempt)
      );
    }
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  onSubmit() {
    this.commonService.gaEvent('general_login', 'general_submit', 'General Submit');

    if (this.form.valid) {
      this.authService.login(this.form.value);
    }
    //this.invalidLogins = true;
    this.formSubmitAttempt = true;
  }

  onCreateAccount() {
    const obj = {
      email: 'abcde@gmail.com'
    };
    this.authService.setUserDetails(obj);
    this.onUserSuccess();
  }

  onUserSuccess() {
    this.authService.onLoginSuccess();
  }

  loginWithGoogle(): void {
    this.loginError = '';
    this.commonService.gaEvent('google_login', 'google_login', 'Login with Google');
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  loginWithFacebook():void {
    this.loginError = '';
    this.commonService.gaEvent('facebook_login', 'facebook_login', 'Login with Facebook');
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  loginWithApple(): void {
    this.commonService.gaEvent('apple_login', 'apple_login', 'Login with Apple');
    this.appleLoginProvider.signIn();
  }

  signOut(): void {
    this.commonService.gaEvent('signout', 'signout', 'Sign out from application');
    this.socialAuthService.signOut();
  }

  forgetPWD() {
    this.loginflow = false;
  }

  onForgetSubmit() {
    let obj = this.forgetPWDform.value;
    this.httpService.postCall(`${config.apiUrl}/forgotpassword`, {"email": obj.email}).subscribe(result => {
      console.log(result);
    }, err => {

    });
  }

  goBack() {
    this.loginflow = true;
  }
}
