<div class="main-content">
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z">
        <ng-container matColumnDef="program">
          <th mat-header-cell *matHeaderCellDef>Programs</th>
          <td mat-cell *matCellDef="let element"> {{element.programName}}{{element.programName? ' : '+ element.startDate + ' to '+ element.endDate:''}}</td> 
        </ng-container>
  
        <ng-container matColumnDef="session">
          <th mat-header-cell *matHeaderCellDef> Sessions </th>
          <td mat-cell *matCellDef="let element"> {{element.sessionName? element.session.name + ':' + element.session_start_date: '' }}</td>
        </ng-container>
  
        <ng-container matColumnDef="measurements">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" >
            <a [ngClass]="{'label':element.measurements.length > 0}" (click)="element.measurements.length > 0 && navigateToCompostion(element.session.id)" >Measurements ({{element.measurements.length}})</a>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="nutrition">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <a class="label" (click)="navigateToNutrition(element.programId)">Nutrition</a>
          </td>
        </ng-container>

        <ng-container matColumnDef="exercise">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <a class="label"  (click)="navigateToExcercise(element.session.id)">Exercise</a>
            </td>
          </ng-container>
  
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <span *ngIf="!dataSource.length" class="emtmsg">No records found</span>
      <!-- <mat-paginator *ngIf="pagination.totalCount>10" [length]="pagination.count" [pageIndex]="pagination.pageIndex"
        [pageSize]="pagination.perPage" [pageSizeOptions]="[10, 25, 50, 100, 200]"  (page)="getServerData($event)" showFirstLastButtons></mat-paginator> -->
    </div>
  </div>