import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { SidenavService } from '../../services/sidenav/sidenav.service';
import { HeaderService } from '../../services/header/header.service';
import { HttpService } from '../../services/http/http.service';
import { config } from '../../config';
import { LogService } from '../../services/log/log.service';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {

  isLoggedIn$: any = false;
  loggedUserAvatar$: any = '';
  isDisplayHeader$:any = false;
  isSideNavToggle:boolean = true;
  notificationsList:any = [];
  userAvatar:string = '';
  notificationsNewCount:number = 0;

  headerData$:any = {};
  // private sidenav: MatSidenav
  constructor(private authService: AuthService, private sidenavService: SidenavService,
    private headerService: HeaderService, private httpService: HttpService, private logService: LogService,
    private router: Router, private commonService: CommonService) { }

  ngOnInit() {
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.loggedUserAvatar$ = this.authService.isLoggedUserAvatar;
    this.setHeaderAvatar();
    this.loggedUserAvatar$.subscribe(()=>{
      this.setHeaderAvatar();
    });

    this.headerService.headerData.subscribe(x =>{
      this.headerData$ = x;
      this.authService.displayHeaderValue();
      this.isDisplayHeader$ = this.authService.isDisplayHeader;
      if (this.isDisplayHeader$) {
        this.userNotification();
      }
    });
  }

  setHeaderAvatar() {
    const avatar = this.authService.getUserAvatar() || '';
      if (avatar.length > 2) {
        this.userAvatar = `${config.imageUrl}${this.authService.getUserAvatar()}`;
      } else {
        this.userAvatar = avatar;
      }
  }

  onImgError() {
    this.userAvatar = `${config.webImageUrl}${this.authService.getUserAvatar()}`;
  }

  onLogout() {
    this.authService.logout();
  }

  onProfile() {
    const actualLoggedUserId = this.authService.getActualLoggedUserId();
    this.router.navigate([`/userprofile`]);
  }

  toggleSideNav(): void {
    this.isSideNavToggle = !this.isSideNavToggle;
    this.sidenavService.handleSideNav(this.isSideNavToggle);
    // this.sidenav.toggle();
   }

   onRoute(path:string) {
    this.router.navigate([`/${path}`]);
   }

   userNotification() {
    const actualLoggedUserId = this.authService.getActualLoggedUserId();
    this.httpService.postCall(`${config.apiUrl}/notifications/read`, {user_id: actualLoggedUserId}).subscribe(data => {
      let notificationsCount = 0;
      const list:any = [];
      data.forEach((item: any) => {
        if (item.notification_status === 1) {
          notificationsCount += 1; 
        }
        item.createdDate = this.commonService.getDateTimeFormat(item.created_at);
        list.push(item);
      });
      this.notificationsNewCount = notificationsCount;
      this.notificationsList = list;
    },
    err => {
      this.logService.logError(err);
    });
  }

  onNotificationClick(item:any) {
    const url = `${config.apiUrl}/notification/update/${item.id}`;
    this.httpService.putCall(url, {}).subscribe(data=>{

    }, err => {
      
    });
  }
}
