export class AppConstants {
    public static Header = {
        header: 'Company Administration',
        subHeader: 'Manage users, roles and screens'
    };

    public static Constants = {
      CLIENT_ROLE: 3,
      SUPER_USER_ROLE: 1
    };

    public static mealConstants = [
      {
        key: 'Breakfast',
        value: 'Breakfast'
      },
      {
        key: 'Lunch',
        value: 'Lunch'
      },
      {
        key: 'Snack',
        value: 'Snack'
      },
      {
        key: 'Dinner',
        value: 'Dinner'
      }
    ];

    public static weekConstants = [
      
      {
        key: 'sunday',
        value: 'Su'
      },
      {
        key: 'monday',
        value: 'Mo'
      },
      {
        key: 'tuesday',
        value: 'Tu'
      },
      {
        key: 'wednesday',
        value: 'We'
      },
      {
        key: 'thursday',
        value: 'Th'
      },
      {
        key: 'friday',
        value: 'Fr'
      },
      {
        key: 'saturday',
        value: 'Sa'
      }
    ];

    public static genderOptions = [
        {
          value: 'm',
          text: 'Male'
        },
        {
          value: 'f',
          text: 'Female'
        }
      ];
    public static weeksKeys = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];
}
 