<div class="main-content roles-editor-container">
    <div class="sub-header">
        <!-- <span>
            <button mat-icon-button aria-label="Go Back" (click)="onGoBack()">
                <mat-icon class="back-icon" aria-hidden="false" aria-label="Go Back">chevron_left</mat-icon>
            </button>
        </span> -->
        <span class="user-name">
            Roles Editor
        </span>
    </div>
    <div class="sub-content">
        <div class="role-selector">
          <mat-form-field>
            <mat-label>Select Role</mat-label>
            <mat-select [(ngModel)]="role" name="dropdown" (selectionChange)="onChangeRole()">
              <mat-option *ngFor="let data of rolesData" [value]="data.id">
                {{data.display_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
            <span class="title">Available Screens</span>
        </div>
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" >
    
                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
              
                <!-- Position Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Name</th>
                  <td mat-cell *matCellDef="let element"> 
                    <span>{{element.name}}</span>
                  </td>
                </ng-container>
              
                <!-- Name Column -->
                <ng-container matColumnDef="displayName">
                  <th mat-header-cell *matHeaderCellDef> Display Name </th>
                  <td mat-cell *matCellDef="let element"> {{element.displayName}} </td>
                </ng-container>
              
                <!-- Weight Column -->
                <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef> Description </th>
                  <td mat-cell *matCellDef="let element">
                      <span>{{element.description}}</span>
                  </td>
                </ng-container>
              
                <!-- Symbol Column -->
                <ng-container matColumnDef="read">
                  <th mat-header-cell *matHeaderCellDef> Read </th>
                  <td mat-cell *matCellDef="let element"> <mat-checkbox (change)="onPrivilegeChange(element, 'read')" class="example-margin" [(ngModel)]="element.read"></mat-checkbox> </td>
                </ng-container>
    
                <ng-container matColumnDef="write">
                  <th mat-header-cell *matHeaderCellDef> Write </th>
                  <td mat-cell *matCellDef="let element"> <mat-checkbox (change)="onPrivilegeChange(element, 'write')" class="example-margin" [(ngModel)]="element.write"></mat-checkbox> </td>
                </ng-container>
    
                <ng-container matColumnDef="admin">
                  <th mat-header-cell *matHeaderCellDef> Admin </th>
                  <td mat-cell *matCellDef="let element">  
                    <mat-checkbox (change)="onPrivilegeChange(element, 'admin')" class="example-margin" [(ngModel)]="element.admin"></mat-checkbox>
                  </td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              <!-- <mat-paginator
                    [length]="pagination.count"
                    [pageIndex]="pagination.pageIndex"
                    [pageSize]="pagination.perPage"
                    [pageSizeOptions]="[10, 25, 50, 100, 200]"
                    (page)="getServerData($event)"
              showFirstLastButtons></mat-paginator> -->
        </div>
    </div>
</div>