<div class="main-container">
    <div class="sub-container-header">
        <div>
            <span class="start-info" (click)="onNavigateBack()">{{headerData.userName}}</span> <span class="slash-info">/</span> <span class="end-info">New Program</span>
        </div>
        <div class="white program-info">
            New Program
            <span class="close_icon" (click)="onNavigateBackUser()">
                <mat-icon>close</mat-icon>
            </span>
        </div>
        <!-- <div class="sub-title">{{headerData.duration}}</div> -->
    </div>
    <div class="sub-container">
        <div *ngIf="active === 'stats'" class="stats">
            <div class="stats-label">Stats</div>
            <div class="nav-req-msg" *ngIf="startErr">Enter numeric values for both Weight and Bodyfat to proceed. </div>
            <div>
                <div class="col-33">
                    <div>Start Date</div>
                    <div class="input_field date-time">
                        <mat-form-field>
                            <input matInput [matDatepicker]="picker" [formControl]="dateValue" [min]="minDate">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-33">
                    <div>Start Weight</div>
                    <div class="input_field"><app-selectinput placeholder="Weight" [indexValue]="0" [setsValue]="0" [itemValue]="start_weight" type="start_weight" (action)="onAction($event)" ></app-selectinput>
                    </div>
                    <div>lbs</div>

                </div>
                <div class="col-33">
                    <div>Start Body Fat</div>
                    <div class="input_field"><app-selectinput placeholder="Body Fat" [indexValue]="0" [setsValue]="0" [itemValue]="start_body_fat" type="start_body_fat" (action)="onAction($event)" ></app-selectinput></div>
                    <div>%</div>
                </div>
            </div>
            <div>
                <div class="col-33">
                    <div class="spec_lbl_info">Fat Mass</div>
                    <div class="spec_info">{{fat_mass}} lbs</div>
                </div>
            </div>
            <div>
                <div class="col-33">
                    <div class="spec_lbl_info">Lean Mass</div>
                    <div class="spec_info">{{lean_mass}} lbs</div>
                </div>
            </div>
        </div>
        <div class="goals-container" *ngIf="active === 'goal'">
            <div class="stats-label">Goal</div>
            <div class="table-container">
                <table mat-table [dataSource]="goalsData">
                    <ng-container matColumnDef="measurement">
                        <th mat-header-cell *matHeaderCellDef>Measurement</th>
                        <td mat-cell *matCellDef="let element"> {{element.measurement}} </td>
                    </ng-container>

                    <ng-container matColumnDef="current">
                        <th mat-header-cell *matHeaderCellDef>Current</th>
                        <td mat-cell *matCellDef="let element"> {{element.current}} </td>
                    </ng-container>

                    <ng-container matColumnDef="classification">
                        <th mat-header-cell *matHeaderCellDef>Classification</th>
                        <td mat-cell *matCellDef="let element"> {{element.classification}} </td>
                    </ng-container>

                    <ng-container matColumnDef="chart">
                        <th mat-header-cell *matHeaderCellDef>Chart</th>
                        <td mat-cell *matCellDef="let element" [class.disabled]="element.isDisabled"> 
                            <a class="view-link" (click)="viewChart(element.chart)">VIEW</a> 
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="goalsColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: goalsColumns;"></tr>
                </table>
            </div>
            <div class="nav-req-msg" *ngIf="goalErr">Enter numeric values for both Bodyfat and Muscle Gain to proceed. </div>
            <div class="form-container">
                <div class="form-row">
                    <label for="Goal-Body-Fat">Goal Body Fat</label>
                    <input id="Goal-Body-Fat" [(ngModel)]="goalsBodyFat" autocomplete="off" />
                    <span class="units">%</span>
                </div>
                <div class="form-row">
                    <label for="Goal-Muscle-Gain">Goal Muscle Gain</label>
                    <input id="Goal-Muscle-Gain" [(ngModel)]="goalsMuscleGain" autocomplete="off" />
                    <span class="units">lbs</span>
                </div>
            </div>
        </div>
        <div *ngIf="active === 'roadmap'">
            <div class="program-creation-dropdown">
                <span class="title">Roadmap</span>
                <!-- <select class="optSelection" [(ngModel)]="recommenedGoalTime">
                    <option *ngFor="let opt of goalsDuartions" [value]="opt.split(' ')[0]">{{opt}}</option>
                </select> -->
                <mat-form-field>
                    <mat-select [(ngModel)]="recommenedGoalTime" (selectionChange)="onChangeProgram($event)" name="dropdown" placeholder="Roadmap">
                      <mat-option *ngFor="let data of goalsDuartions" [value]="data.split(' ')[0]">
                        {{data}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <div class="start-data">
                    <table class="disp-table">
                        <tr><td class="lbl">Start Weight</td><td class="vals">{{roadmapDataOverview?.start?.weight}} lbs</td></tr>
                        <tr><td class="lbl">Start Body Fat</td><td class="vals">{{roadmapDataOverview?.start?.bodyfat}} %</td></tr>
                        <tr><td class="lbl">Start Lean Mass</td><td class="vals">{{roadmapDataOverview?.start?.leanmass}} lbs</td></tr>
                        <tr><td class="lbl">Start Fat Mass</td><td class="vals">{{roadmapDataOverview?.start?.fatmass}} lbs</td></tr>
                        <tr><td class="lbl">Start Date</td><td class="vals">{{start_date}}</td></tr>
                    </table>
                </div> 
                <div class="goal-data">
                    <table class="disp-table">
                        <tr><td class="lbl">Goal Weight</td><td class="vals">{{roadmapDataOverview?.goal?.weight}} lbs</td></tr>
                        <tr><td class="lbl">Goal Body Fat</td><td class="vals">{{roadmapDataOverview?.goal?.bodyfat}} %</td></tr>
                        <tr><td class="lbl">Goal Lean Mass</td><td class="vals">{{roadmapDataOverview?.goal?.leanmass}} lbs</td></tr>
                        <tr><td class="lbl">Goal Fat Mass</td><td class="vals">{{roadmapDataOverview?.goal.bodyfatinpounds}} lbs</td></tr>
                        <tr><td class="lbl">End Date</td><td class="vals">{{end_date}}</td></tr>
                        <tr><td class="lbl">Sessions to Goal</td><td class="vals">{{roadmapDataOverview?.weekstogoal}}</td></tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="button-container">
        <button class="backbtn" *ngIf="active !== 'stats'" (click)="handleBack()">Back</button>
        <button class="nextbtn active" (click)="handleNext()">{{active !== 'roadmap'? 'Next' : 'Create Program'}}</button>
    </div>
</div>