import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { config } from '../config';
import { HttpService } from '../services/http/http.service';
import { UiService } from '../services/uiloader/uiloader.service';
import { ToasterService } from '../services/toaster/toaster.service';

let foodSearchTimeout:any;

@Component({
  selector: 'app-nutritionix-food',
  templateUrl: './nutritionix-food.component.html',
  styleUrls: ['./nutritionix-food.component.sass'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class NutritionixFoodComponent implements OnInit {
  @Output() action = new EventEmitter<any>();
  @Input() selectedIndex:any;

  foodSearch = '';
  foodSearchTableData:any = [];
  pagination = {
    count: 0,
    perPage: 10,
    pageIndex: 0,
    totalCount: 0
  };
  totalRecords = [];

  micronutrients = config.microNutrients;

  constructor(private httpService: HttpService,
    private ui: UiService, private toasterService: ToasterService) { }

  displayedColumns:any = ['name', 'unit', 'servingqty', 'cals', 'photo'];
  expandedElement: any;

  ngOnInit(): void {
  }

  getPaginatorRecords(data:any) {
    const clone = JSON.parse(JSON.stringify(data));
    const specPageIndex = this.pagination.pageIndex * this.pagination.perPage;
    const records = clone.splice(specPageIndex, this.pagination.perPage);
    this.foodSearchTableData =  records;
  }

  getServerData(e:any) {
    this.pagination.pageIndex = e.pageIndex;
    this.pagination.perPage = e.pageSize;
    this.getPaginatorRecords(this.totalRecords);
  }

  onFoodSearchSelect(data:any, index:number) {
    this.ui.spin$.next(true);
    const url = `${config.apiUrl}/nutrition/nutrientswithdetails`;
    const obj = {
      item: data.item_name
    };
    this.httpService.postCall(url, obj).subscribe(data => {
      this.foodSearchTableData[index].foods = data.foods[0];
      this.ui.spin$.next(false);
    }, err => {
      this.ui.spin$.next(false);
    });
  }

  getFoodSearch() {
    this.ui.spin$.next(true);
    const url = `${config.apiUrl}/nutrition/search/${this.foodSearch}`;
    this.httpService.getCall(url).subscribe(result => {
      this.pagination.totalCount = result.results.length;
      this.pagination.pageIndex = 0;
      this.totalRecords = result.results;
      this.pagination.count = this.totalRecords.length;
      this.getPaginatorRecords(this.totalRecords);
      this.ui.spin$.next(false);
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error occurred, Please try again');
    });
  }

  onAction(event:any) {
    if (event.type === 'foodSearch') {
      this.foodSearch = event.info;
      clearTimeout(foodSearchTimeout);
      foodSearchTimeout = setTimeout(()=>{
        this.getFoodSearch();
      }, 1000);
    }
  }

  onSpecificFoodItem(item:any, element:any, pos:any) {
    item.selected = true;
    item.selectedIndex = this.selectedIndex;
    this.action.emit(item);
  }

}
