<div class="main-container">
    <div class="header">
        Nutrition Program Uploader
    </div>
    <div>
        <mat-tab-group mat-align-tabs="start" >
            <mat-tab label="Create">
                <app-nutrition-program-uploader-create [nutritionCategories]="nutritionCategories" ></app-nutrition-program-uploader-create>
            </mat-tab>
            <mat-tab label="Edit">
                <app-nutrition-program-uploader-edit [nutritionCategories]="nutritionCategories" ></app-nutrition-program-uploader-edit>
            </mat-tab>
            <mat-tab label="Categories">
                <app-nutrition-program-uploader-categories [nutritionCategories]="nutritionCategories" ></app-nutrition-program-uploader-categories>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>