import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';

import { AppConstants } from '../constants/appConstants';
import { CommonService } from '../services/common/common.service';
import { config } from '../config';
import { HttpService } from '../services/http/http.service';
import { Router, ActivatedRoute } from '@angular/router';
import {CdkDropList, moveItemInArray, copyArrayItem, transferArrayItem, CdkDragExit, CdkDragDrop} from '@angular/cdk/drag-drop';
import { FormControl } from '@angular/forms';
import { LogService } from '../services/log/log.service';
import { UiService } from '../services/uiloader/uiloader.service';
import {jsPDF} from 'jspdf';
import html2canvas from "html2canvas";
import { AuthService } from '../auth/auth.service';
import { Location } from '@angular/common';
import { ToasterService } from '../services/toaster/toaster.service';
import { CookieService } from 'ngx-cookie-service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

let sessionTimeout:any;
let nutritionTimeout:any;

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.sass']
})

export class SessionComponent implements OnInit {

  newPlanName:string = '';
  nutritionCtrl:any;
  weeks:any = AppConstants.weekConstants;
  mealType:any = AppConstants.mealConstants;
  meal_type_id:string = '';
  index:number = 0;
  specificData:any = {};
  sessionId:any;
  userId:any;
  treeData:any = [];
  copyPlanObject:any ={
    client:'sameClinet',
    selectedUser:'',
    selectedSession:'',
    clientDetais:{},
    sessions: [],
    users:[],
  };


  isAllWeeksSelected = false;

  errorMsg:string = '';

  nutrition:any = [];

  selectedMealIndex = 0;
  mealPlans:any = [];
  meals:any = [];

  headerData:any = {};

  transferringItem: string | undefined = undefined;

  itemDisabled:boolean = true;

  sideTotal = {
    calories: 0,
    protein: 0,
    carbs: 0,
    fat: 0,
    protein_pct: 0,
    carbs_pct: 0,
    fat_pct: 0
  }
  hideTitle: any = false;

  sessionPlansOptions = [];

  specificSessionPlan = [];

  fixedDiv = false;

  saveItem(data:any, mealId:number) {
    let obj = { };
    let  url = `${config.apiUrl}/addmealtemplatefoodstosession`;

    if (data.nutritionprogram) {
      obj = {
        id: data.id,
        session_id: this.sessionId,
        nutritionprogram: data.nutritionprogram,
        client_id: this.specificData.clientId
      };
    } else if (data.mealtemplate) {
      if (mealId === -1) {
        this.toasterService.show('error', 'Info', 'You can not add meal template items with out meal plan');
        return false;
      }
      obj = {
        id: data.id,
        session_id: this.sessionId,
        mealtemplate: data.mealtemplate,
        client_id: this.specificData.clientId,
        meal_id: mealId
      };
    } else if (data.recipe) {
      if (mealId === -1) {
        this.toasterService.show('error', 'Info', 'You can not add recipe items with out meal plan');
        return false;
      }
      obj = {
        id: data.id,
        session_id: this.sessionId,
        client_id: this.specificData.clientId,
        recipe: data.recipe,
        meal_id: mealId
      };
    } else if (data.foodData) {
      if (mealId === -1) {
        this.toasterService.show('error', 'Info', 'You can not add food items with out meal plan');
        return false;
      }
      obj = {
        id: data.id,
        session_id: this.sessionId,
        client_id: this.specificData.clientId,
        meal_id: mealId,
        mealplan_id: data.mealplan_id
      };
      const foodSpecificData = JSON.parse(data.foodData);

      foodSpecificData.calories = this.commonService.getFixedValue(foodSpecificData.calories);
      foodSpecificData.carbs = this.commonService.getFixedValue(foodSpecificData.carbs);
      foodSpecificData.protein = this.commonService.getFixedValue(foodSpecificData.protein);
      foodSpecificData.fat = this.commonService.getFixedValue(foodSpecificData.fat);

      obj = {...obj, ...foodSpecificData};
      url = `${config.apiUrl}/addmealfood`;
    }
    this.ui.spin$.next(true);
    this.httpService.postCall(url, obj).subscribe(item => {
      if (data.foodData) {
        this.meals[data.draggedIndex].mealfoods.push(item);
      } else {
        this.errorMsg = '';
        const isRecordVaries = item[0].mealplans.length !== this.mealPlans.length;
        this.mealPlans = item[0].mealplans;
        if (isRecordVaries) {
          this.selectedMealIndex = this.mealPlans.length - 1;
        }
      }
      this.setWeeksMeals();
      this.ui.spin$.next(false);
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error Occurred', err?.error?.error);
    });
    return true;
  }

  drop(event: any,  index:number) {
    if (event.previousContainer.id !== event.container.id) {
      if (event.item.data) {
        copyArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
        //console.log(event.item.data);
        if (index !== -1) {
          event.item.data.mealplan_id = this.meals[index].mealplan_id;
          event.item.data.draggedIndex = index;
        }
        this.saveItem(event.item.data, index !== -1 ? this.meals[index].id : -1);
      } else {
        const reqObj = {
          deletefrom: {
            id: event.previousContainer.data[event.previousIndex].id,
            meal_id: event.previousContainer.id
          },
          addinto: {
            meal_id: event.container.id,
            display_order: event.currentIndex
          }
         }
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
        this.httpService.putCall(`${config.apiUrl}/reorderfoodsbwmeals`, reqObj).subscribe((result) => {
          const mealsData = this.meals;
          mealsData[index].mealfoods = result.foods;
          this.mealPlans[this.selectedMealIndex].meals = mealsData;
          this.setWeeksMeals();
         }, err => {
          this.ui.spin$.next(false);
         });
        
      }
    } else {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.reorderMealFoods(this.meals[index]);
    }

    this.transferringItem = undefined;
  }

  reorderMealFoods(data:any) {
    const url = `${config.apiUrl}/reordermealfoods/${data.id}`;
    const foodsData = data.mealfoods;
    const arr = foodsData.map((item:any, index:number) => ({
      id: item.id,
      display_order: index + 1
    }));
    const obj = {
      foods: arr
    };
    this.httpService.putCall(url, obj).subscribe(result => {

    }, err => {

    });
  }

  onOrderChangeNutrition(event:any, mealIndex:number, foodIndex:number) {
    console.log(event);
  }

  entered(e:any) {
    this.transferringItem = undefined;
  }

  exited(e: any) {
    this.transferringItem = e.item.data;
  }

  private _transformer = (node: any, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      id: node.id,
      nutritionprogram: node.nutritionprogram,
      mealtemplate: node.mealtemplate,
      recipe: node.recipe,
      foodData: (!node.nutritionprogram && !node.mealtemplate && !node.recipe) ? JSON.stringify(node) : null
    };
  };

  treeControl = new FlatTreeControl<any>(
    (node:any) => node.level,
    (node:any) => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  obtainedDataSource = [];

  constructor(public commonService: CommonService, private httpService: HttpService, private router: Router, private route: ActivatedRoute,
    private logService: LogService, private ui: UiService, private authService: AuthService, private location: Location, private cookieService: CookieService,
    private dialog: MatDialog,
    private toasterService: ToasterService) { 
    const goalData = this.authService.getGoalData();
    this.specificData = {
      programId: this.authService.getSelectedProgramId(),
      clientId: this.route.snapshot.params.userId
    };

    this.headerData = goalData;

    this.dataSource.data = [];

    this.nutritionCtrl = new FormControl();

  }

  hasChild = (_: number, node: any) => node.expandable;

  ngOnInit(): void {
    this.authService.hideDisplayHeaderValue();
    this.sessionId = this.route.snapshot.params.sessionId;
    this.userId = this.route.snapshot.params.userId;
    if(this.cookieService.get('editsession') === 'true') {
      this.hideTitle = true;
    }
    this.getMealsData();
    this.getProgramNutritionTabTree();
  }

  filterChanged(filterText: any) {
    let filteredTreeData = [];
    if(filterText.target.value)
    {
      function filter(array:any, text:any) {
        const getChildren = (result:any, object:any) => {
          var re = new RegExp(text, 'gi');
          if (object.name.match(re)) {
            result.push(object);
            return result;
          }
          if (Array.isArray(object.children)) {
            const children = object.children.reduce(getChildren, []);
            if (children.length) result.push({ ...object, children });
          }
          return result;
        };

        return array.reduce(getChildren, []);
      }

      filteredTreeData = filter(this.obtainedDataSource, filterText.target.value);
      this.dataSource.data = filteredTreeData;

      //this.treeControl.expandAll();
    } else {
      this.dataSource.data = this.obtainedDataSource;
      this.treeControl.collapseAll();
    }
  }

  timeChange(ev:any) {

  }

  getProgramNutritionTabTree() {
    const url = `${config.apiUrl}/getprogramnutritiontabtree`;
    this.httpService.getCall(url).subscribe(result => {
      const data = result[0].programnutritiontabtreeindex;
      const parsed = JSON.parse(data);
      // parsed.forEach((item:any) => {
      //   item.isDragDisabled = true;
      // });
      this.dataSource.data = parsed;
      this.obtainedDataSource = parsed;
    }, err => {

    });
  }

  setWeeks(data:any) {
    let isAllWeeks = true;
    this.weeks.forEach((item:any, index:any) => {
      this.weeks[index].checked = data[item.key];
      if (!data[item.key]) {
        isAllWeeks = false;
      }
    });

    this.isAllWeeksSelected = isAllWeeks;
  }

  getTotalCount(data:any) {
    if (!data ||data.length === 0) {
      return [];
    }
    const total = {
      calories: 0,
      protein: 0,
      carbs: 0,
      fat: 0,
      protein_pct: 0,
      carbs_pct: 0,
      fat_pct: 0
    };

    data.forEach((item:any, i:number) => {
      const obj:any = {
        totalCals: 0,
        totalProtein: 0,
        totalCarbs: 0,
        totalFat:0
      };
      item.mealfoods.forEach((record:any) => {
        obj.totalCals += record.calories;
        obj.totalProtein += record.protein;
        obj.totalCarbs += record.carbs;
        obj.totalFat += record.fat;
        record.log_qty = record.qty;
        if (record.is_added_manual === 1) {
          record.enabled = true;
        }
      });

      obj.totalCals = this.commonService.roundValues(obj.totalCals);
      obj.totalProtein = this.commonService.roundValues(obj.totalProtein);
      obj.totalFat = this.commonService.roundValues(obj.totalFat);
      obj.totalCarbs = this.commonService.roundValues(obj.totalCarbs);


      total.calories += obj.totalCals;
      total.protein += obj.totalProtein;
      total.fat += obj.totalFat;
      total.carbs += obj.totalCarbs;

      const totalmacros = obj.totalProtein * 4 + obj.totalCarbs * 4 + obj.totalFat * 9;

      obj.protein_pct = totalmacros > 0 ? Math.round(obj.totalProtein * 4 / totalmacros * 100) : 0
      obj.carbs_pct = totalmacros > 0 ? Math.round(obj.totalCarbs * 4 / totalmacros * 100) : 0;
      obj.fat_pct = totalmacros > 0 ? Math.round(obj.totalFat * 9 / totalmacros * 100) : 0;

      data[i] = {
        ...data[i],
        ...obj
      };
    });
    const totalCompleteMacros = total.protein * 4 + total.carbs * 4 + total.fat * 9;
    total.protein_pct = totalCompleteMacros > 0 ? Math.round(total.protein * 4 / totalCompleteMacros * 100) : 0;
    total.carbs_pct = totalCompleteMacros > 0 ? Math.round(total.carbs * 4 / totalCompleteMacros * 100) : 0;
    total.fat_pct = totalCompleteMacros > 0 ? Math.round(total.fat * 9 / totalCompleteMacros * 100) : 0;
    this.sideTotal = total;
    return data;
  }

  setWeeksMeals() {
    this.setWeeks(this.mealPlans[this.selectedMealIndex] ? this.mealPlans[this.selectedMealIndex] : {});
    this.meals = this.getTotalCount(this.mealPlans[this.selectedMealIndex] ? this.mealPlans[this.selectedMealIndex].meals : []);
    this.getSessionPlans();
  }

  addNoteFoodItem(i:number, j:number, item:any) {
    let dialogRef = this.dialog.open(NoteDialog, { data: {'note': item.note}, minWidth: 350, minHeight: 300 });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result.status) {
        item.changeNotes = result.note;
        this.updateFoodItem(i, j, item);
      }
    });
  }

  getUniqueSessionPlans() {
    const data:any = this.sessionPlansOptions[this.selectedMealIndex] || {};
    if (!data.meals) {
      return false;
    }
    const arr:any = [];
    data.meals.forEach((item:any) => {
      if (arr.indexOf(item.name) == -1) {
        arr.push(item.name);
      }
    });
    this.specificSessionPlan = arr; 
    return true;
  }

  onSelectMealPlan(i:number, event:any) {
    this.onChangeMealType(i, event.target.value);
  }

  getSessionPlans() {
    if (this.sessionPlansOptions.length === 0) {
    const url = `${config.apiUrl}/getsessionplans`;
    const reqObj = {
      session_id: this.sessionId,
      client_id: this.userId,
     };
     this.httpService.postCall(url, reqObj).subscribe((data:any) => {
      this.sessionPlansOptions = data.mealplans;
      this.getUniqueSessionPlans();
     }, (err) => {
       console.log(err);
     });
    } else {
      this.getUniqueSessionPlans();
    }
  }

  getMealsData() {
    const obj = {
      "program_id": this.sessionId,
      "client_id": this.specificData.clientId
    };

    this.httpService.postCall(`${config.apiUrl}/getprogrammealplans`, obj).subscribe((data:any) => {
      this.mealPlans = data[0].mealplans;
      this.setWeeksMeals();
      this.errorMsg = '';
    }, (err:any) => {
      if (err.error?.error) {
        this.errorMsg = err.error.error;
      }
    });
  }

  onAddPlan() {
    const req = {
      program_id: this.sessionId,
      name: this.newPlanName,
      client_id: this.specificData.clientId
    };
    this.ui.spin$.next(true);
    this.httpService.postCall(`${config.apiUrl}/createmealplan`, req).subscribe(result => {
      this.newPlanName = '';
      this.mealPlans = result[0].mealplans;
      this.selectedMealIndex = this.mealPlans.length - 1;
      this.setWeeksMeals();
      this.ui.spin$.next(false);
      this.errorMsg = '';
      this.toasterService.show('success', 'Success', 'Plan created successfully');
    }, err => {
      this.ui.spin$.next(false);
      if (err.error?.error) {
        this.errorMsg = err.error.error;
      }
      this.toasterService.show('error', 'Error', 'Failed to create plan');
    });
  }

  calculateValue(val:any, qty:any, logQty:any) {
    const res = val / qty;
    return this.commonService.getFixedValue(res * logQty);
  }

  updateFoodItem(i:number, j:number, item:any = {}) {
    const data = this.meals[i].mealfoods[j];
    if (data.id) {
      const url = `${config.apiUrl}/updatemealfood/${data.id}`;
      if (!data.qty || data.qty === '0' || isNaN(data.qty)) {
        return false;
      }
      //data.log_qty = data.qty;
      const obj:any = {
        "meal_id": data.meal_id,
        "name": data.name,
        "qty": data.qty,
        "calories": this.calculateValue(data.calories, data.log_qty, data.qty),
        "carbs": this.calculateValue(data.carbs, data.log_qty, data.qty),
        "unit": data.unit,
        "protein": this.calculateValue(data.protein, data.log_qty, data.qty),
        "fat": this.calculateValue(data.fat, data.log_qty, data.qty),
       };

       if (item.changeNotes) {
        obj.note = item.changeNotes;
       }
  
       this.httpService.putCall(url, obj).subscribe(result => {
        this.meals[i].mealfoods[j].calories = obj.calories;
        this.meals[i].mealfoods[j].carbs = obj.carbs;
        this.meals[i].mealfoods[j].protein = obj.protein;
        this.meals[i].mealfoods[j].fat = obj.fat;
        this.meals[i].mealfoods[j].log_qty = obj.qty;
        this.meals[i].mealfoods[j].qty = obj.qty;
        this.meals[i].mealfoods[j].note = obj.note;
        this.setWeeksMeals();
       }, err => {
        this.toasterService.show('error', 'Error', 'Error Occurred while updating food');
       });
    } else {
      if (Object.keys(item).length === 0) {
        return false;
      }
      const obj = {
        meal_id: this.meals[i].id,
        mealplan_id: this.meals[i].mealplan_id,
        ...item
      };
      obj.calories = this.commonService.getFixedValue(obj.calories);
      obj.carbs = this.commonService.getFixedValue(obj.carbs);
      obj.protein = this.commonService.getFixedValue(obj.protein);
      obj.fat = this.commonService.getFixedValue(obj.fat);
      const url = `${config.apiUrl}/addmealfood`;
      this.httpService.postCall(url, obj).subscribe(result => {
        result.carbs = parseFloat(result.carbs);
        result.protein = parseFloat(result.protein);
        result.calories = parseFloat(result.calories);
        result.fat = parseFloat(result.fat);
        this.meals[i].mealfoods[j] = result;
        this.setWeeksMeals();
      }, err => {
        this.toasterService.show('error', 'Error', 'Error Occurred while creating food');
      });
    }
    return true;
  }

  onAction(item:any) {
    if (item.type === 'new_plan') {
      this.newPlanName = item.info;
    } else {
      const obj = this.meals[item.index].mealfoods[item.setIndex];
      obj[item.type] = item.info;
      if (!obj.id) {
        clearTimeout(nutritionTimeout);
        nutritionTimeout = setTimeout(() => {
          obj.name = obj.displayName;
          obj.is_added_manual = 1;
          this.updateFoodItem(item.index, item.setIndex, obj);
        }, 1000);
      } else if (obj.is_added_manual === 1) {
        clearTimeout(nutritionTimeout);
        nutritionTimeout = setTimeout(() => {
          this.updateFoodItem(item.index, item.setIndex);
        }, 1000);
      } else {
        clearTimeout(nutritionTimeout);
        nutritionTimeout = setTimeout(() => {
          this.updateFoodItem(item.index, item.setIndex);
        }, 1000);
      }
    }
  }

  onSelectAllDays() {
    this.isAllWeeksSelected = !this.isAllWeeksSelected;
    this.onUpdateWeeks({}, true);
  }

  onUpdateWeeks(item:any, isAllWeeks = false) {
    const data = this.mealPlans[this.selectedMealIndex];
    const url = `${config.apiUrl}/updatemealplan/${data.id}`;
    const { key } = item;
    const obj: any = {
      "program_id": this.sessionId,
    };
    if (isAllWeeks) {
      this.weeks.forEach((record:any, index:number) => {
        this.weeks[index].checked = this.isAllWeeksSelected;
        obj[record.key] = this.isAllWeeksSelected;
      });
    } else {
      obj[key] = item.checked ? 1 : 0;
    }
    this.httpService.putCall(url, obj).subscribe(result => {
      if (!isAllWeeks) {
        this.mealPlans[this.selectedMealIndex][key] = item.checked;
      } else {
        this.weeks.forEach((record:any) => {
          this.mealPlans[this.selectedMealIndex][record.key] = this.isAllWeeksSelected;
        })
      }
    }, err => {
      this.toasterService.show('error', 'Error', 'Failed to update week');
    });
  }

  onParentTreeItem(index:number) {

  }

  onChangeMealType(i:number, name:string = '') {
    const data = this.meals[i];
    if (name.length > 0) {
      data.name = name;
    }
    const url = `${config.apiUrl}/updatemeal/${data.id}`;
    const obj = {
      mealplan_id: data.mealplan_id,
      name: data.name,
      time: data.time
    };

    this.httpService.putCall(url, obj).subscribe(item => {

    }, err => {

    });
  }

  searchResult(ev:any) {

  }

  onMealSelection(index:number) {
    this.selectedMealIndex = index;
    this.setWeeksMeals();
  }

  copyMealSection(data:any) {
    this.ui.spin$.next(true);
    const obj = {
      "to_mealplan_id": data.mealplan_id,
      "from_mealplan_id": data.mealplan_id,
      "from_meal_id": data.id
    };
    const url = `${config.apiUrl}/copymealplanmeal`;
    this.httpService.postCall(url, obj).subscribe(data => {
      this.ui.spin$.next(false);
      this.mealPlans = data[0].mealplans;
      this.setWeeksMeals();
      this.toasterService.show('success', 'Success', 'Meal copied successfully');
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Failed to copy meal');
    });
  }

  copyFoodItem(data:any, index:number) {
    if (!data.meal_id) {
      this.toasterService.show('error', 'Error', 'This record can not be copied');
      return false;
    }
    this.ui.spin$.next(true);
    const obj = {
      "from_meal_id": data.meal_id,
      "food_id": data.id
    };
    const url = `${config.apiUrl}/copymealfood`;
    this.httpService.postCall(url, obj).subscribe(data => {
      this.meals[index].mealfoods.push(data);
      this.setWeeksMeals();
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'Food copied successfully');
    }, err => {
      this.toasterService.show('error', 'Error', 'Error Occurred while copying food');
      this.ui.spin$.next(false);
    });
    return false;
  }

  deleteMealFood(data:any, i:number , j:number) {
    if (!data.meal_id) {
      this.meals[i].mealfoods.splice(j, 1);
      return true;
    }
    this.ui.spin$.next(true);
    const url = `${config.apiUrl}/deletemealfood/${data.meal_id}/${data.id}`;
    this.httpService.deleteCall(url, {}).subscribe(data => {
      this.meals[i].mealfoods.splice(j, 1);
      this.setWeeksMeals();
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'Meal deleted successfully');
    }, err => {
      this.toasterService.show('error', 'Error', 'Error Occurred, Please try again');
      this.ui.spin$.next(false);
    });
    return true;
  }
  onSelectShoppingList(){
    const url = `${config.apiUrl}/getshoppinglistpdf/${this.specificData.clientId}/${this.sessionId}`;
    this.ui.spin$.next(true);
    this.httpService.getCallFile(url).subscribe(result => {
      var file = new Blob([result], {type: 'application/pdf'});
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'PDF file created successfully');
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error while creating PDF file');
    });
    return true;
  }

  onSelectRecipes(){
    if (this.mealPlans.length === 0) {
      return false;
    }
    const url = `${config.apiUrl}/getrecipepdf/${this.sessionId}`;
    this.ui.spin$.next(true);
    this.httpService.getCallFile(url).subscribe(result => {
      var file = new Blob([result], {type: 'application/pdf'});
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'PDF file created successfully');
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error while creating PDF file');
    });
    return true;
  }

  deleteMealPlan(data:any, i:number) {
    let dialogRef = this.dialog.open(NavDialog, { data: {'content': 'Are you sure, You want to delete meal plan'} });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
    this.ui.spin$.next(true);
    const url = `${config.apiUrl}/deletemeal/${data.mealplan_id}/${data.id}`;
    this.httpService.deleteCall(url, {}).subscribe(data => {
      this.meals.splice(i, 1);
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'Meal deleted successfully');
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error while deleting meal');
    });
  }
});
  }

  openSessionPopup() {
    let copySessiondialogRef = this.dialog.open(CopyDialogComponent, { data: { 'title': "Select Session for client", "copyUser": true, copyPlanObject: this.copyPlanObject }, width: '600px' });
    copySessiondialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.copyNutritionPlan();
      }
    })
  }
  
  getPrograms(reqObj: any) {
    this.ui.spin$.next(true);
    const url = `${config.apiUrl}/getclientprograms`;
    this.httpService.postCall(url, reqObj).subscribe(data => {
      this.ui.spin$.next(false);
      this.copyPlanObject.clientDetais = data.clientDetais;
      this.copyPlanObject.sessions = data.programs;
     this.openSessionPopup();
    }, err => {
      this.ui.spin$.next(false);
      this.dialog.closeAll();
      this.toasterService.show('error', 'Error', 'Error Occurred while fetching progrmas');
    });
  }
  copyNutritionPlan() {
    console.log(this.copyPlanObject);
    this.ui.spin$.next(true);
    const url = `${config.apiUrl}/copymealplan`;
    const reqObj = {
      "from_program_id": this.specificData.programId,
      "from_mealplan_id": this.mealPlans[this.selectedMealIndex] ? this.mealPlans[this.selectedMealIndex].id : '',
      "to_program_id": this.copyPlanObject.selectedSession,
    }

    this.httpService.postCall(url, reqObj).subscribe(data => {
      this.ui.spin$.next(false);
     if(this.copyPlanObject.client === 'sameClinet') this.mealPlans = data[0].mealplans;
      this.toasterService.show('success', 'Success', 'Meal copied successfully');
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error Occurred while copying meal');
    });
  }

  copyPlan() {
    this.copyPlanObject = {
      client: 'sameClinet',
      selectedUser: '',
      selectedSession: '',
      clientDetais: {},
      sessions: [],
      users: [],
    };
    let CopydialogRef = this.dialog.open(CopyDialogComponent, { data: { 'title': 'Do you want to copy for', 'initial': true, copyPlanObject: this.copyPlanObject }, width: '500px' });
    CopydialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (this.copyPlanObject.client === 'sameClinet') {
          const reqObj = {
            "client_id": this.specificData.clientId,
          }
          this.getPrograms(reqObj);
        }
        else if (this.copyPlanObject.client === 'differentClient') {
          this.ui.spin$.next(true);
          const url = `${config.apiUrl}/fetchloggedinconsultantclients`;
          this.httpService.postCall(url, { "client_id": this.specificData.clientId }).subscribe(data => {
            this.ui.spin$.next(false);
            this.copyPlanObject.users = data;
            let copyUserdialogRef = this.dialog.open(CopyDialogComponent, { data: { 'title': "Select Clients", "copyUser": false, copyPlanObject: this.copyPlanObject }, width: '600px' });
            copyUserdialogRef.afterClosed().subscribe((result: any) => {
              if (result) {
                const reqObj = {
                  "client_id": this.copyPlanObject.selectedUser,
                }
                this.getPrograms(reqObj);
              }
            })
          }, err => {
            this.ui.spin$.next(false);
            this.dialog.closeAll();
            this.toasterService.show('error', 'Error', 'Error Occurred while fetching clients');
          });
        }
      }
    })
  }


  
  deletePlan() {
    let dialogRef = this.dialog.open(NavDialog, { data: {'content': 'Are you sure, You want to delete plan'}  });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
        this.ui.spin$.next(true);
        const data = this.mealPlans[this.selectedMealIndex];
        const url = `${config.apiUrl}/deletemealplan/${this.sessionId}/${data.id}`;
        this.httpService.deleteCall(url, {}).subscribe(item => {
          this.mealPlans.splice(this.selectedMealIndex, 1);
          if (this.selectedMealIndex > (this.mealPlans.length - 1)) {
            this.selectedMealIndex = this.mealPlans.length - 1;
          }
          this.setWeeksMeals();
          this.ui.spin$.next(false);
          this.toasterService.show('success', 'Success', 'Plan deleted successfully');
        }, err => {
          this.ui.spin$.next(false);
          this.toasterService.show('error', 'Error', 'Error while deleting plan');
      });
    }
  });
  }

  addFoodItem(event:any, i:number) {
    const obj = JSON.parse(JSON.stringify({displayName: '', enabled: true}));
    this.meals[i].mealfoods.push(obj);
    setTimeout(()=>{
      event.target.parentElement.previousElementSibling.children[1].children[0].children[0].children[0].focus();
    });
  }

  fetchSearchedNutrition(value:string) {
    this.httpService.getCall(`${config.apiUrl}/nutrition/search/${value}`).subscribe(data => {
      this.nutrition = data.results;
    },
    err => {
      this.logService.logError(err);
    });
  }

  onSearchNutrition(ev:any) {
    const { value = '' } = ev.target;
    clearTimeout(sessionTimeout);
    sessionTimeout = setTimeout(() => {
      this.fetchSearchedNutrition(value);
    }, 600);
  }

  onSelectNutrition(item:any, mealIndex:number, foodIndex:number) {
    this.nutritionCtrl.setValue('');
    this.ui.spin$.next(true);
    let reqType:any;
    if (item.nix_item_id) {
      reqType = this.httpService.getCall(`${config.apiUrl}/nutrition/searchbyscan/${item.nix_item_id}`);
    } else {
      reqType = this.httpService.postCall(`${config.apiUrl}/nutrition/nutrients`, {item: item.item_name});
    }

    reqType.subscribe((data:any) => {
      const [ result ] = data.foods;
      this.meals[mealIndex].mealfoods[foodIndex].name = result.food_name;
      this.meals[mealIndex].mealfoods[foodIndex].log_qty = result.qty;
      this.meals[mealIndex].mealfoods[foodIndex].qty = result.qty;
      this.meals[mealIndex].mealfoods[foodIndex].calories = result.calories;
      this.meals[mealIndex].mealfoods[foodIndex].carbs = result.carbs;
      this.meals[mealIndex].mealfoods[foodIndex].protein = result.protein;
      this.meals[mealIndex].mealfoods[foodIndex].fat = result.fat;
      this.meals[mealIndex].mealfoods[foodIndex].unit = result.unit;

      this.updateFoodItem(mealIndex, foodIndex, result);
      this.ui.spin$.next(false);
    },
    (err:any) => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });

  }

  addMeal() {
    const data = this.mealPlans[this.selectedMealIndex];
    const url = `${config.apiUrl}/createmeal`;
    const reqData = {
        "program_id": this.sessionId,
        "client_id": this.specificData.clientId,
        "mealplan_id": data.id,
        "name":"Breakfast",
        "time":"09:00"
    };
    this.httpService.postCall(url, reqData).subscribe(item => {
      this.getMealsData();
    }, err => {

    });
  }

  async downloadAsPDF() {
    if (this.mealPlans.length === 0) {
      return false;
    }
    const url = `${config.apiUrl}/getmealspdf/${this.specificData.clientId}/${this.sessionId}`;
    this.ui.spin$.next(true);
    this.httpService.getCallFile(url).subscribe(result => {
      var file = new Blob([result], {type: 'application/pdf'});
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'PDF file created successfully');
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error while creating PDF file');
    });
    return true;
  }
  showGuide(name:string) {
    window.open(`/assets/${name}.pdf`);
  }

  onNavigateBack() {
    this.location.back();
  }

  onNavigateBackUser() {
    this.router.navigate(['/userdetails/' + this.headerData.client_id ]);
  }

  onSideScroll(ev:any) {
    if (ev.currentTarget.scrollY > 310) {
      this.fixedDiv = true;
    } else {
      this.fixedDiv = false;
    }
  }

  onSortMealPlanLower(index:number, isLower:boolean) {
    const record = this.meals.splice(index, 1);
    const [ specific ] = record;
    const sortedIndex = isLower ? index + 1 : index - 1;
    this.meals.splice(sortedIndex, 0, specific);
    const actualRecord = this.meals[index];
    const sortedRecord = this.meals[sortedIndex];
    this.saveUpdatedOrder(sortedRecord, sortedIndex, actualRecord, index);
  }

  saveUpdatedOrder(sortingRecord:any, sortingIndex:number, actualRecord:any, actualIndex:number) {
    const url = `${config.apiUrl}/reordermeals/${this.mealPlans[this.selectedMealIndex].id}`;
    const reqObj = {
      meals: [{
        id: sortingRecord.id,
        display_order: sortingIndex + 1
       },
       {
        id: actualRecord.id,
        display_order: actualIndex + 1
       }
      ]
    };

    this.httpService.putCall(url, reqObj).subscribe(result => {

    }, err => {
      this.toasterService.show('error', 'Error', err?.errors);
      this.logService.logError(err);      
    });
  }

}

@Component({
  selector: 'copy-overview-dialog',
  templateUrl:'./copy-overview-dialog.html',
  styleUrls:['./session.component.sass']
})
export class CopyDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CopyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

    responseHandler(action:any): void {
    this.dialogRef.close(action);
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  template: `
  <h1 mat-dialog-title>Confirmation</h1>
  <div mat-dialog-content class="dialogContent">
      <p>{{data.content}}</p>
    </div>
    <div mat-dialog-actions class="dialogActions">
      <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>Submit</button>
      <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
    </div>`,
  styleUrls: ['./session.component.sass']
})
export class NavDialog {
  constructor(
    public dialogRef: MatDialogRef<NavDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  responseHandler(action:any): void {
    this.dialogRef.close(action);
  }
}

@Component({
  selector: 'dialog-overview-add-note-dialog',
  template: `
  <h1 mat-dialog-title>Note</h1>
    <div mat-dialog-content class="dialogContent">
      <textarea (change)="onNoteChange($event)" [value]="data.note" class="add-note-textarea"> </textarea>
    </div>
    <div mat-dialog-actions class="dialogActions">
      <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>Submit</button>
      <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
    </div>
    `,
  styleUrls: ['./session.component.sass']
})
export class NoteDialog {
  constructor(
    public dialogRef: MatDialogRef<NoteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

    onNoteChange(ev:any) {
      this.data.note = ev.target.value;
    }

  responseHandler(action:any): void {
    let obj = {
      status: false,
      note: ''
    };
    if (action) {
      obj = {
        status: true,
        note: this.data.note
      };
    }
    this.dialogRef.close(obj);
  }
}
