import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HttpService } from '../../services/http/http.service';
import { CommonService } from '../../services/common/common.service';
import { UiService } from '../../services/uiloader/uiloader.service';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { config } from '../../config';

let timeoutHandle:any;

@Component({
  selector: 'app-recipesuploadercreate',
  templateUrl: './recipesuploadercreate.component.html',
  styleUrls: ['./recipesuploadercreate.component.sass']
})
export class RecipesuploadercreateComponent implements OnInit {
  @Input() recipesCategories:any;

  imageCustomUrl = config.exerciseImageUrl;

  isUseImagesFromCopy = false;

  recipeImageSequence:any = [];

  requiredFields = ['name', 'note', 'description', 'recipecategory', 'foods'];

  formInvalidFields:any = false;

  videoUrl = '';
  recipeName = '';
  recipeUnitName = '';
  defaultQty = '';

  description = '';
  note = '';
  instructions = '';

  message = '';
  recipeImageSequenceFormData:any = [];

  selected_recipe_category = -1;
  newRecipeCategory = '';
  selected_sub_recipe_category = -1;

  datasource:any = new MatTableDataSource<any>();
  recipesSource:any = new MatTableDataSource<any>();

  recipesList = [];

  recipeimages:any = [];

  pagination = {
    count: 0,
    perPage: 25,
    pageIndex: 0,
    searchtext: '',
    totalCount: 0
  };

  displayedColumns: string[] = ['name', 'category', 'calories', 'protein', 'carbs', 'fat', 'unit', 'quantity', 'delete'];


  totals:any = {};

  constructor(private httpService: HttpService,
    private dialog: MatDialog,
    private ui: UiService,
    public commonService: CommonService) { 
      this.getFoodTemplates();
      this.getRecipes();
    }

  ngOnInit(): void {
  }

  getRecipes() {
    const url = `${config.apiUrl}/recipe/getrecipes`;
    const reqObj = {
      "orderByOn":"name",
      "orderBy":"ASC",
      "limit":250,
      "fromIndex":0,
      "searchtext":""
    };
    this.httpService.postCall(url, reqObj).subscribe(result => {
      this.recipesList = result.rows;
    }, err => {
      console.log(err);
    });
  }

  getFoodTemplates() {
    const url = `${config.apiUrl}/foods/getfoodtemplates`;
    const req = {
      limit: this.pagination.perPage,
      fromIndex: this.pagination.pageIndex
    };
    this.httpService.postCall(url, req).subscribe((result:any) => {
      this.recipesSource = result.rows;
      this.pagination.count = result.count;
    }, (err:any) => {

    })
  }

  getServerData(e:any) {
    console.log(e.pageIndex);
    console.log(e.pageSize);
    this.pagination.pageIndex = e.pageIndex;
    this.pagination.perPage = e.pageSize;
    this.getFoodTemplates();
  }

  onChangeRecipeCategory() {
    console.log(this.selected_recipe_category);
    this.newRecipeCategory = '';
    this.selected_sub_recipe_category = -1;
  }

  onChangeRecipeSubCategory() {
    this.selected_recipe_category = -1;
  }

  onAction(event:any) {
    if (event.type === 'recipeName') {
      this.recipeName = event.info;
    } else if (event.type === 'newRecipeCategory') {
      this.newRecipeCategory = event.info;
    } else if (event.type === 'videoUrl') {
      this.videoUrl = event.info;
    } else if (event.type === 'recipeUnitName') {
      this.recipeUnitName = event.info;
    } else if (event.type === 'defaultQty') {
      this.defaultQty = event.info;
    }
  }

  toBase64(file:any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  onDeleteRecord(ev: any, index: number) {
    this.onFoodSelection({deletedIndex: index}, false, true);
  }

  onQuantityChange(ev: any, record: any, index: number) {
    clearTimeout(timeoutHandle);
    timeoutHandle = setTimeout(() => {
      this.onFoodSelection({}, true);
    }, 1000);
  }

  async preview(files:any) {
    if (files.length === 0)
      return;
 
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    this.recipeImageSequenceFormData.push(files[0]);

    const data:any = await this.toBase64(files[0]);
    const modified = data.split('base64,');

    this.recipeImageSequence.push(data);

  }

  onUploadImageSequence(fileInputEvent:any) {
    var files = fileInputEvent.target.files;
    this.preview(files);
    console.log(files);
  }

  onFoodSelection(event:any, isUpdated = false, isRemove = false) {
    console.log(event);
    const records = this.datasource.data;
    if (!isUpdated && !isRemove) {
      records.push(event);
    }
    const totalsCalculated = {
      cals: 0,
      protein: 0,
      carbs: 0,
      fat: 0
    };
    this.datasource.data = records.filter((item:any, index: number) => {
      if (event.deletedIndex !== index) {
        item.quantity = item.quantity || 1 ;

        totalsCalculated.cals += item.calories * item.quantity;
        totalsCalculated.protein += item.protein * item.quantity;
        totalsCalculated.carbs += item.carbs * item.quantity;
        totalsCalculated.fat += item.fat * item.quantity;
        return item;
      }
      return false;
    });
    this.totals = totalsCalculated;
    this.ui.spin$.next(false);
  }

  specificRecord(record:any) {
    this.onFoodSelection(record);
    console.log(record);
  }

  onResetMeal() {
    this.datasource.data = [];
    this.recipeName = '';
    this.recipeUnitName = '';
    this.defaultQty = '';
    this.note = '';
    this.description = '';
    this.videoUrl = '';
    this.instructions = '';
    this.selected_sub_recipe_category = -1;
    this.selected_recipe_category = -1;
    this.newRecipeCategory = '';
  }

  updateImages(templateId:number) {
    const formData = new FormData();
    this.recipeImageSequenceFormData.forEach((item:any) => {
      formData.append("sequence", item);
    });
    const url = `${config.apiUrl}/recipe/uploadimages/${templateId}`;

    this.httpService.putCall(url, formData).subscribe(result => {
      this.onResetMeal();
    }, err => {

    });
  }

  onSubmitMeal() {
    const reqObj:any = {
      name: this.recipeName,
      video_url: this.videoUrl,
      unit: this.recipeUnitName,
      qty: this.defaultQty,
      note: this.note,
      description: this.description,
      recipecategory: this.newRecipeCategory || this.selected_recipe_category,
      subcat: this.selected_sub_recipe_category,
      foods: [...this.datasource.data]
    };

    const isFormInValid: any = [];
    this.requiredFields.forEach((key: string) => {
     if (!reqObj[key]) {
       isFormInValid.push(key);
     }
     if (key === 'foods' && reqObj[key].length === 0) {
      isFormInValid.push(key);
     }
    });

    if (reqObj.recipecategory && isNaN(reqObj.recipecategory)) {
      if (!reqObj.subcat) {
        isFormInValid.push('Sub category');
      }
    } else if (reqObj.recipecategory === -1 || reqObj.subcat === -1) {
      isFormInValid.push('Recipe Category');
    }

   if (isFormInValid.length > 0) {
     this.formInvalidFields = isFormInValid;
     setTimeout(() => {
       this.formInvalidFields = [];
     }, 3000);
     return false;
   }

    const url = `${config.apiUrl}/recipe/createrecipe`;
    this.httpService.postCall(url, reqObj).subscribe(result => {
      this.updateImages(result.data.id);
       if (this.recipeImageSequenceFormData.length === 0) {
        this.onResetMeal();
       }
       //this.ui.spin$.next(false);
    }, err => {
      console.log(err);
    });
    return true;
  }

  getSpecificRecipeData(recipeId: any) {
    const url = `${config.apiUrl}/recipe/getrecipedetails/${recipeId}`;
    this.httpService.getCall(url).subscribe(result => {
      this.recipeName = result.name;
      this.description = result.description;
      this.instructions = result.instructions;
      this.note = result.note;
      this.videoUrl = result.video_url;
      this.recipeUnitName = result.unit;
      this.defaultQty = result.qty;
      this.recipeimages = result.recipeimages;
      if (this.recipeimages.length > 0) {
        this.isUseImagesFromCopy = true;
      }
      this.datasource.data = result.recipefoods.map((item:any) => ({
        ...item,
        quantity: item.qty
      }));

      this.onFoodSelection({}, true);
    }, err => {

    });
  }

  onCopyRecipe() {
    const dialogRef = this.dialog.open(CopyRecipeDialog, { data: {recipesList: this.recipesList}, minWidth: 350, minHeight: 220 });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result.recipeSelectedId) {
        this.ui.spin$.next(true);
        this.getSpecificRecipeData(result.recipeSelectedId);
      }
    });
  }

}

@Component({
  selector: 'dialog-overview-copy-recipe-dialog',
  template: `
  <h1 mat-dialog-title>Copy Existing Recipe</h1>
    <div mat-dialog-content class="dialogContent">
    <div class="select-ddl">
    <mat-form-field class="width-full recipe-ddl-dialog">
      <mat-label>Select Recipe</mat-label>
      <mat-select [(ngModel)]="recipeSelectedId">
        <mat-option *ngFor="let item of recipesList"  [value]="item.id">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    </div>
    </div>
    <div mat-dialog-actions class="dialogActions">
      <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>Submit</button>
      <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
    </div>
    `,
  styleUrls: ['../recipes-uploader.component.sass']
})
export class CopyRecipeDialog {
  recipeSelectedId = -1;
  recipesList:any = [];

  constructor(
    public dialogRef: MatDialogRef<CopyRecipeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.recipesList = data.recipesList;
    }

  responseHandler(action:any): void {
    const obj:any = {};
    if (action) {
      obj.recipeSelectedId = this.recipeSelectedId;
    }
    this.dialogRef.close(obj);
  }
}