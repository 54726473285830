import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {LoginflowComponent} from './loginflow/loginflow.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import { AuthGuard } from './auth/auth.guard';
import { ConsultantsComponent } from './consultants/consultants.component';
import { ReportsComponent } from './reports/reports.component';
import { UploaderComponent } from './uploader/uploader.component';
import { UsersComponent } from './users/users.component';
import { PlatformComponent } from './platform/platform.component';
import { UserdetailsComponent } from './userdetails/userdetails.component';
import { SurveyComponent } from './survey/survey.component';
import { CreateuserComponent } from './createuser/createuser.component';
import { SurveyfinishComponent } from './surveyfinish/surveyfinish.component';
import { AppleComponent } from './apple/apple.component';
import { SurveyinitComponent } from './surveyinit/surveyinit.component';
import { AdduserComponent } from './adduser/adduser.component';
import { ConsultantsubsComponent } from './consultants/consultantsubs/consultantsubs.component';
import { ProspectsComponent } from './prospects/prospects.component';
import { ProspectprofileComponent } from './prospectprofile/prospectprofile.component';
import { RoleseditorComponent } from './roleseditor/roleseditor.component';
import { ResourcesComponent } from './resources/resources.component';
import { CompositionComponent } from './composition/composition.component';
import { ClientdashboardComponent } from './clientdashboard/clientdashboard.component';
import { ClientexerciseComponent } from './clientexercise/clientexercise.component';
import { ClientresourcesComponent } from './clientresources/clientresources.component';
import { UserprofileComponent } from './userprofile/userprofile.component';
import { ClientnutritionComponent } from './clientnutrition/clientnutrition.component';
import { NewprogramComponent } from './newprogram/newprogram.component';
import { SessionComponent } from './session/session.component';
import { ProgramcreationComponent } from './programcreation/programcreation.component';
import { FoodsuploaderComponent } from './foodsuploader/foodsuploader.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ExercisesuploaderComponent } from './exercisesuploader/exercisesuploader.component';
import { MealsuploaderComponent } from './mealsuploader/mealsuploader.component';
import { RecipesUploaderComponent } from './recipes-uploader/recipes-uploader.component';
import { NutritionProgramUploaderComponent } from './nutrition-program-uploader/nutrition-program-uploader.component';
import { ExercisePlansUploaderComponent } from './exercise-plans-uploader/exercise-plans-uploader.component';
import { ProgramArchiveComponent } from './program-archive/program-archive.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  { path: 'login', component: LoginflowComponent },
  { path: 'loginBasic', component: LoginComponent},
  { path: 'dashboard', component: ClientdashboardComponent, canActivate: [AuthGuard] },
  { path: 'consultant-clients', component: ProspectsComponent, canActivate: [AuthGuard] },
  { path: 'consultants/:subs/:id', component: ConsultantsubsComponent, canActivate: [AuthGuard] },
  { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard] },
  { path: 'commonuploader', component: UploaderComponent, canActivate: [AuthGuard] },
  { path: 'company-clients', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'platform-setup', component: PlatformComponent, canActivate: [AuthGuard] },
  { path: 'userdetails/:id', component: UserdetailsComponent, canActivate: [AuthGuard]},
  { path: 'survey/:pageId', component: SurveyComponent},
  { path: 'surveyinit/:emailId/:surveyId', component: SurveyinitComponent},
  { path: 'createuser', component: CreateuserComponent},
  { path: 'surveyfinish', component: SurveyfinishComponent},
  { path: 'auth/apple/:token', component: AppleComponent},
  { path: 'adduser', component: AdduserComponent },
  { path: 'prospects', component: ConsultantsComponent},
  { path: 'prospectprofile', component: ProspectprofileComponent},
  { path: 'roles-editor', component: RoleseditorComponent},
  { path: 'resources', component: ResourcesComponent },
  { path: 'clientcomposition', component: CompositionComponent},
  { path: 'clientcomposition/:programId', component: CompositionComponent},
  { path: 'clientnutrition', component: ClientnutritionComponent},
  { path: 'clientnutrition/:programId', component: ClientnutritionComponent},
  { path: 'clientdashboard', component: ClientdashboardComponent},
  { path: 'clientexercise', component: ClientexerciseComponent},
  { path: 'clientexercise/:programId', component: ClientexerciseComponent},
  { path: 'clientresources', component: ClientresourcesComponent},
  { path: 'userprofile', component: UserprofileComponent },
  { path: 'program', component:  NewprogramComponent },
  { path: 'program/:userId/:goalId', component:  NewprogramComponent },
  { path: 'sessions/:userId/:sessionId', component: SessionComponent},
  { path: 'userprofile', component: UserprofileComponent},
  { path: 'programcreate', component: ProgramcreationComponent },
  { path: 'foods-uploader', component: FoodsuploaderComponent },
  { path: 'uploader', component: UploaderComponent },
  { path: 'exercises-uploader', component: ExercisesuploaderComponent},
  { path: 'meals-uploader', component: MealsuploaderComponent},
  { path: 'notifications', component: NotificationsComponent},
  { path: 'recipes-uploader', component: RecipesUploaderComponent },
  { path: 'nutrition-program-uploader', component: NutritionProgramUploaderComponent},
  { path: 'exercise-plan-uploader', component: ExercisePlansUploaderComponent},
  { path: 'clientprogramarchive',component:ProgramArchiveComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
