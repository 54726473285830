<div class="recipe_uploader_main">
  <div class="recipe_copy">
    <mat-icon (click)="onCopyRecipe()">file_copy</mat-icon>
  </div>
    <div class="col-33 padding-5 container">
        <div class="marginBottom fieldlbls">Name</div>
        <div>
            <app-selectinput placeholder="Name" type="recipeName" [itemValue]="recipeName" (action)="onAction($event)"></app-selectinput>
        </div>
    </div>
    <div class="select-ddl col-33 padding-5">
        <div>Recipe Category</div>
        <mat-form-field class="width-full">
            <mat-label>Select Category</mat-label>
          <mat-select [(ngModel)]="selected_recipe_category" (ngModelChange)="onChangeRecipeCategory()">
            <mat-option *ngFor="let data of recipesCategories"  [value]="data.id">{{data.name}}</mat-option>
          </mat-select>
        </mat-form-field>
    </div>
    <div>
        <div class="col-33 inlineBlock padding-5">
            <app-selectinput placeholder="New recipe category..." type="newRecipeCategory" [itemValue]="newRecipeCategory" (action)="onAction($event)"></app-selectinput>
        </div>
        <div class="select-ddl col-33 inlineBlock padding-5">
            <mat-form-field class="width-full">
                <mat-label>Make subcategory of...</mat-label>
              <mat-select [(ngModel)]="selected_sub_recipe_category" (ngModelChange)="onChangeRecipeSubCategory()">
                <mat-option *ngFor="let data of recipesCategories"  [value]="data.id">{{data.name}}</mat-option>
              </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="multi_line_input col-66 padding-5">
        <div>Description</div>
        <textarea [(ngModel)]="description"></textarea>
    </div>
    <div class="multi_line_input col-66 padding-5">
        <div>Instructions</div>
        <textarea [(ngModel)]="instructions"></textarea>
    </div>
    <div class="multi_line_input col-66 padding-5">
        <div>Note</div>
        <textarea [(ngModel)]="note"></textarea>
    </div>
    <div class="upload-image-sequence">
    <input #imageInput hidden="true" type="file" name="photo" onclick="this.value=null" (change)="onUploadImageSequence($event)" accept="image/*"/>
    <div class="link_upload"
        (click)="imageInput.click()"
    >
        UPLOAD IMAGE SEQUENCE
    </div>
    <mat-slide-toggle [(ngModel)]="isUseImagesFromCopy">Use images from copied recipe</mat-slide-toggle>
    </div>
    <div>
      <div *ngFor="let specImg of recipeimages"
        class="exerciseImageBlock"
      >
      <img [src]="imageCustomUrl+specImg.image" class="exercise-image" />
  </div>
    </div>
    <div>
        <div *ngFor="let image of recipeImageSequence"
            class="exerciseImageBlock"
        >
            <img [src]="image" class="exercise-image" />
        </div>
    </div>
    <div class="col-33">
        <div class="marginBottom">Video URL</div>
        <div>
            <app-selectinput placeholder="Video URL" type="videoUrl" [itemValue]="videoUrl" (action)="onAction($event)"></app-selectinput>
        </div>
    </div>
    <div>
        <div class="col-33 inlineBlock">
            <div class="marginBottom">Recipe unit name (Servings)</div>
            <div>
                <app-selectinput placeholder="Recipe unit name (Servings)" type="recipeUnitName" [itemValue]="recipeUnitName" (action)="onAction($event)"></app-selectinput>
            </div>
        </div>
        <div class="col-33 inlineBlock margin-left-10">
            <div class="marginBottom">Default Qty (Total servings in recipe)</div>
            <div>
                <app-selectinput placeholder="Default Qty (Total servings in recipe)" type="defaultQty" [itemValue]="defaultQty" (action)="onAction($event)"></app-selectinput>
            </div>
        </div>
    </div>

      <div>
        <div class="recipe-ingredients">Recipe Ingredients</div>
        <div class="table-container">
            <table mat-table [dataSource]="datasource" multiTemplateDataRows>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    <td mat-footer-cell *matFooterCellDef>Macro totals</td>

                  </ng-container>

                  <ng-container matColumnDef="category">
                    <th mat-header-cell *matHeaderCellDef> Category </th>
                    <td mat-cell *matCellDef="let element"> {{element.category}} </td>
                    <td mat-footer-cell *matFooterCellDef></td>

                  </ng-container>

                  <ng-container matColumnDef="calories">
                    <th mat-header-cell *matHeaderCellDef> Calories </th>
                    <td mat-cell *matCellDef="let element"> {{element.calories}} </td>
                    <td mat-footer-cell *matFooterCellDef>{{commonService.getFixedValue(totals.cals)}}</td>
                  </ng-container>

                  <ng-container matColumnDef="protein">
                    <th mat-header-cell *matHeaderCellDef> Protein </th>
                    <td mat-cell *matCellDef="let element"> {{element.protein}} </td>
                    <td 
                        mat-footer-cell 
                        *matFooterCellDef>
                        {{commonService.getFixedValue(totals.protein)}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="carbs">
                    <th mat-header-cell *matHeaderCellDef> Carbs </th>
                    <td mat-cell *matCellDef="let element"> {{element.carbs}} </td>
                    <td mat-footer-cell *matFooterCellDef>
                      {{commonService.getFixedValue(totals.carbs)}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fat">
                    <th mat-header-cell *matHeaderCellDef> Fat </th>
                    <td mat-cell *matCellDef="let element"> {{element.fat}} </td>
                    <td mat-footer-cell *matFooterCellDef>{{commonService.getFixedValue(totals.fat)}}</td>
                  </ng-container>

                  <ng-container matColumnDef="unit">
                    <th mat-header-cell *matHeaderCellDef> Unit </th>
                    <td mat-cell *matCellDef="let element"> {{element.unit}} </td>
                    <td mat-footer-cell *matFooterCellDef></td>

                  </ng-container>

                  <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef> Qty </th>
                    <td mat-cell *matCellDef="let element; let i = dataIndex"> <input class="meal_input_qty" [(ngModel)]="element.quantity" (keyup)="onQuantityChange($event, element.quantity, i)" /> </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                  </ng-container>

                  <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef> Delete </th>
                    <td mat-cell *matCellDef="let element; let i = dataIndex"> <div (click)="onDeleteRecord($event, i)"><mat-icon >delete</mat-icon></div> </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                  </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
            </table>
        </div>
      </div>

      <div class="nutritionix-section">
        <app-nutritionix-food (action)="onFoodSelection($event)"></app-nutritionix-food>
      </div>

      <div>
        <div class="add-food-template">Add food template</div>
        <div class="table-container">
          <table mat-table [dataSource]="recipesSource">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
            <ng-container matColumnDef="category">
              <th mat-header-cell *matHeaderCellDef> Category </th>
              <td mat-cell *matCellDef="let element"> {{element.category}} </td>
            </ng-container>
            <ng-container matColumnDef="calories">
              <th mat-header-cell *matHeaderCellDef> Calories </th>
              <td mat-cell *matCellDef="let element"> {{element.calories}} </td>
            </ng-container>
            <ng-container matColumnDef="protein">
              <th mat-header-cell *matHeaderCellDef> Protein </th>
              <td mat-cell *matCellDef="let element"> {{element.protein}} </td>
            </ng-container>
            <ng-container matColumnDef="carbs">
              <th mat-header-cell *matHeaderCellDef> Carbs </th>
              <td mat-cell *matCellDef="let element"> {{element.carbs}} </td>
            </ng-container>
            <ng-container matColumnDef="fat">
              <th mat-header-cell *matHeaderCellDef> Fat </th>
              <td mat-cell *matCellDef="let element"> {{element.fat}} </td>
            </ng-container>
            <ng-container matColumnDef="unit">
              <th mat-header-cell *matHeaderCellDef> Unit </th>
              <td mat-cell *matCellDef="let element"> {{element.unit}} </td>
            </ng-container>
            <ng-container matColumnDef="quantity">
              <th mat-header-cell *matHeaderCellDef> Quantity </th>
              <td mat-cell *matCellDef="let element"> {{element.qty}} </td>
            </ng-container>
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef>  </th>
              <td mat-cell *matCellDef="let element">
                <mat-icon>add_box</mat-icon>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="cursor_pointer" (click)="specificRecord(row)"></tr>
          </table>
                  <mat-paginator
      [length]="pagination.count"
      [pageIndex]="pagination.pageIndex"
      [pageSize]="pagination.perPage"
      [pageSizeOptions]="[10, 25, 50, 100, 200]"
      (page)="getServerData($event)"
showFirstLastButtons></mat-paginator>
        </div>
      </div>
      <mat-card *ngIf="formInvalidFields.length > 0" class="error_info">
        <div>Please fill required fields</div>
        <ul>
            <li *ngFor="let item of formInvalidFields">{{item}}</li>
        </ul>
      </mat-card>
      <div class="submit_meal">
        <button mat-stroked-button (click)="onResetMeal()" class="margin-right-5">Reset Changes</button>
        <button mat-raised-button color="primary" (click)="onSubmitMeal()" >Submit</button>
    </div>
</div>