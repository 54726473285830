import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from './../auth/auth.service';
import { HeaderService } from './../services/header/header.service';
// import { AppleLoginProvider } from '../providers/apple-login.provider';

import { SocialAuthService, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
import { CommonService } from '../services/common/common.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
  socialUser: SocialUser = {response: '', provider: '', email: '', id: '', name: '', photoUrl: '', firstName: '', lastName: '', authToken: '', idToken: '', authorizationCode: ''};

  constructor(private router: Router, private fb: FormBuilder,
    private authService: AuthService,
    private headerService: HeaderService,
    private socialAuthService: SocialAuthService,
    private commonService: CommonService
    // private appleLoginProvider: AppleLoginProvider
    ) { }

  form: any = {};
  private formSubmitAttempt: boolean = false;

  invalidLogins = false;

  ngOnInit() {
    this.commonService.gaEvent('login_initial', 'login', 'Initial Login Screen');

    this.form = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });

    //Test login
    // const obj = {
    //   userName: 'pavankumar@filostech.com',
    //   password: 'Admin@123'
    // };
    // this.authService.login(obj);
    //Test login

    const data = {
      title: 'Total Health & Fitness'
    };
    this.headerService.setHeaderData(data);
    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
      console.log(JSON.stringify(user));
      this.authService.onLoginSuccess();
      console.log('User logged in');
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  onSubmit() {
    this.commonService.gaEvent('general_login', 'general_submit', 'General Submit');

    if (this.form.valid) {
      this.authService.login(this.form.value);
    }
    //this.invalidLogins = true;
    this.formSubmitAttempt = true;
  }

  loginWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  // loginWithApple(): void {
  //   this.appleLoginProvider.signIn();
  // }
  
}
