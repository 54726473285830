<div>
    <div class="col-33 padding-5">
        <div class="marginBottom">Name</div>
        <div>
            <app-selectinput placeholder="Name" type="exerciseName" [itemValue]="exerciseName"
                (action)="onAction($event)"></app-selectinput>
        </div>
    </div>
    <div class="select-ddl col-33 padding-5">
        <div>Exercise Plan Category</div>
        <mat-form-field class="width-full">
            <mat-label>Exercise Plan Category</mat-label>
            <mat-select [(ngModel)]="selected_exercise_category" (ngModelChange)="onChangeExerciseCategory()">
                <mat-option *ngFor="let data of exerciseCategories" [value]="data.id">{{data.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <div class="col-33 inlineBlock padding-5">
            <app-selectinput placeholder="New category..." type="newExerciseCategory"
                [itemValue]="newExerciseCategory" (action)="onAction($event)"></app-selectinput>
        </div>
        <div class="select-ddl col-33 inlineBlock padding-5">
            <mat-form-field class="width-full">
                <mat-label>Make subcategory of...</mat-label>
                <mat-select [(ngModel)]="selected_sub_exercise_category"
                    (ngModelChange)="onChangeExerciseSubCategory()">
                    <mat-option *ngFor="let data of exerciseCategories" [value]="data.id">{{data.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="multi_line_input col-66 padding-5">
        <div>Description</div>
        <textarea [(ngModel)]="description"></textarea>
    </div>
    <div class="multi_line_input col-66 padding-5">
        <div>Instructions</div>
        <textarea [(ngModel)]="instructions"></textarea>
    </div>

    <div>
        <div class="align-right">
            <button mat-raised-button color="primary" (click)="onExerciseAddPlan()">Add Plan</button>
        </div>
        <div class="align-right">
            <div class="overall-sets dsp-blk">
                <input class="sets" placeholder="Sets" [(ngModel)]="srr.set" />
                <input class="sets" placeholder="Reps" [(ngModel)]="srr.reps" />
                <input class="sets" placeholder="Rest" [(ngModel)]="srr.rest" />
                <input class="sets" placeholder="Speed" [(ngModel)]="srr.speed" />
            </div>
            <mat-icon title="Overall Sets, Reps, Rest, Speed" class="apply-down" (click)="updateAllRecords()">arrow_downward</mat-icon>
        </div>
        <div cdkDropListGroup>
            <div class="col-30">
                <div class="tree-container">
                    <input [(ngModel)]="searchTree" (input)="searchExercise($event)" class="searchtree">
                    <div class="tree-struct">
                        <div
                        cdkDropList
                        [cdkDropListData]="dataSource"
                        class="exercise-list">
                        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="0"
                            cdkDrag [cdkDragData]="node">
                                <button mat-icon-button disabled></button>
                                <span class="drgitem">{{node.name}}</span>
                            </mat-tree-node>
                            <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="10" 
                            cdkDrag [cdkDragData]="node"
                            [cdkDragDisabled]="true">
                                <button mat-icon-button matTreeNodeToggle
                                        [attr.aria-label]="'Toggle ' + node.name">
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                    </mat-icon>
                                    {{node.name}}
                                </button>
                            </mat-tree-node>
                        </mat-tree>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-70">
                <div class="example-list">
                    <div  class="exercise-plan" *ngFor="let item of templateData;let i = index;">
                        <div class="top-section">
                            <div class="col-30">
                                <input type="text" class="name-ipt" [(ngModel)]="item.planName" />
                            </div>
                            <div class="actions col-70">
                                <div class="overall-sets dsp-blk">
                                    <input class="sets" placeholder="Sets" [(ngModel)]="item.oSet" />
                                    <input class="sets" placeholder="Reps" [(ngModel)]="item.oReps" />
                                    <input class="sets" placeholder="Rest" [(ngModel)]="item.oRest" />
                                    <input class="sets" placeholder="Speed" [(ngModel)]="item.oSpeed" />
                                </div>
                                <mat-icon title="Overall Sets, Reps, Rest, Speed" class="apply-down" (click)="updateOverallSRR(i)">arrow_downward</mat-icon>
                                <mat-icon title="Copy Plan" (click)="copyExePlan(item)" class="copy">note_add</mat-icon>
                                <mat-icon class="delete-plan" title="Delete Plan" (click)="deletePlan(item.id)">delete_outline</mat-icon>
                            </div>
                        </div>                
                        <div class="exe-detail" cdkDropList [cdkDropListData]="item.exercises" (cdkDropListDropped)="drop($event, i)">
                            <div class="calender">
                                <div class="cursorPointer cal-days" (click)="onSelectAllDays(i)">
                                    <mat-icon *ngIf="!item.isAllWeeksSelected">done</mat-icon>
                                    <mat-icon color="primary" *ngIf="item.isAllWeeksSelected">check_circle</mat-icon>
                                </div>
                                <div class="cal-days" *ngFor="let day of item.days">
                                    <div class="daylbl">{{day.key.substr(0,2)}}</div>
                                    <mat-checkbox color="primary" class="example-margin" [(ngModel)]="day.value"  [checked]="day.value" (change)="onWeekSelection(i)"></mat-checkbox>
                                </div>
                            </div>
                            <div class="exercise-detail" >
                                <div *ngFor="let ex of item.exercises" cdkDrag [cdkDragData]="ex">
                                <mat-icon class="drg">menu</mat-icon>
                                <div class="fields dropdown">
                                    <div class="lbl">Body Part</div>
                                    <input class="sets" [(ngModel)]="ex.catname" />
                                </div>
                                <div class="fields namefld dropdown">
                                    <div class="lbl">Name</div>
                                    <input class="sets" [(ngModel)]="ex.name" />
                                </div>
                                <div class="fields ex-sets">
                                    <div title="Sets/Mode" class="lbl">Sets/Mode</div>
                                    <input *ngIf="ex.cat_id !== 1" class="sets"  [(ngModel)]="ex.sets" placeholder="Sets"/>
                                    <input *ngIf="ex.cat_id === 1" class="sets" [(ngModel)]="ex.mode" placeholder="Mode"/>
                                </div>
                                <div class="fields ex-sets">
                                    <div title="Reps/Intensity" class="lbl">Reps/Intensity</div>
                                    <input *ngIf="ex.cat_id !== 1" class="sets"  [(ngModel)]="ex.reps" placeholder="Reps" />
                                    <input *ngIf="ex.cat_id === 1" class="sets" [(ngModel)]="ex.intensity" placeholder="Intensity" />
                                </div>
                                <div class="fields ex-sets">
                                    <div title="Rest/HeartRate" class="lbl">Rest/HeartRate</div>
                                    <input *ngIf="ex.cat_id !== 1" class="sets" [(ngModel)]="ex.rest" placeholder="Rest"/>
                                    <input *ngIf="ex.cat_id === 1" class="sets" [(ngModel)]="ex.heartrate" placeholder="Heart Rate"/>
                                </div>
                                <div class="fields ex-sets">
                                    <div title="Speed/Duration" class="lbl">Speed/Duration</div>
                                    <input *ngIf="ex.cat_id !== 1" class="sets" [(ngModel)]="ex.speed" placeholder="Speed"/>
                                    <input *ngIf="ex.cat_id === 1" class="sets" [(ngModel)]="ex.duration" placeholder="Duration"/>
                                </div>
                                <div class="actions">
                                    <img class="copy" title="Copy Exercise"  (click)="copyExercise(ex, item.id)" src="../../assets/icons/paperplus.svg" />
                                    <div class="delete-exercise" title="Delete Exercise" (click)="deleteExercise(ex.id, item.id)">X</div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="submit_exercise_plan">
        <button mat-stroked-button (click)="onResetExercisePlan()" class="margin-right-5">Reset Changes</button>
        <button mat-raised-button color="primary" (click)="onSubmitExercisePlan()">Save Nutrition Program</button>
    </div>
</div>