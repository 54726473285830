import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { User } from './user';
import { HttpService } from '../services/http/http.service';
// import { ProgressbarService } from '../services/progressbar/progressbar.service';
import { ToasterService } from '../services/toaster/toaster.service';
import { SidenavService } from '../services/sidenav/sidenav.service';
import { config } from '../config';
import { Location } from '@angular/common';
import { UiService } from '../services/uiloader/uiloader.service';
import { WebpushService } from '../services/webpush/webpush.service';
import { CommonService } from '../services/common/common.service';

import { SocialAuthService } from 'angularx-social-login';
import { LogService } from '../services/log/log.service';

@Injectable()
export class AuthService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private displayHeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private loggedUserAvatar: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public sideMenuSelected: BehaviorSubject<string> = new BehaviorSubject<string>('');

  get onSideMenuSelected() {
    return this.sideMenuSelected.asObservable();
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get isLoggedUserAvatar() {
    return this.loggedUserAvatar.asObservable();
  }

  get isDisplayHeader() {
    return this.displayHeader.asObservable();
  }

  setGoalData(goalData:any) {
    this.cookieService.set('goal_data_user', JSON.stringify(goalData), 1, '/',window.location.hostname, false, "Strict");
  }

  getGoalData() {
    const data = this.cookieService.get('goal_data_user');
    return data === '' ? {} : JSON.parse(data);
  }

  setProgramCreation(data:any) {
    this.cookieService.set('program_creation', JSON.stringify(data), 1, '/',window.location.hostname, false, "Strict");
  }

  public setSelectedProgramId = (programId:any) => {
    this.cookieService.set('selected_program_id', programId, 1, '/',window.location.hostname, false, "Strict");
  };

  public getSelectedProgramId = () => {
    return this.cookieService.get('selected_program_id');
  };

  displayHeaderValue() {
    this.displayHeader.next(true);
  }

  hideDisplayHeaderValue() {
    this.displayHeader.next(false);
  }

  constructor(
    private router: Router,
    private httpService: HttpService,
    private commonService: CommonService,
    // private progressbarService: ProgressbarService,
    private webpush: WebpushService,
    private toasterService: ToasterService,
    private sidenavService: SidenavService,
    private cookieService: CookieService,
    private http: HttpClient,
    private location: Location,
    private ui : UiService,
    private socialAuthService: SocialAuthService,
    private logService: LogService
  ) {
    if (this.getJwtToken()) {
    this.loadInitialComponents();
    }
  }

  navigateToPath() {
    const userRole = this.getUserRole();
    if (userRole === config.CLIENT_USER_ROLE) {
      this.sideMenuSelected.next('dashboard');
      this.router.navigate(['/dashboard']);
    } else if (userRole === config.ADMIN_USER_ROLE) {
      this.sideMenuSelected.next('company-clients');
      this.router.navigate(['/company-clients']);
    } else {
      this.sideMenuSelected.next('consultant-clients');
      this.router.navigate(['/consultant-clients']);
    }
  }

  onActAsUser(data: any, isSwitch: any) {
    this.storeJwtToken(data);
    this.loadInitialComponents(isSwitch);
    this.navigateToPath();
  }

  switchBackToAdmin() {
    const url = `${config.apiUrl}/companyadmin/impersonatetoadmin/${this.getLoggedUserId()}`;
    this.httpService.getCall(url).subscribe((res)=>{
      this.setLoggedUserId(this.getLoggedUserId());
      this.cookieService.delete('logged_user_id', '/');
      this.onActAsUser(res, false);
    }, (err) => {
      console.log(err);
      this.logService.logError(err);
    });
  }

  onLoginSuccess() {
    this.loadInitialComponents();
    this.webpush.registerPushNotification();
    this.navigateToPath();
  }

  loadInitialComponents(isSwitch?:false) {
    const path = this.location.path();
    if (path === '/login') {
      this.router.navigate(['/dashboard']);
    }
    this.loggedIn.next(true);
    setTimeout(()=>{
      this.loadSideMenu(isSwitch);
    }, 1000);
  }

  loadSideMenu(isSwitch:any) {
    this.httpService.getCall(`${config.apiUrl}/dashboard/sidemenu`).subscribe(data => {
      if (isSwitch || this.getLoggedUserId()) {
        data.push({"id":9999,"name":"","display_name":"Switch Back to Admin","icon":"track_changes"});
      }
      this.sidenavService.fetchSideNav(data);
    },
    err => {
      console.log(err)
      this.logService.logError(err);
    });
  }

  login(user: User) {
    if (user.userName !== '' && user.password !== '' ) {
      // this.progressbarService.handleSpinner(true);
      this.ui.spin$.next(true);
      const data = {
        email: user.userName,
        password: user.password
      };
      this.httpService.postCall(`${config.apiUrl}/auth`,data).subscribe(data => {
        this.storeJwtToken(data);
        this.onLoginSuccess();
        this.ui.spin$.next(false);
      },
      err => {
        this.ui.spin$.next(false);
        this.toasterService.show('error', 'Invalid Credentials', err?.error?.error)
        this.logService.logError(err);
      },
      () => { 
        this.ui.spin$.next(false);
      });
    }
  }

  onLogoutClear() {
    this.loggedIn.next(false);
    localStorage.removeItem('userRefreshToken');
    this.cookieService.delete('userToken');
    this.cookieService.deleteAll( '/' , window.location.hostname );
    this.router.navigate(['/login']);
  }

  logout() {
    this.socialAuthService.signOut(true).then(data => {  
      this.onLogoutClear();
    }, err => {
      this.onLogoutClear();
    });
  }

  private storeRefreshToken(refresh: string) {
    localStorage.setItem('userRefreshToken', refresh);
  }

  setSurveyEmail(email:string, surveyId:string) {
    this.cookieService.set('user_survey_email', email, 1, '/',window.location.hostname, false, "Strict");
    this.cookieService.set('user_survey_id', surveyId, 1, '/',window.location.hostname, false, "Strict");
  }

  setSurveyFeedbackId(surveyFeedbackId:string) {
    this.cookieService.set('user_survey_feedback_id', surveyFeedbackId, 1, '/',window.location.hostname, false, "Strict");
  }

  getSurveyEmail() {
    const email = this.cookieService.get('user_survey_email');
    return email;
  }

  setLoggedUserId(userId:any) {
    this.cookieService.set('logged_user_id', userId, 1, '/',window.location.hostname, false, "Strict");
  }

  getLoggedUserId() {
    return this.cookieService.get('logged_user_id');
  }

  setUserId(surveyFeedbackId:string) {
    this.cookieService.set('user_id', surveyFeedbackId, 1, '/',window.location.hostname, false, "Strict");
  }

  getUserId() {
    const email = this.cookieService.get('user_id');
    return email;
  }

  getSurveyId() {
    const surveyId = this.cookieService.get('user_survey_id');
    return surveyId;
  }

  getSurveyFeedbackId() {
    const surveyFeedbackId = this.cookieService.get('user_survey_feedback_id');
    return surveyFeedbackId;
  }

  setUserDetails(data:any) {
    this.cookieService.set('user_details', JSON.stringify(data));
  }

  getUserDetails() {
    const data = this.cookieService.get('user_details');
    if (data.length > 0) {
      return JSON.parse(data);
    }
    return '';
  }

  getJwtToken() {
    return this.cookieService.get('userToken');
  }

  refreshToken() {
    return this.http.post<any>(`${config.apiUrl}/auth/refresh`, {
      'refresh_token': this.getRefreshToken()
    }).pipe(tap((tokens: any) => {
      this.storeJwtToken(tokens);
    }));
  }

  setUserRole(data:any) {
    this.cookieService.set('user_role', data.userData.role.id, data.expiresAt);
  }

  public getUserRole() {
    return this.cookieService.get('user_role');
  }

  setUserAvatar(data:any) {
    if (data.userData && data.userData.personal) {
      let avatar = data.userData.personal.avatar;
      if (avatar.length === 0) {
        avatar = this.commonService.getUserInitials(data.userData);
      }
      this.cookieService.set('user_avatar', avatar, data.expiresAt);
      this.loggedUserAvatar.next(avatar);
    }
  }

  public getUserAvatar() {
    return this.cookieService.get('user_avatar');
  }

  setActualLoggedUserId(data:any) {
    this.cookieService.set('user_actual_logged_id', data.userData.id, data.expiresAt);
  }

  public getActualLoggedUserId() {
    return this.cookieService.get('user_actual_logged_id');
  }

  storeJwtToken(data:any) {
    this.cookieService.set('userToken', data.accessToken, data.expiresAt);
    this.storeRefreshToken(data.refreshToken);
    this.setUserRole(data);
    if (data.userData.personal) {
      this.setUserAvatar(data);
    }
    this.setActualLoggedUserId(data);
  }

  private getRefreshToken() {
    return localStorage.getItem('userRefreshToken');
  }
}