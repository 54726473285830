<div>
    <section class="spec-section">
        <div>
            <div [ngClass]="{'highlight': selectedItem === 'name'}">
                <div class="highlight-inner">
                    <div class="item" (click)="onItemSelect('name')">
                        <span>Name</span><span>{{userData.firstname}} {{userData.lastname}}</span>
                    </div>
                    <app-useritem *ngIf="selectedItem === 'name'"  name="Name" type="multiinputs" [userData]="userNamesData" (action)="onAction($event)"></app-useritem>
                </div>
            </div>
            <div [ngClass]="{'highlight': selectedItem === 'email'}">
                <div class="highlight-inner">
                    <div class="item" (click)="onItemSelect('email')">
                        <span>Email Address</span><span>{{userData.email}}</span>
                    </div>
                    <app-useritem *ngIf="selectedItem === 'email'"  name="Email Address" [itemValue]="userData.email" (action)="onAction($event)"></app-useritem>
                </div>
            </div>
            <div [ngClass]="{'highlight': selectedItem === 'address'}">
                <div class="highlight-inner">
                    <div class="item" (click)="onItemSelect('address')">
                        <span>Address</span><span>{{userData.aptsuite}}, {{userData.address}}, {{userData.city}}, {{userData.state}}, {{userData.postalcode}}</span>
                    </div>
                    <app-useritem *ngIf="selectedItem === 'address'"  name="Address" type="multiinputs" [userData]="userAddressData" [selectData]="selectData" (action)="onAction($event)"></app-useritem>
                </div>
            </div>
            <div [ngClass]="{'highlight': selectedItem === 'gender'}">
                <div class="highlight-inner">
                    <div class="item" (click)="onItemSelect('gender')">
                        <span>Gender</span><span>{{userData.gender}}</span>
                    </div>
                    <app-useritem *ngIf="selectedItem === 'gender'"  name="Gender" [itemValue]="userData.genderShort" type="gender" [radioOptions]="genderOptions" (action)="onAction($event)"></app-useritem>
                </div>
            </div>
            <div [ngClass]="{'highlight': selectedItem === 'birthdate'}">
                <div class="highlight-inner">
                    <div class="item" (click)="onItemSelect('birthdate')">
                        <span>Birth Date</span><span>{{userData.birthdate}}</span>
                    </div>
                    <app-useritem *ngIf="selectedItem === 'birthdate'"  name="Birth Date" type="date" [dateValue]="userData.birthdate" (action)="onAction($event)"></app-useritem>
                </div>
            </div>
            <div [ngClass]="{'highlight': selectedItem === 'mobilephone'}">
                <div class="highlight-inner">
                    <div class="item" (click)="onItemSelect('mobilephone')">
                        <span>Mobile Phone</span><span>{{userData.mobilephone}}</span>
                    </div>
                    <app-useritem *ngIf="selectedItem === 'mobilephone'"  name="Mobile Phone" [itemValue]="userData.mobilephone" (action)="onAction($event)"></app-useritem>
                </div>
            </div>
            <div [ngClass]="{'highlight': selectedItem === 'password'}">
                <div class="highlight-inner">
                    <div class="item" (click)="onItemSelect('password')">
                        <span>Password</span><span>******</span>
                    </div>
                    <app-useritem *ngIf="selectedItem === 'password'" inputType="password"  name="Password" [itemValue]="userData.password" (action)="onAction($event)"></app-useritem>
                </div>
            </div>
        </div>
    </section>
</div>