import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {LiveAnnouncer} from '@angular/cdk/a11y';

import {MatTableDataSource} from '@angular/material/table';

import {MatSort, Sort} from '@angular/material/sort';
import { HttpService } from '../services/http/http.service';
import { config } from '../config';
import { UiService } from '../services/uiloader/uiloader.service';
import { CommonService } from '../services/common/common.service';
import { LogService } from '../services/log/log.service';
import { ToasterService } from '../services/toaster/toaster.service';

let searchTimeout:any;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.sass']
})
export class UsersComponent implements OnInit {

  constructor(private router: Router,
    private httpService: HttpService,
    private ui: UiService,
    private logService: LogService,
    private commonService: CommonService,
    private _liveAnnouncer: LiveAnnouncer,
    private toasterService: ToasterService) { }

    @ViewChild(MatSort) sort: any = {};

  ngOnInit(): void {
    const routes = [{
      name: 'Clients',
      path: 'company-clients'
    }];
    this.commonService.setHeaderData(routes);
    this.fetchUsers();
  }

  ngAfterViewInit() {
  }

  displayedColumns: string[] = ['profilePic', 'name', 'companyName', 'consultantName', 'roles', 'userStatus'];
  dataSource:any = [];
  pagination = {
    count: 0,
    perPage: 25,
    pageIndex: 0,
    searchtext: '',
    totalCount: 0
  };

  specificUser(item:any){
    console.log(item);
    this.router.navigate(['/userdetails', item.id ]);
  }

  addUser() {
    this.router.navigate(['/adduser']);
  }

  private getUserRoles(data:any) {
    const result = data ? data.map((item:any)=>(item.display_name)) : [];
    return result;
  }

  onTableSort(sortState: any) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  private getFormatedData(data:any) {
    const { rows = [], count = 0 } = data;
    this.pagination.count = count;
    return rows.map((item:any) => ({
      id: item.id,
      name: `${item.firstname} ${item.lastname}`,
      companyName: item.company ? item.company.name: '',
      consultantName: item.consultant ? `${item.consultant.firstname} ${item.consultant.lastname}` : '',
      roles: this.getUserRoles(item.roles),
      userStatus: item.enabled === 1 ? true : false,
      isProfilePicExisted: item.personal && item.personal.avatar && item.personal.avatar.length > 0,
      userChars:  this.commonService.getUserInitials(item),
      profilePic: item.personal && item.personal.avatar && item.personal.avatar.length > 0 ? `${config.imageUrl}${item.personal.avatar}` : config.defaultProfilePic
    }));
  }

  private fetchUsers() {
    this.ui.spin$.next(true);
    const obj = {
      fromIndex: this.pagination.pageIndex,
      limit: this.pagination.perPage,
      orderBy: "DESC",
      orderByOn: 'id',
      searchtext: this.pagination.searchtext
    };
    this.httpService.postCall(`${config.apiUrl}/getclients`, obj).subscribe(data => {
      this.ui.spin$.next(false);
      this.dataSource = new MatTableDataSource(this.getFormatedData(data));
      this.dataSource.sort = this.sort;

      // if (this.pagination.searchtext.length === 0) { //Pavan commented on 26th Jan 22
        this.pagination.totalCount = data.count;
      // }      
    },
    err => {
      this.ui.spin$.next(false);
      this.dataSource = [];
      this.pagination.totalCount = 0;
      this.toasterService.show('error', 'Alert', err?.error?.error);
      this.logService.logError(err);
    });
  }

  getServerData(e:any) {
    console.log(e.pageIndex);
    console.log(e.pageSize);
    this.pagination.pageIndex = e.pageIndex;
    this.pagination.perPage = e.pageSize;
    this.fetchUsers();
  }

  onSearchUser(x:any) {
    this.pagination.pageIndex = 0;
    this.pagination.searchtext = x.target.value;
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(()=>{
      this.fetchUsers();
    }, 1000);
  }
}
