import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CommonService } from '../services/common/common.service';
import { UiService } from '../services/uiloader/uiloader.service';
import { config } from '../config';
import { HttpService } from '../services/http/http.service';
import { LogService } from '../services/log/log.service';

@Component({
  selector: 'app-roleseditor',
  templateUrl: './roleseditor.component.html',
  styleUrls: ['./roleseditor.component.sass']
})
export class RoleseditorComponent implements OnInit {
  rolesData:any = [];
  privilegesData:any = [];
  role:string = '';
  displayedColumns: string[] = ['name', 'displayName', 'description', 'read', 'write', 'admin'];
  dataSource = [];
  rolesConstant:any = {
    read: 1,
    write: 2,
    admin: 3
  };
  pagination = {
    count: 0,
    perPage: 25,
    pageIndex: 0,
    searchtext: '',
    totalCount: 0
  };
  constructor(private location: Location,
    private commonService: CommonService,
    private ui: UiService,
    private logService: LogService,
    private httpService: HttpService) { }

  ngOnInit(): void {
    const routes:any = [
      { name: 'Platform Setup', path: 'platform-setup'},
      { name: 'Roles Editor', path: 'roles-editor'}
    ];
    this.commonService.setHeaderData(routes);
    this.fetchRoles();
  }

  fetchRoles() {
    const url = `${config.apiUrl}/permission/roles`;
    this.httpService.getCall(url).subscribe(data => {
      const result = data.map((item:any) => ({
        "id": item.id,
        "display_name": item.display_name
      }));
      this.rolesData = result;
    },
    err => {
      this.logService.logError(err);
    });
  }

  getFormattedPrivileges(data:any) {
    const result = data.map((item:any) => {
      const {privileges = []} = item;
      return { 
        permission_id: item.permission_id,
        name: item.permissions[0].name,
        displayName: item.permissions[0].display_name,
        description: item.permissions[0].description,
        read: privileges.indexOf(1) !== -1,
        write: privileges.indexOf(2) !== -1,
        admin: privileges.indexOf(3) !== -1
      }
    });
    return result;
  }

  fetchPermissions() {
    this.ui.spin$.next(true);
    const obj = {
      fromIndex: this.pagination.pageIndex,
      limit: this.pagination.perPage,
      orderBy: "DESC",
      orderByOn: 'id',
      searchtext: this.pagination.searchtext
    };
    const url = `${config.apiUrl}/permission/permissionprivileges/${this.role}`;
    this.httpService.getCall(url).subscribe(data => {
      this.dataSource = this.getFormattedPrivileges(data);
      this.ui.spin$.next(false);
    },
    err => {
      this.ui.spin$.next(false);
      this.logService.logError(err);
    });
  }

  onGoBack() {
    this.location.back();
  }

  onAction(item: any) {
    console.log(item);
  }

  getServerData(e:any) {
    console.log(e.pageIndex);
    console.log(e.pageSize);
    this.pagination.pageIndex = e.pageIndex;
    this.pagination.perPage = e.pageSize;
    this.fetchPermissions();
  }

  onChangeRole() {
    this.fetchPermissions();
  }

  onPrivilegeChange(item:any, type:string) {

    const url = `${config.apiUrl}/permission/updatepermissionprivileges/${this.role}`;
    const obj:any = {
      permission_id: item.permission_id,
      privilege_id_status: item[type] ? 1 : 0,
      privilege_id: this.rolesConstant[type]
    };
    this.httpService.putCall(url, obj).subscribe(data => {
      console.log(data);
    },
    err => {
      this.logService.logError(err);
    });
  }

}
