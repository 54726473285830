import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common/common.service';

@Component({
  selector: 'app-clientresources',
  templateUrl: './clientresources.component.html',
  styleUrls: ['./clientresources.component.sass']
})
export class ClientresourcesComponent implements OnInit {

  constructor(public commonService: CommonService) { }

  ngOnInit(): void {
    const routes:any = [
      { name: 'Resources', path: 'clientresources'}
    ];
    this.commonService.setHeaderData(routes);
  }

}
