<div cdkDropListGroup class="exercise-content">
    <div class="flexContainer title">
        <span>Exercise</span>
        <span>
            <div class="print">
                <button mat-raised-button class="margin_10 cursorPointer" color="secondary"
                    [ngClass]="{'disable_print': templateData.length === 0}" (click)="copyExcercise()">
                    <mat-icon>note_add</mat-icon>
                    Copy
                </button>
                <button mat-raised-button color="secondary" class="cursorPointer"
                    [ngClass]="{'disable_print': templateData.length === 0}" (click)="downloadAsPDF()">
                    <mat-icon>print</mat-icon>
                    Print
                </button>
            </div>
        </span>
    </div>
    <div class="details-container">
        <div *ngIf="templateData.length === 0" cdkDropList [cdkDropListData]="templateData"
            (cdkDropListDropped)="drop($event)" class="empty_drag_container">
            <div>Drag and Drop directly from templates</div>
        </div>
        <div class="example-list">
            <div class="top-section main_top_section" *ngIf="templateData.length !== 0">
                <div class="empty_div"><span class="addPlan" (click)="newExercisePlan()">+ Add New</span></div>
                <div class="overall-sets dsp-blk">
                    <input class="sets" placeholder="Sets" [(ngModel)]="main.oSet" />
                    <input class="sets" placeholder="Reps" [(ngModel)]="main.oReps" />
                    <input class="sets" placeholder="Rest" [(ngModel)]="main.oRest" />
                    <input class="sets" placeholder="Speed" [(ngModel)]="main.oSpeed" />
                </div>
                <div class="actions float_none">
                    <mat-icon title="Overall Sets, Reps, Rest, Speed" class="apply-down"
                        (click)="updateOverallSRRPlans()">arrow_downward</mat-icon>
                </div>
            </div>
            <div class="exercise-plan" *ngFor="let item of templateData; let i =index">
                <div class="align_right top_move_icon">
                    <span (click)="onSortMealPlanLower(i, false)">
                        <mat-icon>expand_less</mat-icon>
                    </span>
                </div>
                <div class="top-section">
                    <input type="text" class="name-ipt" [(ngModel)]="item.planName" />
                    <span class="btn-cont"><button class="addBtn" (click)="updatePlan(item)">Update Plan</button></span>
                    <div class="overall-sets dsp-blk">
                        <input class="sets" placeholder="Sets" [(ngModel)]="item.oSet" />
                        <input class="sets" placeholder="Reps" [(ngModel)]="item.oReps" />
                        <input class="sets" placeholder="Rest" [(ngModel)]="item.oRest" />
                        <input class="sets" placeholder="Speed" [(ngModel)]="item.oSpeed" />
                    </div>
                    <div class="actions">
                        <mat-icon title="Overall Sets, Reps, Rest, Speed" class="apply-down"
                            (click)="updateOverallSRR(item)">arrow_downward</mat-icon>
                        <mat-icon title="Copy Plan" (click)="copyExerciseSessionPlan(item)" class="copy">note_add
                        </mat-icon>
                        <mat-icon class="delete-plan" title="Delete Plan" (click)="deletePlan(item.id)">delete_outline
                        </mat-icon>
                    </div>
                </div>
                <div class="exe-detail" [id]="item.id" cdkDropList [cdkDropListData]="item.exercises"
                    (cdkDropListDropped)="drop($event, i)">
                    <div class="calender">
                        <div class="cursorPointer cal-days" (click)="onSelectAllDays(item,i)">
                            <mat-icon *ngIf="!item.isAllWeeksSelected">done</mat-icon>
                            <mat-icon color="primary" *ngIf="item.isAllWeeksSelected">check_circle</mat-icon>
                        </div>
                        <div class="cal-days" *ngFor="let day of item.days">
                            <div class="daylbl">{{day.key.substr(0,2)}}</div>
                            <mat-checkbox color="primary" class="example-margin" [(ngModel)]="day.value"
                                (change)="updatePlan(item)" [checked]="day.value"></mat-checkbox>
                        </div>
                    </div>
                    <div class="exercise-detail" *ngFor="let ex of item.exercises; let j = index" cdkDrag
                        [cdkDragData]="ex">
                        <mat-icon class="drg" cdkDragHandle>menu</mat-icon>
                        <div class="fields dropdown">
                            <div class="lbl">Body Part</div>
                            <mat-form-field>
                                <mat-select [(ngModel)]="ex.cat_id" name="dropdown"
                                    (selectionChange)="onChangeProgram($event, item, ex.id)"
                                    [disabled]="!ex.exercisetemplate?.is_added_manual">
                                    <span *ngFor="let data of catlist">
                                        <mat-option [value]="data.id">
                                            {{data.name}}
                                        </mat-option>
                                        <span class="opt-child" *ngIf="data.childs">
                                            <mat-option *ngFor="let sub of data.childs" [value]="sub.id">
                                                {{sub.name}}
                                            </mat-option>
                                        </span>
                                    </span>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="fields namefld dropdown add-exercise">
                            <div class="lbl">Name</div>
                            <!-- <mat-form-field>
                                <mat-select [(ngModel)]="ex.name" name="dropdown" (selectionChange)="subNameSelect($event, ex.id, ex.cat_id, item)" [disabled]="!ex.exercisetemplate?.is_added_manual">
                                    <span *ngFor="let data of ex.searchOpt">
                                        <mat-option [value]="data.name">
                                            {{data.name}}
                                        </mat-option>
                                    </span>
                                </mat-select>
                            </mat-form-field> -->
                            <form class="example-form">
                                <div class="inp-section">
                                    <input type="text" [placeholder]="ex.name ? ex.name : 'Name'"
                                        [disabled]="!ex.exercisetemplate?.is_added_manual" [matAutocomplete]="auto"
                                        (change)="subNameCustom($event, ex.id, ex.cat_id, item, i, j)"
                                        (keyup)="subNameCustom($event, ex.id, ex.cat_id, item, i, j)"
                                        (blur)="subNameBlur($event, ex.id, ex.cat_id, item, i, j)"
                                        [formControl]="exerciseCtrl"
                                        [disabled]="!ex.exercisetemplate?.is_added_manual" />
                                </div>
                                <mat-autocomplete #auto="matAutocomplete" [panelWidth]="450"
                                    class="auto_cmpt_ddl_nutrition">
                                    <span *ngFor="let data of ex.searchOpt">
                                        <mat-option [value]="data.name"
                                            *ngIf="!ex.customname || data.name.toLowerCase().indexOf(ex.customname && ex.customname.toLowerCase()) !== -1"
                                            class="nutrition_mat_option"
                                            (click)="subNameSelect(data, ex.id, ex.cat_id, item)">
                                            <span>
                                                {{data.name}}
                                            </span>
                                        </mat-option>
                                    </span>

                                </mat-autocomplete>
                            </form>
                        </div>
                        <div class="fields ex-sets">
                            <div title="Sets/Mode" class="lbl">Sets/Mode</div>
                            <input *ngIf="ex.cat_id !== 1" class="sets" (focus)="onInputFocus($event)"
                                (blur)="updateExercise(ex, item.id)" [(ngModel)]="ex.sets" placeholder="Sets" />
                            <input *ngIf="ex.cat_id === 1" class="sets" (focus)="onInputFocus($event)"
                                (blur)="updateExercise(ex, item.id)" [(ngModel)]="ex.mode" placeholder="Mode" />
                        </div>
                        <div class="fields ex-sets">
                            <div title="Reps/Intensity" class="lbl">Reps/Intensity</div>
                            <input *ngIf="ex.cat_id !== 1" class="sets" (focus)="onInputFocus($event)"
                                (blur)="updateExercise(ex, item.id)" [(ngModel)]="ex.reps" placeholder="Reps" />
                            <input *ngIf="ex.cat_id === 1" class="sets" (focus)="onInputFocus($event)"
                                (blur)="updateExercise(ex, item.id)" [(ngModel)]="ex.intensity"
                                placeholder="Intensity" />
                        </div>
                        <div class="fields ex-sets">
                            <div title="Rest/HeartRate" class="lbl">Rest/HeartRate</div>
                            <input *ngIf="ex.cat_id !== 1" class="sets" (focus)="onInputFocus($event)"
                                (blur)="updateExercise(ex, item.id)" [(ngModel)]="ex.rest" placeholder="Rest" />
                            <input *ngIf="ex.cat_id === 1" class="sets" (focus)="onInputFocus($event)"
                                (blur)="updateExercise(ex, item.id)" [(ngModel)]="ex.heartrate"
                                placeholder="Heart Rate" />
                        </div>
                        <div class="fields ex-sets">
                            <div title="Speed/Duration" class="lbl">Speed/Duration</div>
                            <input *ngIf="ex.cat_id !== 1" class="sets" (focus)="onInputFocus($event)"
                                (blur)="updateExercise(ex, item.id)" [(ngModel)]="ex.speed" placeholder="Speed" />
                            <input *ngIf="ex.cat_id === 1" class="sets" (focus)="onInputFocus($event)"
                                (blur)="updateExercise(ex, item.id)" [(ngModel)]="ex.duration" placeholder="Duration" />
                        </div>
                        <div class="actions">
                            <mat-icon *ngIf="ex?.exercisetemplate?.video_url" class="exercise_video"
                                (click)="onVideoView(ex.exercisetemplate.video_url, ex.name)">videocam</mat-icon>
                            <img class="copy" title="Copy Exercise" (click)="copyExercise(ex, item.id)"
                                src="../../assets/icons/paperplus.svg" />
                            <mat-icon class="copy"
                                (click)="onExerciseInfo(ex.exercisetemplate.description, ex.name, ex.exercisetemplate.exercisetemplateimages)">
                                info</mat-icon>
                            <div class="delete-exercise" title="Delete Exercise"
                                (click)="deleteExercise(ex.id, item.id)">X</div>
                        </div>
                    </div>
                    <div class="addexe" (click)="addExercise($event, item)">+</div>
                    <div class="align_right bottom_move_icon">
                        <span (click)="onSortMealPlanLower(i, true)">
                            <mat-icon>expand_more</mat-icon>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div></div>

    </div>
    <div class="tree-container">
        <div
            (window:scroll)="onSideScroll($event)"
            [ngClass]="{'fixed-div': fixedDiv}"
        >
            <input [(ngModel)]="searchTree" (input)="searchExercise($event)" class="searchtree">
            <div class="tree-struct">
                <div cdkDropList [cdkDropListData]="dataSource" class="exercise-list">
                    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="0"
                            cdkDrag [cdkDragData]="node">
                            <button mat-icon-button disabled></button>
                            <span class="drgitem">{{node.name}}</span>
                        </mat-tree-node>
                        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding
                            matTreeNodePaddingIndent="10" cdkDrag [cdkDragData]="node" [cdkDragDisabled]="true">
                            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                                <mat-icon class="mat-icon-rtl-mirror">
                                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                                {{node.name}}
                            </button>
                        </mat-tree-node>
                    </mat-tree>
                </div>
            </div>
        </div>
    </div>
</div>