<div>
    <div class="main-header-survey"
        [ngStyle]="{'width': surveyProgress + '%' }"
    >
    </div>
    <div class="main-container">
        <div class="getting-know">
            Getting To Know You
        </div>
        <div class="would-love">
            We would love to learn a little more about you and the way that you do things. Please answer the following questions.
        </div>
        <div class="questions-section"> 
            <div *ngFor="let item of surveyPageQuestions; let i=index">
                <div class="question"><span class="question-num">{{ questionsStartCount + i}}.</span>{{item.label}}</div>
                <app-selectradio *ngIf="item.type === 'radio'" [values]="item.values" [indexValue]="i" (action)="onAction($event)" [itemValue]="item.selected_values" [specified_strings]="item.specified_strings || []" [colType]="item.colType"></app-selectradio>
                <app-selectcheckbox *ngIf="item.type === 'checkbox'" [values]="item.values" (action)="onAction($event)" [indexValue]="i" [itemValue]="item.selected_values" [itemValueInput]="item.selected_values_other"></app-selectcheckbox>
                <app-selectinput *ngIf="item.type === 'textarea' || item.type === 'textbox'" [placeholder]="item.placeholder" [indexValue]="i" [itemValue]="item.selected_values" (action)="onAction($event)"></app-selectinput>
            </div>
            <mat-card *ngIf="errorInfo.length > 0">
                <span class="error-info">{{errorInfo}}</span></mat-card>
        </div>
        <div class="error-info" *ngIf="surveyIncomplete">
            <p>Please fill all questions</p>
        </div>
    </div>
    <div class="btm-ftr"></div>
    <div class="ftr-action">
        <div class="col-50">
            <button mat-stroked-button (click)="onBack()" *ngIf="pageId !== 1">Back</button>
        </div>
        <div class="col-50 align-right">
            <button mat-raised-button color="primary" (click)="onSubmitNext()">Next</button>
        </div>
    </div>
</div>