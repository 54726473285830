import { Component, OnInit } from '@angular/core';
import { config } from '../config';
import { HttpService } from '../services/http/http.service';
import { CommonService } from '../services/common/common.service';

@Component({
  selector: 'app-mealsuploader',
  templateUrl: './mealsuploader.component.html',
  styleUrls: ['./mealsuploader.component.sass']
})
export class MealsuploaderComponent implements OnInit {
  mealsCategories = [];
  constructor(private httpService: HttpService,
    private commonService: CommonService) { 
    this.getMealCategories();
  }

  ngOnInit(): void {
    const routes:any = [{
      name: 'Uploader',
      path: 'uploader'
    },{
      name: 'Meals Uploader',
      path: 'meals-uploader'
    }];

    this.commonService.setHeaderData(routes);
  }

  getMealCategories() {
    const url = `${config.apiUrl}/meals/getmealtemplatecategory/`;
    this.httpService.getCall(url).subscribe((result:any) => {
      this.mealsCategories = result;
    }, (err:any) => {
      
    });
  }

}
