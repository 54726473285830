<table mat-table [dataSource]="localData" class="mat-elevation-z8 nutrition-client-table" >
    <ng-container matColumnDef="data">
        <th mat-header-cell *matHeaderCellDef>
            <mat-slide-toggle [(ngModel)]="selectAll" (change)="calculateAll()"></mat-slide-toggle>
        </th>
        <td mat-cell *matCellDef="let element" class="nameCol">
            <mat-slide-toggle *ngIf="element.data.checked !== 'none'" [(ngModel)]="element.data.checked" (change)="rowselection($event, element.data.rowid, type)">{{element.data.value}}</mat-slide-toggle>
            <span *ngIf="element.data.checked === 'none'" class="totals">Totals:</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="calories">
        <th mat-header-cell *matHeaderCellDef> calories </th>
        <td mat-cell *matCellDef="let element">
            <div class="client-name">
                <span class="orange" [ngClass]="{'green': element.servingsize.changed == element.servingsize.count && element.data.checked === true, 'red': element.servingsize.changed > element.servingsize.count && element.data.checked === true}">{{commonService.getFixedValue(element.calories.re)}} </span> <span class="orange" [ngClass]="{'green': element.servingsize.changed >= element.servingsize.count && element.data.checked === true}">/{{commonService.getFixedValue(element.calories.actual)}} kcal</span>
            </div> 
        </td>
    </ng-container>

    <ng-container matColumnDef="protein">
        <th mat-header-cell *matHeaderCellDef> protein </th>
        <td mat-cell *matCellDef="let element">
            <span class="orange" [ngClass]="{'green': element.servingsize.changed == element.servingsize.count && element.data.checked === true, 'red': element.servingsize.changed > element.servingsize.count && element.data.checked === true}">{{commonService.getFixedValue(element.protein.re)}} </span> <span class="orange" [ngClass]="{'green': element.servingsize.changed >= element.servingsize.count && element.data.checked === true}">/{{commonService.getFixedValue(element.protein.actual)}} g</span>
        </td>
    </ng-container>
    
    <ng-container matColumnDef="carbs">
        <th mat-header-cell *matHeaderCellDef> carbs </th>
        <td mat-cell *matCellDef="let element">
            <span class="orange" [ngClass]="{'green': element.servingsize.changed == element.servingsize.count && element.data.checked === true, 'red': element.servingsize.changed > element.servingsize.count && element.data.checked === true}">{{commonService.getFixedValue(element.carbs.re)}} </span> <span class="orange" [ngClass]="{'green': element.servingsize.changed >= element.servingsize.count && element.data.checked === true}">/{{commonService.getFixedValue(element.carbs.actual)}} g</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="fat">
        <th mat-header-cell *matHeaderCellDef> FAT </th>
        <td mat-cell *matCellDef="let element">
            <span class="orange" [ngClass]="{'green': element.servingsize.changed == element.servingsize.count && element.data.checked === true, 'red': element.servingsize.changed > element.servingsize.count && element.data.checked === true}">{{commonService.getFixedValue(element.fat.re)}} </span> <span class="orange" [ngClass]="{'green': element.servingsize.changed >= element.servingsize.count && element.data.checked === true}">/{{commonService.getFixedValue(element.fat.actual)}} g</span>
        </td>
    </ng-container>
    
    <ng-container matColumnDef="servingsize">
        <th mat-header-cell *matHeaderCellDef> serving size </th>
        <td mat-cell *matCellDef="let element">
            <div class="serving" *ngIf="element.servingsize">
                <input [ngClass]="{'toggeled': element.data.checked === true || (totalClr && element.data.checked === 'none'), 'overeaten': (element.data.checked === true && element.servingsize.changed > element.servingsize.count)}" [(ngModel)]="element.servingsize.changed" (keyup)="changeHandler($event, element.servingsize, type)" /> / {{element.servingsize.count}} {{element.servingsize.unit}}
            </div>
        </td>
    </ng-container>
    
    <ng-container matColumnDef="delete">
        <!-- <th mat-header-cell *matHeaderCellDef class="deleteAll" (click)="deleteClick('',type)"> Delete All </th> -->
        <th mat-header-cell *matHeaderCellDef class="center"> Actions </th>
        <td mat-cell *matCellDef="let element"> 
            <div *ngIf="!element.delete.hideActions" class="deleteAction" >
                <span>...</span>
                <ul class="delete-dropdown">
                    <li (click)="addNote(element.delete.rowid)">Add Note</li>
                    <li *ngIf="element.delete.show" (click)="deleteClick(element.delete)">Delete</li>
                </ul>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsList"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsList;" ></tr>
</table>
<span *ngIf="!localData.length" class="emtmsg">No records found</span>