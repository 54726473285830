import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common/common.service';
import { UiService } from '../services/uiloader/uiloader.service';
import { HttpService } from '../services/http/http.service';
import { config } from '../config';
import { Router } from '@angular/router';
import { DataService } from '../services/data/data.service';
import { LogService } from '../services/log/log.service';

let searchTimeout:any;

@Component({
  selector: 'app-prospects',
  templateUrl: './prospects.component.html',
  styleUrls: ['./prospects.component.sass']
})
export class ProspectsComponent implements OnInit {

  constructor(private commonService: CommonService,
    private ui: UiService,
    private httpService: HttpService,
    private router: Router,
    private logService: LogService,
    private dataService: DataService) { }

  ngOnInit(): void {
    const routes:any = [{
      name: 'Clients',
      path: 'consultant-clients'
    }];
    this.commonService.gaEvent('prospects', 'prospects', 'Prospects Screen');

    this.commonService.setHeaderData(routes);
    this.fetchUsers();
  }

  displayedColumns: string[] = ['name', 'email', 'progress', 'status'];
  dataSource = [];
  pagination = {
    count: 0,
    perPage: 25,
    pageIndex: 0,
    searchtext: '',
    totalCount: 0
  };

  private getFormatedData(data:any) {
    const { rows = [], count = 0 } = data.consultantclients;
    this.pagination.count = count;
    return rows.map((item:any) => ({
      id: item?.id,
      firstname: item.firstname,
      lastname: item.lastname,
      email: item.email,
      enabled: item.enabled,
      consultant: item.consultant,
      progress: item.survey_status.status
      //survey_completed: this.commonService.getFormattedDate(item.surveys_feedbacks[0].modified_at)
    }));
  }

  private fetchUsers() {
    this.ui.spin$.next(true);
    const obj = {
      fromIndex: this.pagination.pageIndex,
      limit: this.pagination.perPage,
      orderBy: "DESC",
      orderByOn: 'id',
      searchtext: this.pagination.searchtext
    };
    this.httpService.postCall(`${config.apiUrl}/consultantclients`, obj).subscribe((data:any) => {
      this.ui.spin$.next(false);
      this.dataSource = this.getFormatedData(data);
      // if (this.pagination.searchtext.length === 0) { //Commented by pavan on 26th Jan 22
        this.pagination.totalCount = data.count;
      // }     
    },
    err => {
      this.ui.spin$.next(false);
      this.dataSource = [];
      this.pagination.totalCount = 0;
      this.logService.logError(err);
    });
  }

  onSearchUser(x:any) {
    this.pagination.pageIndex = 0;
    this.pagination.searchtext = x.target.value;
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(()=>{
      this.fetchUsers();
    }, 1000);
  }

  getServerData(e:any) {
    this.pagination.pageIndex = e.pageIndex;
    this.pagination.perPage = e.pageSize;
    this.fetchUsers();
  }

  specificUser(item:any){
    this.commonService.gaEvent('specific_prospect', 'specific_prospect', 'On selecting specific user of Prospect');

    this.dataService.setProspectSelected(item);
    // this.router.navigate(['/prospectprofile']);
    this.router.navigate(['/userdetails', item.id ]);
  }

  addUser() {
    this.router.navigate(['/adduser']);
  }

}
