export const config = {
    apiUrl: "https://stageweb.totalhealthandfitness.com",
    // apiUrl: "https://ec2-18-223-190-78.us-east-2.compute.amazonaws.com", // https url of elevated
    // apiUrl: "https://api.elevated.totalhealthandfitness.com",
    imageUrl: "https://thfmedia.s3.us-east-2.amazonaws.com/profileimages/",
    webImageUrl: "https://ec2-13-59-59-211.us-east-2.compute.amazonaws.com/img/uploads/avatars/",
    exerciseImageUrl: "https://thfmedia.s3.us-east-2.amazonaws.com/",
    exerciseTemplateImageUrl: "https://thfmedia.s3.us-east-2.amazonaws.com/exercisetemplateimages/",
    defaultProfilePic: '/assets/profile.png',
    vapidPublicKey: 'BH0F7IsTSCvDXaQmuLaOhk9QFbuhcm3gNbpmFXca1ire37XYDkzJMJJQusIkOwwmU62L2wPbfeieTKBZXvwR2u0',
    PROVIDER_GOOGLE: 'GOOGLE',
    PROVIDER_FACEBOOK: 'FACEBOOK',
    LOG_ERROR_KEY_HEADER: '9Z$rJaF^J>6P?VHzc039emw$uTUfXKHFd3',
    ADMIN_USER_ROLE: '1',
    CLIENT_USER_ROLE: '3',
    microNutrients: [
        {
          label: 'Sugars',
          key: 'sugars',
          value: ''
        },
        {
          label: 'Fiber',
          key: 'fiber',
          value: ''
        },
        {
          label: 'Calcium',
          key: 'calcium',
          value: ''
        },
        {
          label: 'Iron',
          key: 'iron',
          value: ''
        },
        {
          label: 'Magnesium',
          key: 'magnesium',
          value: ''
        },
        {
          label: 'Phosphorus',
          key: 'phosphorus',
          value: ''
        },
        {
          label: 'Potassium',
          key: 'potassium',
          value: ''
        },
        {
          label: 'Sodium',
          key: 'sodium',
          value: ''
        },
        {
          label: 'Zinc',
          key: 'zinc',
          value: ''
        },
        {
          label: 'Vitamin A',
          key: 'vitamin_a',
          value: ''
        },
        {
          label: 'Vitamin E',
          key: 'vitamin_e',
          value: ''
        },
        {
          label: 'Vitamin D',
          key: 'vitamin_d',
          value: ''
        },
        {
          label: 'Vitamin D3',
          key: 'vitamin_d3',
          value: ''
        },
        {
          label: 'Vitamin C',
          key: 'vitamin_c',
          value: ''
        },
        {
          label: 'Thiamin',
          key: 'thiamin',
          value: ''
        },
        {
          label: 'Riboflavin',
          key: 'riboflavin',
          value: ''
        },
        {
          label: 'Niacin',
          key: 'niacin',
          value: ''
        },
        {
          label: 'Pantothenic Acid',
          key: 'pantothenicacid',
          value: ''
        },
        {
          label: 'Vitamin B6',
          key: 'vitamin_b6',
          value: ''
        },
        {
          label: 'Folate',
          key: 'folate',
          value: ''
        },
        {
          label: 'Vitamin B12',
          key: 'vitamin_b12',
          value: ''
        },
        {
          label: 'Leucine',
          key: 'leucine',
          value: ''
        },
        {
          label: 'Cholesterol',
          key: 'cholesterol',
          value: ''
        },
        {
          label: 'Fatty Acids',
          key: 'fatty_acids',
          value: ''
        },
        {
          label: 'DHA',
          key: 'dha',
          value: ''
        },
        {
          label: 'EPA',
          key: 'epa',
          value: ''
        },
        {
          label: 'ALA',
          key: 'ala',
          value: ''
        },
      ]
};
