<div *ngIf="!data.isSeessionCopy">
    <h1 mat-dialog-title>{{data.title}} {{data.excersieCopyObject.clientDetais?.firstname}}
        {{data.excersieCopyObject.clientDetais?.lastname}} </h1>
    <div mat-dialog-content class="dialogContent " [ngClass]="!data.isSeessionCopy ? 'copyProgram' : 'copyplan'">
    <ng-container *ngIf="!data.copyUser && data.excersieCopyObject.users.length > 0">
        <mat-form-field appearance="fill" class="session">
            <mat-label>Select User</mat-label>
            <mat-select [(ngModel)]="data.excersieCopyObject.selectedUser">
                <mat-option *ngFor="let user of data.excersieCopyObject.users" [value]="user.id">{{user.firstname}}
                    {{user.lastname}}</mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>
    <ng-container *ngIf="data.copyUser && data.excersieCopyObject.sessions.length > 0">
        <mat-form-field appearance="fill" class="session">
            <mat-label>Select Session</mat-label>
            <mat-select [(ngModel)]="data.excersieCopyObject.selectedSession">
                <mat-option *ngFor="let session of data.excersieCopyObject.sessions" [value]="session.id">{{session.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>
</div>

</div>
<div *ngIf="data.isSeessionCopy">
    <h1 mat-dialog-title>{{data.title}} {{data.excersieCopyObject.clientDetais?.firstname}}
        {{data.excersieCopyObject.clientDetais?.lastname}}</h1>   
<div mat-dialog-content class="dialogContent " [ngClass]="!data.isInitial ? 'copyProgram' : 'copyplan'">
    <ng-container *ngIf="data.isInitial">
        <mat-radio-group aria-label="Select an option" [(ngModel)]="data.excersieCopyObject.client">
            <mat-radio-button value="sameClinet">Same Client</mat-radio-button> &nbsp;
            <mat-radio-button value="differentClient">Different Client</mat-radio-button>
        </mat-radio-group>
    </ng-container>
    <ng-container *ngIf="!data.copyUser && data.excersieCopyObject.users.length > 0">
        <mat-form-field appearance="fill" class="session">
            <mat-label>Select User</mat-label>
            <mat-select [(ngModel)]="data.excersieCopyObject.selectedUser">
                <mat-option *ngFor="let user of data.excersieCopyObject.users" [value]="user.id">{{user.firstname}}
                    {{user.lastname}}</mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>
    <ng-container *ngIf="data.copyUser && data.excersieCopyObject.sessions.length > 0">
        <mat-form-field appearance="fill" class="session">
            <mat-label>Select Session</mat-label>
            <mat-select [(ngModel)]="data.excersieCopyObject.selectedSession">
                <mat-option *ngFor="let session of data.excersieCopyObject.sessions" [value]="session.id">{{session.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>
</div>
</div>
<div mat-dialog-actions class="dialogActions text-right">
    <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" (click)="responseHandler(true)"
        cdkFocusInitial>Confirm</button>
    <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
</div>
