import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.sass']
})
export class ResourcesComponent implements OnInit {
  specificProspectId:string = '';
  constructor(private authService: AuthService) { 
    const specificId = this.authService.getActualLoggedUserId();
    this.specificProspectId = specificId;
  }

  ngOnInit(): void {
  }

}
